.bs-tarjetas-estadisticas {
  width: calc(100% - 16px);

       @media #{$tablet} {
  max-width: 940px;
       }
  margin: 0 auto 12px;
  display: block;
  outline: 1px solid var(--bs--color-grises-gris-20);
  @include border-radius(4px);
  padding: 8px;
  min-height: 80px;
  @include flex();
  @include flex-position-child("center", "center");
  @include flex-position-vertical("center");
  h3 {
    font-size: 2.2rem;
    font-family: $overPass_light;
    color: var(--bs--color-grises-gris-80);
    text-align: center;
    flex: 0 0 100%;
    line-height: 2;
  }
  h2 {
    flex: 0 0 100%;
    font-family: $overPass_bold;
    font-size: 1.5rem;
    color: var(--bs--color-principal-negro);
    line-height: 1.5;
    text-align: center;
    em {
      display: block;
      font-family: $overPass_bold;
      color: var(--bs--color-principal-azul);
    }
  }
  figure {
    flex:0 0 100%;
    text-align: center;
    img {
      height: 72px; width: auto;
    }
  }
  > span {
    @include border-radius(50%);
    height: 6px;
    width: 6px;
    display: inline-block;
    background-color: var(--bs--color-secundario-azul-40);
    margin: 12px auto;
  }
  &.destacado {
    background-color: var(--bs--color-principal-azul);
    figure {

    img {
       filter: brightness(518%);
              -webkit-filter: brightness(518%);
    }
  }
    h2,
    h3 {
      color: var(--bs--color-principal-blanco);
    }
    h2 {
      font-family: $overPass_regular;
      em {
        font-family: $overPass_bold;
        color: var(--bs--color-principal-blanco);
      }
    }
  }

}

.bs-tarjetas-boton {
  padding: 8px;
    @media #{$tablet} {
  max-width: 340px;
       }
  margin: 0 auto 12px;
  display: block;
  width: calc(100% - 16px);
  min-height: 20px;
  @include flex();
  @include flex-position-child("center", "str");
  @include flex-position-vertical("str");
  background-color: var(--bs--color-grises-gris-10);
  cursor: pointer;
  article {
    text-align: center;
    display: block;
    width: 100%;
    padding:8px;
    min-height: 60px;
    background-color: var(--bs--color-principal-blanco);
    svg {
      display: inline-block;
      margin:8px auto 0;
      fill:var(--bs--color-grises-gris-40);
      height: 12px; width: auto;
    }
    figure {
      margin: 0 auto;
      text-align: center;
      display: block;
      height: auto;
      width: 100%;
      img {margin:0 auto;
        width: auto;
        height: 48px;
      }
    }
    h2 {
      margin-top: 0px;
      width: 100%;
      text-align: center;
      font-size: 1.5rem;
      font-family: $overPass_regular;
      color: var(--bs--color-principal-negro);
      line-height: 1.5;
      em {
        color: var(--bs--color-principal-azul);
        font-family: $overPass_bold;
      }
    }
  }
  &:not(.destacado) {
    &:hover {
      article {
        background-color: var(--bs--color-grises-gris-10);
      }
    }
  }
  &.destacado {

    background-color: var(--bs--color-principal-azul-hover);
    figure {
          filter: brightness(518%);
              -webkit-filter: brightness(518%);
    }
    article {
      background-color: var(--bs--color-principal-azul);
      h2 {
        color: var(--bs--color-principal-blanco);
        em {
          color: var(--bs--color-principal-blanco);
        }
      }
    }

    &:hover {
      article {
        background-color: var(--bs--color-principal-azul-hover);
      }
    }
  }
}
