p-multiselect {
  width: 100%;

  .p-multiselect {
    width: 100%;
    margin-bottom: 6px;
    .p-multiselect-filter-icon {
      font-size: 1.5rem;
      @include centrar(99%, 50%);
      height: 10px;
      width: 22px;
    }
    .p-multiselect-token {
      background-color: var(--bs--color-principal-azul);

      .p-multiselect-token-label {
        color: var(--bs--color-principal-blanco);
        font-family: $overPass_bold;
      }
      .p-multiselect-token-icon {
        margin-left: 10px;
        color: var(--bs--color-principal-blanco);
        &:before {
          display: none;
        }
        height: $form-size;
        width: 30px;
        top: 0px;
        margin: 8px 0;
        right: 16px;
        background: $icon_search center center no-repeat;
      }
    }
    @include border-radius(4px);
    border: none;
    outline: none;
    line-height: $form-size;
    background: var(--bs--color-principal-blanco);

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
    outline: 1px solid var(--bs--color-grises-gris-80) !important;

    .p-multiselect-header {
      padding-left: 0 !important;
      padding-right: 0 !important;
      //margin:0 !important
      .p-multiselect-filter-container {
        @include flex();
        @include flex-position-child("bw", "top");
        @include flex-position-vertical("top");

        .p-multiselect-filter-icon {
          height: $form-size;
          width: 40px;
          top: 58%;
          left: 98%;
          right: 20px;
          background: $icon_search center center no-repeat;
          &::before {
            display: none;
          }
        }
        .p-multiselect-filter {
          position: relative;
          flex: 1;
          margin: 8px !important;
          width: auto !important;
        }
      }
    }
    .p-multiselect-panel {
      @include no-shadow();
      outline: 1px solid var(--bs--color-grises-gris-80) !important;
      @include border-radius(0 0 4px 4px);
      background-color: var(--bs--color-principal-blanco);
    }
    .p-multiselect-item {
      font-size: 1.6rem;

      line-height: $form-size;
      padding: 0 !important;
      margin: 0 !important;
      font-family: $overPass_bold !important;
      &:focus {
        @include no-shadow();
      }
      &:hover {
        background: var(--bs--color-grises-gris-10) !important;
      }
      &.p-highlight {
        background: var(--bs--color-grises-gris-20) !important;
      }
    }
    .p-multiselect-trigger {
      width: 40px;
      background: $icon_arrow_down_green center center no-repeat;
      background-size: 16px auto;
      span {
        display: none;
      }
    }
    .p-multiselect-header {
      background-color: var(--bs--color-grises-gris-10);
      .p-multiselect-filter-container {
        width: 100%;
      }
    }
    .p-multiselect-empty-message {
      font-family: $overPass_bold;
      color: $rojo;
      font-size: 1.5rem;
    }
    .p-multiselect-close {
      display: none;
      span {
        display: none;
      }
    }
    .p-multiselect-label {
      margin:0; padding: 0;
      @include no-shadow();
      text-transform: none !important;
      color: var(--bs--color-principal-negro) !important;
      font-size: 1.4rem !important;
      font-family: $overPass_bold !important;
      text-indent: 16px;
      &.p-placeholder {
        font-family: $overPass_regular !important;
        color: var(--bs--color-grises-gris-50) !important;
      }
    }
    .p-multiselect-item {
      span:not(.p-checkbox-icon) {
        font-family: $overPass_bold;
        color: var(--bs--color-grises-gris-50);
        font-size: 1.6rem;
        text-transform: none;
      }
    }
  }

    &.p-inputwrapper-focus {
      .p-multiselect {
        outline: 2px solid var(--bs--color-principal-negro) !important;
      }
    }

  &.ng-invalid,
  &.is-invalid {
    .p-multiselect {
      outline: 2px solid  var(--bs--color-secundario-rojo) !important;
      .p-multiselect-label {
        color:  var(--bs--color-secundario-rojo) !important;
        font-family: $overPass_bold !important;
        &.p-placeholder {

          color:  var(--bs--color-secundario-rojo) !important;
        }
      }
      .p-multiselect-trigger {
        background-image: $icon_arrow_down_red;
      }
    }
  }
}
