@charset "UTF-8";
main {
  display: block;
}

[hidden] {
  display: none !important;
}

button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

input, textarea, select {
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@font-face {
  font-family: "overpassbold";
  src: url(~src/assets/fonts/overpass-bold-webfont.woff2) format("woff2"), url(~src/assets/fonts/overpass-bold-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "overpasslight";
  src: url(~src/assets/fonts/overpass-light-webfont.woff2) format("woff2"), url(~src/assets/fonts/overpass-light-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "overpassregular";
  src: url(~src/assets/fonts/overpass-regular-webfont.woff2) format("woff2"), url(~src/assets/fonts/overpass-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
:root {
  --bs--font-size-titulo-xl: clamp(1.5rem, 1.7vw + 0.2rem, 2rem);
  --bs--font-size-titulo-l: clamp(1.3rem, 1.7vw + 0.2rem, 1.7rem);
  --bs--font-size-titulo-m: clamp(1.2rem, 1.7vw + 0.2rem, 1.5rem);
  --bs--font-size-titulo-label: 1.4rem;
  --bs--font-size-placeholder: 1.4rem;
  --bs--font-size-p: 1.5rem;
  --bs--font-size-caption: 1.3rem;
  --bs--font-size-menu: 1.5rem;
  --bs--font-size-h1: clamp(2.2rem, 3.2vw + 0.2rem, 4rem);
  --bs--font-size-h2: clamp(1.8rem, 2vw + 0.2rem, 2.2rem);
  --bs--font-size-h3: clamp(1.8rem, 2vw + 0.2rem, 2rem);
  --bs--font-size-h4: 1.8rem;
  --bs--font-size-h5: 1.5rem;
  --bs--font-size-button: 1.3rem;
  --bs--color-principal-blanco: #ffffff;
  --bs--color-principal-verde: #00953A;
  --bs--color-principal-verde-hover: #037730;
  --bs--color-principal-azul: #006FB9;
  --bs--color-principal-azul-hover: #0C6098;
  --bs--color-principal-negro: #595B5A;
  --bs--color-secundario-amarillo: #F7D500;
  --bs--color-secundario-rojo: #EF3742;
  --bs--color-secundario-rojo-hover: #B91B24;
  --bs--color-secundario-azul-40: #99C5E3;
  --bs--color-secundario-azul-10: #F0F8FE;
  --bs--color-secundario-verde-50: #80CA9C;
  --bs--color-grises-gris-10: #F4F4F4;
  --bs--color-grises-gris-15: #EFF1F4;
  --bs--color-grises-gris-20: #EAEAEA;
  --bs--color-grises-gris-40: #E2E2E3;
  --bs--color-grises-gris-50: #ACADAC;
  --bs--color-grises-gris-80: #8f8f8f;
  --bs--color-validacion-verde-10: #E5F4EB;
  --bs--color-validacion-verde-50: #80CA9C;
  --bs--color-validacion-amarillo-10: #FEF9D9;
  --bs--color-validacion-amarillo-50: #FBEA80;
  --bs--color-validacion-rojo-10: #FFE7E9;
  --bs--color-validacion-rojo-50: #F79BA0;
}

p,
h2,
h2,
h3,
h4,
h5,
label {
  color: var(--bs--color-principal-negro);
}
p.bs-uppercase,
h2.bs-uppercase,
h2.bs-uppercase,
h3.bs-uppercase,
h4.bs-uppercase,
h5.bs-uppercase,
label.bs-uppercase {
  text-transform: uppercase;
}
p.bs-center,
h2.bs-center,
h2.bs-center,
h3.bs-center,
h4.bs-center,
h5.bs-center,
label.bs-center {
  text-align: center !important;
}
p.bs-right,
h2.bs-right,
h2.bs-right,
h3.bs-right,
h4.bs-right,
h5.bs-right,
label.bs-right {
  text-align: right !important;
}
p.bs-azul,
h2.bs-azul,
h2.bs-azul,
h3.bs-azul,
h4.bs-azul,
h5.bs-azul,
label.bs-azul {
  color: var(--bs--color-principal-azul) !important;
}

p {
  font-family: "overpassregular", sans-serif;
  font-size: var(--bs--font-size-p);
  line-height: 2.4rem;
  color: var(--bs--color-principal-negro);
}
p strong {
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-azul) !important;
}
p strong.bs-negro {
  color: var(--bs--color-principal-negro) !important;
}
p a {
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-verde) !important;
  text-decoration: underline;
  text-underline-offset: 2px;
}
p a.bs-imagen {
  margin: 0 8px;
}
p a.bs-imagen:hover {
  opacity: 0.5;
}
p a:hover {
  color: var(--bs--color-principal-verde-hover) !important;
}
p a.bs-azul {
  color: var(--bs--color-principal-azul) !important;
}
p a.bs-azul:hover {
  color: var(--bs--color-principal-azul-hover) !important;
}
p.bs-caption {
  font-size: var(--bs--font-size-caption);
  color: var(--bs--color-grises-gris-50);
  line-height: 1.1rem;
}
p.bs-caption.bs-error {
  color: var(--bs--color-secundario-rojo);
}
.color p {
  color: var(--bs--color-principal-blanco);
}
.color p.bs-error {
  color: var(--bs--color-validacion-rojo-10);
}

h1 {
  font-family: "overpassbold", sans-serif;
  font-size: var(--bs--font-size-h1);
  line-height: normal;
}
h1.bs-contenido {
  font-size: var(--bs--font-size-titulo-xl);
  text-transform: uppercase;
}
h1 strong {
  font-family: "overpassbold", sans-serif;
}

h2 {
  font-family: "overpassregular", sans-serif;
  font-size: var(--bs--font-size-h2);
  line-height: 3.4rem;
}
h2.bs-contenido {
  font-size: var(--bs--font-size-titulo-l);
  text-transform: uppercase;
}
h2 strong {
  font-family: "overpassbold", sans-serif;
}

h3 {
  font-family: "overpassbold", sans-serif;
  font-size: var(--bs--font-size-h3);
  line-height: 2.4rem;
}
h3.bs-contenido {
  font-size: var(--bs--font-size-titulo-m);
  text-transform: uppercase;
  color: var(--bs--color-principal-azul);
}
h3.bs-contenido.bs-gris-80 {
  color: var(--bs--color-grises-gris-80);
}
h3.bs-contenido.bs-negro {
  color: var(--bs--color-principal-negro);
}

h4 {
  font-family: "overpassregular", sans-serif;
  font-size: var(--bs--font-size-h4);
  line-height: 2.4rem;
}
h4 strong {
  font-family: "overpassbold", sans-serif;
}

h5 {
  font-family: "overpassbold", sans-serif;
  font-size: var(--bs--font-size-h5);
  line-height: 2.4rem;
}
label h5 {
  font-size: var(--bs--font-size-titulo-label);
  line-height: 1;
  margin-bottom: 8px;
  min-height: 1.4rem;
}
label h5[disabled] {
  color: var(--bs--color-grises-gris-50);
}
label h5 span.bs-required {
  margin-left: 4px;
  color: var(--bs--color-secundario-rojo);
  font-size: 1.4rem;
}
label h5 span.bs-optional {
  margin-left: 4px;
  color: var(--bs--color-grises-gris-50);
  font-size: 1.1rem;
  text-transform: uppercase;
}
.bs-color h5 {
  color: var(--bs--color-principal-blanco);
}
.bs-color h5[disabled] {
  color: var(--bs--color-principal-blanco);
  opacity: 0.5;
}

div.bs-lista {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
div.bs-lista span {
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
  color: var(--bs--color-principal-blanco);
  font-size: 1.3rem;
  margin-right: 12px;
  background-color: var(--bs--color-principal-azul);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin-top: 2px;
}
div.bs-lista span.check {
  background: url(~src/assets/img/svg/icon-bullet.svg) center center no-repeat;
}
div.bs-lista p {
  flex: 1;
}

ul.bs-lista li,
ol.bs-lista li {
  list-style: none;
  font-size: var(--bs--font-size-p);
  color: var(--bs--color-principal-negro);
  margin-bottom: 12px;
  padding-left: 30px;
}

ul.bs-lista li {
  background: url(~src/assets/img/svg/icon-bullet.svg) left 1px no-repeat;
  background-size: auto 20px;
  min-height: 20px;
  line-height: 1.5;
}

ol.bs-lista {
  counter-reset: element-counter;
}
ol.bs-lista li {
  position: relative;
  counter-increment: element-counter;
}
ol.bs-lista li::before {
  content: counter(element-counter);
  height: 20px;
  width: 20px;
  display: grid;
  place-items: center;
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  left: 0;
  top: 1px;
  color: var(--bs--color-principal-blanco);
  background-color: var(--bs--color-principal-azul);
  font-family: "overpassbold", sans-serif;
  font-size: 1rem;
}

.bs-contenedor {
  margin: 0 auto;
  display: block;
  width: calc(100% - 30px);
  max-width: 1200px;
}
.bs-contenedor.extended {
  max-width: clamp(1200px,100%,1600px);
}

.bs-wrapper {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 1200px;
}
.bs-wrapper.extended {
  max-width: calc($container-size + 380px);
}

img.border-4 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
img.border-8 {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
img.border-12 {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
}
img.border-20 {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
}
img.bs-fotografia {
  height: auto;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  filter: saturate(65%);
  -webkit-filter: saturate(65%);
}

/*!
 * Bootstrap Grid v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.bs-btn {
  text-decoration: none;
  height: 48px;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  color: white;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  font-size: 1.3rem;
  text-align: center;
  padding: 0;
  min-width: 144px;
  font-size: var(--bs--font-size-button);
  font-family: "overpassbold", sans-serif;
  text-transform: uppercase;
  margin: 6px 0;
  width: 100%;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  position: relative;
}
.bs-btna {
  cursor: pointer;
}
.bs-btn.small {
  height: 18px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  width: auto !important;
  min-width: 0px;
}
.bs-btn.tabla {
  height: 30px;
  width: auto;
  min-width: 100px;
  margin: 0;
  cursor: pointer;
}
.bs-btn.tabla + .bs-btn-primary, .bs-btn.tabla + .bs-btn-secondary {
  margin-left: 5px;
}
.bs-btn.tabla + .bs-btn-icon-only {
  margin-left: 10px;
}
.bs-btn.tabla.bs-btn-icon-only {
  min-width: 0;
}
.bs-btn.tabla.bs-btn-link {
  min-width: 0;
  min-height: 0;
  height: auto;
}
@media only screen and (min-width : 600px) {
  .bs-btn {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 190px;
  }
}
@media only screen and (min-width : 1000px) {
  .bs-btn {
    min-width: 220px;
  }
}
.bs-btn.icons {
  justify-content: space-between;
  align-items: center;
}
.bs-btn figure {
  height: 20px;
  width: 20px;
  display: block;
  margin-right: 10px;
  margin-left: 15px;
}
@media only screen and (min-width : 600px) {
  .bs-btn figure {
    margin-left: 0;
  }
}
.bs-btn figure img {
  height: 100%;
  width: auto;
}
.bs-btn span.arrow, .bs-btn span.dummy {
  height: 20px;
  width: 20px;
  background-size: 20px 20px;
  display: block;
}
.bs-btn span.arrow:last-child, .bs-btn span.dummy:last-child {
  margin-left: 10px;
  margin-right: 15px;
}
@media only screen and (min-width : 600px) {
  .bs-btn span.arrow:last-child, .bs-btn span.dummy:last-child {
    margin-right: 0;
  }
}
.bs-btn span.arrow:first-child, .bs-btn span.dummy:first-child {
  margin-left: 15px;
  margin-right: 10px;
}
@media only screen and (min-width : 600px) {
  .bs-btn span.arrow:first-child, .bs-btn span.dummy:first-child {
    margin-left: 0;
  }
}
.bs-btn span.add {
  height: 28px;
  width: 28px;
  background: url(~src/assets/img/svg/icon-add.svg) center center no-repeat;
  background-size: 28px 28px;
  display: block;
}
.bs-btn span.add:last-child {
  margin-left: 10px;
  margin-right: 15px;
}
.bs-btn span.add:first-child {
  margin-left: 15px;
  margin-right: 10px;
}
.bs-btn span.remove {
  height: 28px;
  width: 28px;
  background: url(~src/assets/img/svg/icon-remove.svg) center center no-repeat;
  background-size: 28px 28px;
  display: block;
}
.bs-btn span.remove:last-child {
  margin-left: 10px;
  margin-right: 15px;
}
.bs-btn span.remove:first-child {
  margin-left: 15px;
  margin-right: 10px;
}
.bs-btn.border {
  background: white;
}
.bs-btn.disabled, .bs-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.bs-btn-secondary-color {
  color: var(--bs--color-principal-blanco);
  border: 2px solid var(--bs--color-principal-blanco);
}
.bs-btn-secondary-color.icons span.arrow {
  background: url(~src/assets/img/svg/arrow-button.svg) center center no-repeat;
}
.bs-btn-secondary-color:hover {
  opacity: 0.5;
}
.bs-btn-form {
  height: 46px;
  background-color: var(--bs--color-principal-verde);
  outline: var(--bs--color-principal-verde) 1px solid;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  margin: 0;
  padding: 0 16px;
  min-width: 0px;
  width: auto !important;
  max-width: 120px;
  -webkit-transition: all 0s linear;
  -moz-transition: all 0s linear;
  -ms-transition: all 0s linear;
  -o-transition: all 0s linear;
  transition: all 0s linear;
}
.bs-btn-form.icons {
  cursor: pointer;
}
.bs-btn-form.icons figure {
  margin: 0;
}
.bs-btn-form.no-bg {
  background: none;
  outline: none;
}
.bs-btn-form.no-bg:hover {
  background-color: none !important;
  opacity: 0.5;
}
.bs-btn-form.float {
  position: absolute;
  top: 0;
  right: 0px;
}
.bs-btn-form.audio {
  background: url(~src/assets/img/svg/icon-mic-off.svg) center center no-repeat;
}
.bs-btn-form.audio.active {
  background: url(~src/assets/img/svg/icon-mic.svg) center center no-repeat;
}
.bs-btn-form.password {
  background: url(~src/assets/img/svg/icon-watch-off.svg) center center no-repeat;
}
.bs-btn-form.password.active {
  background: url(~src/assets/img/svg/icon-watch.svg) center center no-repeat;
}
.bs-btn-form:hover:not(.audio):not(.password):not(.no-bg) {
  background-color: var(--bs--color-principal-verde-hover);
  outline: var(--bs--color-principal-verde-hover) 1px solid;
}
.bs-btn-form[disabled]:hover:not(.audio):not(.password):not(.no-bg) {
  background-color: var(--bs--color-principal-verde);
  outline: var(--bs--color-principal-verde) 1px solid;
}
.bs-btn-remove {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: 2px dotted var(--bs--color-secundario-rojo);
  text-transform: none;
  height: auto;
  padding: 6px 0;
  min-height: 34px;
  width: 100%;
}
@media only screen and (min-width : 600px) {
  .bs-btn-remove {
    width: auto;
    min-width: 190px;
  }
  .bs-btn-remove.full {
    width: 100%;
  }
}
.bs-btn-remove span.label {
  font-size: 1.5rem;
  flex: 1;
  line-height: normal;
  margin: 0;
  text-align: left;
  color: var(--bs--color-secundario-rojo);
  padding-right: 15px;
}
.bs-btn-remove.icons span.remove {
  margin-left: 0;
  margin-right: 16px;
  margin-left: 15px;
}
.bs-btn-remove:hover {
  border: 2px dotted var(--bs--color-secundario-rojo-hover);
}
.bs-btn-remove:hover span.remove {
  filter: brightness(80%);
  -webkit-filter: brightness(80%);
}
.bs-btn-remove:hover span.label {
  color: var(--bs--color-secundario-rojo-hover);
}
.bs-btn-remove[disabled] span.remove {
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
}
.bs-btn-remove[disabled] span.label {
  color: var(--bs--color-secundario-rojo);
}
.bs-btn-remove[disabled]:hover {
  border: 2px dotted var(--bs--color-secundario-rojo);
}
.bs-btn-add {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: 2px dotted var(--bs--color-principal-verde);
  text-transform: none;
  height: auto;
  padding: 6px 0;
  min-height: 34px;
  width: 100%;
}
@media only screen and (min-width : 600px) {
  .bs-btn-add {
    width: auto;
    min-width: 190px;
  }
  .bs-btn-add.full {
    width: 100%;
  }
}
.bs-btn-add span.label {
  font-size: 1.5rem;
  flex: 1;
  line-height: normal;
  margin: 0;
  text-align: left;
  color: var(--bs--color-principal-verde);
  padding-right: 15px;
}
.bs-btn-add.icons span.add {
  margin-left: 0;
  margin-right: 16px;
  margin-left: 15px;
}
.bs-btn-add:hover {
  border: 2px dotted var(--bs--color-principal-verde-hover);
}
.bs-btn-add:hover span.add {
  filter: brightness(80%);
  -webkit-filter: brightness(80%);
}
.bs-btn-add:hover span.label {
  color: var(--bs--color-principal-verde-hover);
}
.bs-btn-add[disabled] span.add {
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
}
.bs-btn-add[disabled] span.label {
  color: var(--bs--color-principal-verde);
}
.bs-btn-add[disabled]:hover {
  border: 2px dotted var(--bs--color-principal-verde);
}
.bs-btn-link {
  color: var(--bs--color-principal-verde);
  text-decoration: underline;
  height: auto;
  text-transform: none;
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  display: inline;
  font-family: "overpassbold", sans-serif;
  text-underline-offset: 2px;
  min-width: 0;
  width: auto !important;
}
.bs-btn-link:hover {
  color: var(--bs--color-principal-verde-hover);
}
.bs-btn-link.icon {
  padding-left: 20px;
  position: relative;
}
.bs-btn-link.icon img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  height: 16px;
  width: auto;
}
.bs-btn-link.icon.invert {
  padding-right: 20px;
  padding-left: 0px;
}
.bs-btn-link.icon.invert img {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-100%, -50%);
}
.bs-btn-link.icon:hover img {
  filter: brightness(80%);
  -webkit-filter: brightness(80%);
}
.bs-btn-link[disabled] {
  color: var(--bs--color-validacion-verde-50);
}
.bs-btn-link[disabled] img {
  filter: brightness(100%) grayscale(100%);
  -webkit-filter: brightness(100%) grayscale(100%);
}
.bs-btn-link[disabled]:hover {
  color: var(--bs--color-validacion-verde-50);
}
.bs-btn-link[disabled]:hover img {
  filter: brightness(100%) grayscale(100%);
  -webkit-filter: brightness(100%) grayscale(100%);
}
.bs-btn-primary {
  background-color: var(--bs--color-principal-verde);
}
.bs-btn-primary.icons span.arrow {
  background: url(~src/assets/img/svg/arrow-button.svg) center center no-repeat;
}
.bs-btn-primary:hover {
  background-color: var(--bs--color-principal-verde-hover);
}
.bs-btn-primary[disabled]:hover {
  background-color: var(--bs--color-principal-verde);
}
.bs-btn-primary-blue {
  background-color: var(--bs--color-principal-azul);
}
.bs-btn-primary-blue.icons span.arrow {
  background: url(~src/assets/img/svg/arrow-button.svg) center center no-repeat;
}
.bs-btn-primary-blue:hover {
  background-color: var(--bs--color-principal-azul-hover);
}
.bs-btn-primary-blue[disabled]:hover {
  background-color: var(--bs--color-principal-azul);
}
.bs-btn-secondary {
  background-color: var(--bs--color-principal-blanco);
  color: var(--bs--color-principal-verde);
  border: 2px solid var(--bs--color-principal-verde);
}
.bs-btn-secondary.icons span.arrow {
  background: url(~src/assets/img/svg/arrow-button-secondary.svg) center center no-repeat;
}
.bs-btn-secondary.icons:hover span,
.bs-btn-secondary.icons:hover figure {
  filter: brightness(80%);
  -webkit-filter: brightness(80%);
}
.bs-btn-secondary:hover {
  color: var(--bs--color-principal-verde-hover);
  border: 2px solid var(--bs--color-principal-verde-hover);
}
.bs-btn-secondary[disabled]:hover {
  border: 2px solid var(--bs--color-principal-verde);
}
.bs-btn-secondary-alert {
  color: var(--bs--color-secundario-rojo);
  border: 2px solid var(--bs--color-secundario-rojo);
}
.bs-btn-secondary-alert:hover {
  color: var(--bs--color-secundario-rojo-hover);
  border: 2px solid var(--bs--color-secundario-rojo-hover);
}
.bs-btn-secondary-alert[disabled]:hover {
  border: 2px solid var(--bs--color-secundario-rojo);
}
.bs-btn-back {
  color: var(--bs--color-principal-negro);
  justify-content: flex-start;
  align-items: center;
  min-width: 50px;
  padding-left: 0px;
  text-transform: none;
  font-family: "overpassregular", sans-serif;
  font-size: 1.5rem;
}
.bs-btn-back span.arrow {
  margin-left: 0;
  margin-right: 10px;
  background: url(~src/assets/img/svg/arrow-button-back.svg) center center no-repeat;
}
.bs-btn-back:hover {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.bs-btn-back-color {
  color: var(--bs--color-principal-blanco);
  justify-content: flex-start;
  align-items: center;
  min-width: 50px;
  padding-left: 0px;
}
.bs-btn-back-color span.arrow {
  margin-left: 0;
  margin-right: 10px;
  background: url(~src/assets/img/svg/arrow-button-back-white.svg) center center no-repeat;
}
.bs-btn-back-color:hover {
  opacity: 0.5;
}
.bs-btn-terciary-color {
  color: var(--bs--color-principal-blanco);
  width: auto;
  min-width: 100px;
}
.bs-btn-terciary-color.icons span.arrow {
  background: url(~src/assets/img/svg/arrow-button.svg) center center no-repeat;
}
.bs-btn-terciary-color:hover {
  opacity: 0.5;
}
.bs-btn-icon-only {
  padding-right: 10px;
  padding-left: 10px;
  min-width: 0px;
}
.bs-btn-icon-only figure {
  margin: 0;
}
.bs-btn-terciary {
  width: auto;
  color: var(--bs--color-principal-verde);
  min-width: 100px;
}
.bs-btn-terciary.icons span.arrow {
  background: url(~src/assets/img/svg/arrow-button-secondary.svg) center center no-repeat;
}
.bs-btn-terciary.icons:hover span,
.bs-btn-terciary.icons:hover figure {
  filter: brightness(80%);
  -webkit-filter: brightness(80%);
}
.bs-btn-terciary:hover {
  color: var(--bs--color-principal-verde-hover);
}
.bs-btn-terciary[disabled]:hover {
  color: var(--bs--color-principal-verde);
}

.bs-selector {
  position: relative;
  min-height: 54px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: 3px solid var(--bs--color-grises-gris-15);
  padding: 8px 16px;
  width: calc(100% - 32px);
  cursor: pointer;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  margin: 6px 6px 6px 0;
  text-decoration: none;
  color: var(--bs--color-principal-negro);
}
.bs-selector:hover {
  border: 3px solid rgba(0, 111, 185, 0.5);
}
.bs-selector.active.simple {
  background-color: var(--bs--color-principal-azul);
  border: 3px solid var(--bs--color-principal-azul);
}
.bs-selector.active.simple .label {
  color: var(--bs--color-principal-blanco);
}
.bs-selector.simple {
  width: auto;
  min-width: 50px;
  max-width: 200px;
}
.bs-selector.simple span.label {
  line-height: 1;
}
@media only screen and (min-width : 1000px) {
  .bs-selector {
    width: auto;
    max-width: 600px;
    min-width: 200px;
  }
}
.bs-selector.w-100p {
  width: calc(100% - 32px);
}
.bs-selector.suspended figure {
  opacity: 0.5;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.bs-selector.suspended span.label, .bs-selector.suspended span.preface, .bs-selector.suspended span.caption {
  color: var(--bs--color-grises-gris-50) !important;
}
.bs-selector.suspended span.arrow, .bs-selector.suspended span.delete {
  opacity: 0.5;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.bs-selector[disabled] {
  border: 3px solid var(--bs--color-grises-gris-10);
  cursor: not-allowed;
}
.bs-selector[disabled] span.caption, .bs-selector[disabled] span.label, .bs-selector[disabled] span.preface {
  color: var(--bs--color-grises-gris-50);
  color: var(--bs--color-grises-gris-50);
}
.bs-selector[disabled] span.arrow, .bs-selector[disabled] span.delete {
  opacity: 0.5;
}
.bs-selector figure {
  margin-right: 16px;
  width: 30px;
  height: 30px;
  display: inline-block;
}
.bs-selector figure img {
  width: 100%;
  height: auto;
}
.bs-selector.grande figure {
  height: auto;
  max-height: 48px;
  width: 48px;
}
.bs-selector.superior {
  justify-content: space-between;
  align-items: flex-start;
}
.bs-selector.superior span.arrow {
  margin-top: 20px;
}
.bs-selector div.datos-informacion {
  width: 100%;
  flex: 0 0 100%;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  margin-top: 12px;
  padding-top: 8px;
  border-top: 1px solid var(--bs--color-grises-gris-20);
}
.bs-selector div.datos-informacion .dato span {
  display: block;
  color: var(--bs--color-grises-gris-50);
  font-size: 1.4rem;
  font-family: "overpassregular", sans-serif;
  line-height: 1.3;
}
.bs-selector div.datos-informacion .dato h4 {
  display: block;
  color: var(--bs--color-principal-azul);
  font-size: 1.8rem;
  font-family: "overpassbold", sans-serif;
}
.bs-selector div.datos-informacion .dato:last-child > span,
.bs-selector div.datos-informacion .dato:last-child > h4 {
  text-align: right;
}
.bs-selector div.datos-informacion .dato.estado {
  flex: 1;
}
.bs-selector div.datos-informacion .dato.monto {
  flex: 1;
}
.bs-selector span {
  text-align: left;
}
.bs-selector span.arrow {
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url(~src/assets/img/svg/arrow-button-secondary.svg) center center no-repeat;
  margin-left: 16px;
}
.bs-selector span.delete {
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url(~src/assets/img/svg/delete-button.svg) center center no-repeat;
  margin-left: 16px;
}
.bs-selector span.label {
  font-family: "overpassbold", sans-serif;
  font-size: 1.5rem;
  color: var(--bs--color-principal-negro);
  flex: 1;
  line-height: normal;
}
@media only screen and (min-width : 1000px) {
  .bs-selector span.label {
    line-height: 2.2rem;
  }
}
.bs-selector span.label + .caption {
  margin-left: 16px;
}
.bs-selector span.bs-title {
  display: block;
  font-family: "overpassbold", sans-serif;
  font-size: 1.5rem;
  color: var(--bs--color-principal-negro);
  line-height: normal;
}
.bs-selector span.bs-description {
  display: block;
  font-family: "overpassregular", sans-serif;
  font-size: 1rem;
  color: var(--bs--color-grises-gris-80);
  line-height: normal;
}
@media only screen and (min-width : 600px) {
  .bs-selector span.bs-description {
    font-size: 1.1rem;
  }
}
.bs-selector span.preface {
  display: block;
  font-family: "overpassregular", sans-serif;
  font-size: 1.3rem;
  color: var(--bs--color-principal-azul);
  line-height: normal;
}
.bs-selector span.bs-caption {
  display: block;
  font-family: "overpassregular", sans-serif;
  font-size: 1.3rem;
  color: var(--bs--color-grises-gris-80);
  line-height: normal;
}
.bs-selector span.bs-caption strong {
  color: var(--bs--color-principal-azul);
  font-family: "overpassbold", sans-serif;
}
.bs-selector.visited {
  border: 3px solid rgba(0, 111, 185, 0.5);
}
.bs-selector.visited span.arrow {
  background-image: url(~src/assets/img/svg/check-button-blue.svg);
}

.bs-estados-generales {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-estados-generales.derecha {
  justify-content: flex-end;
  align-items: center;
}
.bs-estados-generales.izquierda {
  justify-content: flex-start;
  align-items: center;
}
.bs-estados-generales.centro {
  justify-content: center;
  align-items: center;
}
.bs-estados-generales h5 {
  font-size: 1.5rem;
  font-family: "overpassbold", sans-serif;
  padding-right: 8px;
}
@media only screen and (min-width : 600px) {
  .bs-estados-generales h5 {
    font-size: 1.8rem;
  }
}
.bs-estados-generales span {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  display: inline-block;
  background-color: var(--bs--color-principal-negro);
}
@media only screen and (min-width : 600px) {
  .bs-estados-generales span {
    height: 16px;
    width: 16px;
  }
}
.bs-estados-generales.verde h5 {
  color: var(--bs--color-principal-verde);
}
.bs-estados-generales.verde span {
  background-color: var(--bs--color-principal-verde);
}
.bs-estados-generales.azul h5 {
  color: var(--bs--color-principal-azul);
}
.bs-estados-generales.azul span {
  background-color: var(--bs--color-principal-azul);
}
.bs-estados-generales.rojo h5 {
  color: var(--bs--color-principal-rojo);
}
.bs-estados-generales.rojo span {
  background-color: var(--bs--color-principal-rojo);
}

.bs-separador {
  clear: both;
  float: none;
  width: 100%;
  display: block;
  height: 10px;
}
.bs-separador.h-5 {
  height: 5px;
}
.bs-separador.h-10 {
  height: 10px;
}
.bs-separador.h-15 {
  height: 15px;
}
.bs-separador.h-20 {
  height: 20px;
}
.bs-separador.h-25 {
  height: 25px;
}
.bs-separador.h-30 {
  height: 30px;
}
.bs-separador.h-35 {
  height: 35px;
}
.bs-separador.h-40 {
  height: 40px;
}
.bs-separador.h-45 {
  height: 45px;
}
.bs-separador.h-50 {
  height: 50px;
}
.bs-separador.h-55 {
  height: 55px;
}
.bs-separador.h-60 {
  height: 60px;
}
.bs-separador.h-65 {
  height: 65px;
}
.bs-separador.h-70 {
  height: 70px;
}
.bs-separador.h-75 {
  height: 75px;
}
.bs-separador.h-80 {
  height: 80px;
}
.bs-separador.h-85 {
  height: 85px;
}
.bs-separador.h-90 {
  height: 90px;
}
.bs-separador.h-95 {
  height: 95px;
}
.bs-separador.h-100 {
  height: 100px;
}
.bs-separador.h-105 {
  height: 105px;
}
.bs-separador.lineal {
  position: relative;
  margin: 10px 0;
}
.bs-separador.lineal:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: var(--bs--color-grises-gris-40);
}
.bs-separador.lineal.th-1:after {
  -webkit-border-radius: calc($height/2);
  -moz-border-radius: calc($height/2);
  -ms-border-radius: calc($height/2);
  border-radius: calc($height/2);
  height: 1px !important;
}
.bs-separador.lineal.th-2:after {
  -webkit-border-radius: calc($height/2);
  -moz-border-radius: calc($height/2);
  -ms-border-radius: calc($height/2);
  border-radius: calc($height/2);
  height: 2px !important;
}
.bs-separador.lineal.th-3:after {
  -webkit-border-radius: calc($height/2);
  -moz-border-radius: calc($height/2);
  -ms-border-radius: calc($height/2);
  border-radius: calc($height/2);
  height: 3px !important;
}
.bs-separador.lineal.th-4:after {
  -webkit-border-radius: calc($height/2);
  -moz-border-radius: calc($height/2);
  -ms-border-radius: calc($height/2);
  border-radius: calc($height/2);
  height: 4px !important;
}
.bs-separador.lineal.th-5:after {
  -webkit-border-radius: calc($height/2);
  -moz-border-radius: calc($height/2);
  -ms-border-radius: calc($height/2);
  border-radius: calc($height/2);
  height: 5px !important;
}
.bs-separador.lineal.th-6:after {
  -webkit-border-radius: calc($height/2);
  -moz-border-radius: calc($height/2);
  -ms-border-radius: calc($height/2);
  border-radius: calc($height/2);
  height: 6px !important;
}
.bs-separador.lineal.th-7:after {
  -webkit-border-radius: calc($height/2);
  -moz-border-radius: calc($height/2);
  -ms-border-radius: calc($height/2);
  border-radius: calc($height/2);
  height: 7px !important;
}
.bs-separador.lineal.th-8:after {
  -webkit-border-radius: calc($height/2);
  -moz-border-radius: calc($height/2);
  -ms-border-radius: calc($height/2);
  border-radius: calc($height/2);
  height: 8px !important;
}
.bs-separador.lineal.th-9:after {
  -webkit-border-radius: calc($height/2);
  -moz-border-radius: calc($height/2);
  -ms-border-radius: calc($height/2);
  border-radius: calc($height/2);
  height: 9px !important;
}
.bs-separador.lineal.th-10:after {
  -webkit-border-radius: calc($height/2);
  -moz-border-radius: calc($height/2);
  -ms-border-radius: calc($height/2);
  border-radius: calc($height/2);
  height: 10px !important;
}

figure.bs-iconos {
  height: 64px;
  width: 64px;
  margin: 0;
  padding: 0;
  /*Sistema de tamaños, la caja */
  display: inline-block;
}
figure.bs-iconos img {
  width: 100%;
  height: auto;
}
figure.bs-iconos.block {
  display: block;
}
figure.bs-iconos.w-5 {
  height: 5px;
  width: 5px;
}
figure.bs-iconos.w-10 {
  height: 10px;
  width: 10px;
}
figure.bs-iconos.w-15 {
  height: 15px;
  width: 15px;
}
figure.bs-iconos.w-20 {
  height: 20px;
  width: 20px;
}
figure.bs-iconos.w-25 {
  height: 25px;
  width: 25px;
}
figure.bs-iconos.w-30 {
  height: 30px;
  width: 30px;
}
figure.bs-iconos.w-35 {
  height: 35px;
  width: 35px;
}
figure.bs-iconos.w-40 {
  height: 40px;
  width: 40px;
}
figure.bs-iconos.w-45 {
  height: 45px;
  width: 45px;
}
figure.bs-iconos.w-50 {
  height: 50px;
  width: 50px;
}
figure.bs-iconos.w-55 {
  height: 55px;
  width: 55px;
}
figure.bs-iconos.w-60 {
  height: 60px;
  width: 60px;
}
figure.bs-iconos.w-65 {
  height: 65px;
  width: 65px;
}
figure.bs-iconos.w-70 {
  height: 70px;
  width: 70px;
}
figure.bs-iconos.w-75 {
  height: 75px;
  width: 75px;
}
figure.bs-iconos.w-80 {
  height: 80px;
  width: 80px;
}
figure.bs-iconos.w-85 {
  height: 85px;
  width: 85px;
}
figure.bs-iconos.w-90 {
  height: 90px;
  width: 90px;
}
figure.bs-iconos.w-95 {
  height: 95px;
  width: 95px;
}
figure.bs-iconos.w-100 {
  height: 100px;
  width: 100px;
}
figure.bs-iconos.w-105 {
  height: 105px;
  width: 105px;
}
figure.bs-iconos.w-110 {
  height: 110px;
  width: 110px;
}
figure.bs-iconos.w-115 {
  height: 115px;
  width: 115px;
}
figure.bs-iconos.w-120 {
  height: 120px;
  width: 120px;
}
figure.bs-iconos.w-125 {
  height: 125px;
  width: 125px;
}
figure.bs-iconos.w-130 {
  height: 130px;
  width: 130px;
}
figure.bs-iconos.w-135 {
  height: 135px;
  width: 135px;
}
figure.bs-iconos.w-140 {
  height: 140px;
  width: 140px;
}
figure.bs-iconos.w-145 {
  height: 145px;
  width: 145px;
}
figure.bs-iconos.w-150 {
  height: 150px;
  width: 150px;
}
figure.bs-iconos.w-155 {
  height: 155px;
  width: 155px;
}
figure.bs-iconos.w-16 {
  height: 16px;
  width: 16px;
}
figure.bs-iconos.w-24 {
  height: 24px;
  width: 24px;
}
figure.bs-iconos.w-32 {
  height: 32px;
  width: 32px;
}
figure.bs-iconos.w-48 {
  height: 48px;
  width: 48px;
}
figure.bs-iconos.w-64 {
  height: 64px;
  width: 64px;
}
figure.bs-iconos.w-72 {
  height: 72px;
  width: 72px;
}
figure.bs-iconos.w-96 {
  height: 96px;
  width: 96px;
}
figure.bs-iconos.w-144 {
  height: 144px;
  width: 144px;
}

header.bs-header {
  background-color: var(--bs--color-principal-blanco);
  z-index: 990;
  height: 60px;
  width: 100%;
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  border-bottom: 1px solid var(--bs--color-grises-gris-20);
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
header.bs-header a.logo {
  height: 32px;
  display: inline-block;
  margin-left: 8px;
}
@media only screen and (min-width : 360px) {
  header.bs-header a.logo {
    margin: 0 16px;
  }
}
@media only screen and (min-width : 600px) {
  header.bs-header a.logo {
    height: 32px;
  }
}
header.bs-header a.logo img {
  height: 24px;
  margin-top: 4px;
  width: auto;
}
@media only screen and (min-width : 360px) {
  header.bs-header a.logo img {
    margin-top: 0;
    height: 32px;
  }
}
header.bs-header .actions {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  flex: 1;
}
header.bs-header .actions .action {
  position: relative;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
header.bs-header .actions .action span.bubble {
  width: 16px;
  height: 16px;
  display: grid;
  place-items: center;
  font-size: 1.1rem;
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-blanco);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 20px;
}
header.bs-header .actions .action span.bubble.azul {
  background: var(--bs--color-principal-azul);
}
header.bs-header .actions .action span.bubble.verde {
  background: var(--bs--color-principal-verde);
}
header.bs-header button.menu {
  width: 30px;
  height: 30px;
  margin: 0 8px;
  display: inline-block;
  background: url(~src/assets/img/svg/icon-ui-nav-menu.svg) center center no-repeat;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  background-size: 30px auto;
}
header.bs-header button.menu.active {
  background-image: url(~src/assets/img/svg/delete-button.svg);
  background-size: 24px auto !important;
}
header.bs-header button.menu:hover {
  opacity: 0.7;
}
header.bs-header.corporativo {
  border-bottom: none;
  height: auto;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(100, 100, 100, 0.09);
  -moz-box-shadow: 0px 0px 20px 0px rgba(100, 100, 100, 0.09);
  -ms-box-shadow: 0px 0px 20px 0px rgba(100, 100, 100, 0.09);
  box-shadow: 0px 0px 20px 0px rgba(100, 100, 100, 0.09);
}
header.bs-header.corporativo .top,
header.bs-header.corporativo .bottom {
  width: 100%;
}
header.bs-header.corporativo .top {
  border-bottom: 1px solid var(--bs--color-grises-gris-20);
  display: block;
}
header.bs-header.corporativo .top .bs-contenedor {
  position: relative;
  width: 100%;
  height: 60px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
header.bs-header.corporativo .top .bs-contenedor .actions {
  height: 40px;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu {
  position: static;
  padding: 0 2px;
}
@media only screen and (min-width : 600px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action.menu {
    position: relative;
  }
}
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu .bs-menu-desplegable {
  display: none;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu:hover .bs-menu-desplegable {
  display: block;
}
@media only screen and (min-width : 360px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action.menu {
    padding: 0 3px;
  }
}
@media only screen and (min-width : 430px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action.menu {
    padding: 0 10px;
  }
}
@media only screen and (min-width : 600px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action.menu {
    padding: 0 20px;
    border-right: 1px solid var(--bs--color-grises-gris-40);
  }
  header.bs-header.corporativo .top .bs-contenedor .actions .action.menu:last-child {
    border-right: none;
  }
}
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu > a {
  position: relative;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  cursor: pointer;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu > a > figcaption {
  display: none;
  margin-right: 20px;
}
@media only screen and (min-width : 600px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action.menu > a > figcaption {
    display: inline-block;
  }
}
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu > a > figcaption p,
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu > a > figcaption h4 {
  font-size: 1.35rem;
  line-height: 1.3;
  text-align: right;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu > a > figcaption h4 {
  font-family: "overpassbold", sans-serif;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu > a > figure {
  height: 40px;
  display: grid;
  place-items: center;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu > a > figure img {
  height: 30px;
  width: auto;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu > a:hover > figcaption {
  opacity: 0.5;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action.menu > a:hover > figure {
  opacity: 0.5;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu) {
  cursor: pointer;
  padding: 0 2px;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu).buscar {
  position: absolute;
  top: 60px;
  right: 0;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu).buscar label {
  margin: 0;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu).buscar label .btn {
  margin: 0;
  height: 30px !important;
}
@media only screen and (min-width : 1000px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu).buscar label .btn {
    height: 40px !important;
  }
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu).buscar label .btn figure {
  height: 30px !important;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu).buscar label .btn figure img {
  height: 24px;
}
@media only screen and (min-width : 1000px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu).buscar label .btn figure {
    height: 40px !important;
    display: grid;
    place-items: center;
  }
  header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu).buscar label .btn figure img {
    height: 34px;
  }
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu).buscar input {
  background: none !important;
  width: 100%;
  margin: 0;
  line-height: 30px !important;
  text-align-last: right;
  padding-right: 8px;
}
@media only screen and (min-width : 1000px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu).buscar input {
    width: 200px;
    line-height: 40px !important;
  }
}
@media only screen and (min-width : 1000px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu).buscar {
    position: static;
  }
}
@media only screen and (min-width : 360px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu) {
    padding: 0 3px;
  }
}
@media only screen and (min-width : 430px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu) {
    padding: 0 10px;
  }
}
@media only screen and (min-width : 1000px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu) {
    padding: 0 20px;
    border-right: 1px solid var(--bs--color-grises-gris-40);
  }
  header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu):last-child {
    border-right: none;
  }
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu) > figcaption {
  display: none;
  margin-right: 20px;
}
@media only screen and (min-width : 600px) {
  header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu) > figcaption {
    display: inline-block;
  }
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu) > figcaption p,
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu) > figcaption h4 {
  font-size: 1.35rem;
  line-height: 1.3;
  text-align: right;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu) > figcaption h4 {
  font-family: "overpassbold", sans-serif;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu) > figure {
  height: 40px;
  display: grid;
  place-items: center;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu) > figure img {
  height: 30px;
  width: auto;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu):hover > figcaption {
  opacity: 0.5;
}
header.bs-header.corporativo .top .bs-contenedor .actions .action:not(.menu):hover > figure {
  opacity: 0.5;
}
header.bs-header.corporativo .top .bs-contenedor button.menu {
  display: inline-block;
}
@media only screen and (min-width : 360px) {
  header.bs-header.corporativo .top .bs-contenedor button.menu {
    margin: 0 16px;
    background-size: 40px auto;
  }
  header.bs-header.corporativo .top .bs-contenedor button.menu + a.logo {
    margin-left: 0px;
  }
}
@media only screen and (min-width : 1000px) {
  header.bs-header.corporativo .top .bs-contenedor button.menu {
    display: none;
  }
  header.bs-header.corporativo .top .bs-contenedor button.menu + a.logo {
    margin-left: 16px;
  }
}
header.bs-header.corporativo .top .bs-contenedor button.menu:hover {
  opacity: 0.7;
}
header.bs-header.corporativo .bottom {
  height: 30px;
  width: 100%;
}
header.bs-header.corporativo .bottom > .bs-contenedor {
  position: relative;
}
header.bs-header.corporativo .bottom > .bs-contenedor > ul {
  height: 30px;
  width: 100%;
  display: none;
}
@media only screen and (min-width : 1000px) {
  header.bs-header.corporativo .bottom > .bs-contenedor > ul {
    display: -ms-flex;
    display: -webkit-inline-flex;
    display: inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
}
header.bs-header.corporativo .bottom > .bs-contenedor > ul > li {
  list-style: none;
  position: relative;
}
header.bs-header.corporativo .bottom > .bs-contenedor > ul > li > a {
  cursor: pointer;
  padding: 0 10px;
  font-size: var(--bs--font-size-menu);
  color: var(--bs--color-principal-negro);
  text-decoration: none;
  font-family: "overpassbold", sans-serif;
  line-height: 3rem;
}
header.bs-header.corporativo .bottom > .bs-contenedor > ul > li .bs-menu-desplegable {
  display: none;
  z-index: 990;
}
header.bs-header.corporativo .bottom > .bs-contenedor > ul > li:hover > a {
  color: var(--bs--color-principal-verde);
}
header.bs-header.corporativo .bottom > .bs-contenedor > ul > li:hover .bs-menu-desplegable {
  display: block;
}
header.bs-header.privado {
  height: 50px;
}
header.bs-header.privado .bs-contenedor {
  position: relative;
  width: 100%;
  height: 50px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
@media only screen and (min-width : 600px) {
  header.bs-header.privado .bs-contenedor {
    height: 60px;
  }
}
@media only screen and (min-width : 600px) {
  header.bs-header.privado {
    height: 60px;
  }
}
header.bs-header.privado .actions {
  height: 40px;
}
header.bs-header.privado .actions .action.menu {
  position: static;
  padding: 0 10px;
  border-right: 1px solid var(--bs--color-grises-gris-40);
}
@media only screen and (min-width : 600px) {
  header.bs-header.privado .actions .action.menu {
    position: relative;
  }
}
header.bs-header.privado .actions .action.menu .bs-menu-desplegable {
  display: none;
}
header.bs-header.privado .actions .action.menu:hover .bs-menu-desplegable {
  display: block;
}
@media only screen and (min-width : 600px) {
  header.bs-header.privado .actions .action.menu {
    padding: 0 20px;
  }
}
header.bs-header.privado .actions .action.menu:last-child {
  border-right: none;
}
header.bs-header.privado .actions .action.menu > a {
  position: relative;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  width: 100%;
  cursor: pointer;
}
header.bs-header.privado .actions .action.menu > a > figcaption {
  display: none;
  margin-right: 20px;
}
@media only screen and (min-width : 600px) {
  header.bs-header.privado .actions .action.menu > a > figcaption {
    display: inline-block;
  }
}
header.bs-header.privado .actions .action.menu > a > figcaption p,
header.bs-header.privado .actions .action.menu > a > figcaption h4 {
  font-size: 1.35rem;
  line-height: 1.3;
  text-align: right;
}
header.bs-header.privado .actions .action.menu > a > figcaption h4 {
  font-family: "overpassbold", sans-serif;
}
header.bs-header.privado .actions .action.menu > a > figure {
  height: 40px;
  display: grid;
  place-items: center;
}
header.bs-header.privado .actions .action.menu > a > figure img {
  height: 30px;
  width: auto;
}
header.bs-header.privado .actions .action.menu > a:hover > figcaption {
  opacity: 0.5;
}
header.bs-header.privado .actions .action.menu > a:hover > figure {
  opacity: 0.5;
}
header.bs-header.privado .actions .action:not(.menu) {
  cursor: pointer;
  padding: 0 10px;
  border-right: 1px solid var(--bs--color-grises-gris-40);
}
@media only screen and (min-width : 600px) {
  header.bs-header.privado .actions .action:not(.menu) {
    padding: 0 20px;
  }
}
header.bs-header.privado .actions .action:not(.menu):last-child {
  border-right: none;
}
header.bs-header.privado .actions .action:not(.menu) > figcaption {
  display: none;
  margin-right: 20px;
}
@media only screen and (min-width : 600px) {
  header.bs-header.privado .actions .action:not(.menu) > figcaption {
    display: inline-block;
  }
}
header.bs-header.privado .actions .action:not(.menu) > figcaption p,
header.bs-header.privado .actions .action:not(.menu) > figcaption h4 {
  font-size: 1.35rem;
  line-height: 1.3;
  text-align: right;
}
header.bs-header.privado .actions .action:not(.menu) > figcaption h4 {
  font-family: "overpassbold", sans-serif;
}
header.bs-header.privado .actions .action:not(.menu) > figure {
  height: 40px;
  display: grid;
  place-items: center;
}
header.bs-header.privado .actions .action:not(.menu) > figure img {
  height: 30px;
  width: auto;
}
header.bs-header.privado .actions .action:not(.menu):hover > figcaption {
  opacity: 0.5;
}
header.bs-header.privado .actions .action:not(.menu):hover > figure {
  opacity: 0.5;
}
header.bs-header.privado button.menu {
  margin: 0 16px;
  background-size: 40px auto;
}
header.bs-header.privado button.menu + a.logo {
  margin-left: 0px;
}
header.bs-header.simple .bs-contenedor {
  height: 60px;
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
header.bs-header.simple .bs-contenedor h1 {
  flex: 1;
  text-align: center;
  display: none;
}
@media only screen and (min-width : 600px) {
  header.bs-header.simple .bs-contenedor h1 {
    display: inline-block;
  }
}
header.bs-header.simple .bs-contenedor a.logo {
  max-width: 150px;
  height: 32px;
  margin-left: 16px;
}
@media only screen and (min-width : 600px) {
  header.bs-header.simple .bs-contenedor a.logo {
    height: 40px;
  }
}
@media only screen and (min-width : 1280px) {
  header.bs-header.simple .bs-contenedor a.logo {
    margin-left: 0px;
  }
}
header.bs-header.simple .bs-contenedor a.logo img {
  height: 32px;
}
@media only screen and (min-width : 600px) {
  header.bs-header.simple .bs-contenedor a.logo img {
    height: 40px;
  }
}
header.bs-header.simple .bs-contenedor .floating-img {
  margin-left: auto;
  width: 64px;
  height: auto;
  margin-right: 16px;
}
@media only screen and (min-width : 600px) {
  header.bs-header.simple .bs-contenedor .floating-img {
    width: auto;
  }
}
@media only screen and (min-width : 1280px) {
  header.bs-header.simple .bs-contenedor .floating-img {
    margin-right: 0px;
  }
}
header.bs-header.simple .bs-contenedor a.resumen {
  text-decoration: none;
  height: 60px;
  width: clamp(70px, 10vw, 88px);
  background-color: var(--bs--color-principal-verde);
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
header.bs-header.simple .bs-contenedor a.resumen:hover, header.bs-header.simple .bs-contenedor a.resumen:active {
  background-color: var(--bs--color-principal-verde-hover);
}
header.bs-header.simple .bs-contenedor a.resumen figure {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0;
  padding: 0;
}
header.bs-header.simple .bs-contenedor a.resumen figure img {
  margin: 2px auto;
}
header.bs-header.simple .bs-contenedor a.resumen span {
  color: var(--bs--color-principal-blanco);
  font-family: "overpassregular", sans-serif;
  font-size: 1.2rem;
  width: 100%;
  display: block;
  text-align: center;
  line-height: 1;
}
@media only screen and (min-width : 600px) {
  header.bs-header.simple .bs-contenedor a.resumen {
    display: none;
  }
}
header.bs-header.movil {
  position: static;
}
header.bs-header.movil + .separador {
  display: none;
}

footer.bs-footer {
  width: 100%;
  margin-top: 40px;
}
footer.bs-footer .simple-content {
  width: 98%;
  margin: 0 auto;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
@media only screen and (min-width : 600px) {
  footer.bs-footer .simple-content {
    justify-content: space-between;
    align-items: center;
  }
}
footer.bs-footer .simple-content > p {
  flex: 0 0 100%;
  text-align: center !important;
}
footer.bs-footer .simple-content .marca {
  flex: 0 0 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
footer.bs-footer .simple-content .marca .logo {
  margin: 12px 0;
}
@media only screen and (min-width : 600px) {
  footer.bs-footer .simple-content .marca .logo {
    margin: 0;
  }
}
@media only screen and (min-width : 600px) {
  footer.bs-footer .simple-content .marca {
    justify-content: flex-start;
    align-items: center;
    flex: 0 0 200px;
  }
}
footer.bs-footer .simple-content .redes-sociales {
  flex: 0 0 100%;
  margin: 12px 0;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
@media only screen and (min-width : 600px) {
  footer.bs-footer .simple-content .redes-sociales {
    flex: 1;
    margin: 0;
    justify-content: flex-end;
    align-items: center;
  }
}
footer.bs-footer .simple-content .redes-sociales p {
  flex: 0 0 100%;
  text-align: center;
}
@media only screen and (min-width : 600px) {
  footer.bs-footer .simple-content .redes-sociales p {
    text-align: right;
  }
}
footer.bs-footer .simple-content .redes-sociales a {
  margin-left: 10px;
}
footer.bs-footer .simple-content .redes-sociales a:first-child {
  margin-left: 0;
}
footer.bs-footer .simple-content .redes-sociales a:hover {
  opacity: 0.5;
}

.border-rojo {
  outline: 1px solid var(--bs--color-secundario-rojo);
}
.border-rojo-dotted {
  outline: 1px dotted var(--bs--color-secundario-rojo);
}
.border-rojo-dotted.gris {
  outline-color: var(--bs--color-grises-gris-40);
}
.border-rojo.gris {
  outline-color: var(--bs--color-grises-gris-20);
}

.bs-border-all {
  border: 2px solid var(--bs--color-grises-gris-10);
}
.bs-border-all.gris-10 {
  border-color: var(--bs--color-grises-gris-10);
}
.bs-border-all.gris-15 {
  border-color: var(--bs--color-grises-gris-15);
}
.bs-border-all.gris-20 {
  border-color: var(--bs--color-grises-gris-20);
}
.bs-border-all.gris-40 {
  border-color: var(--bs--color-grises-gris-40);
}
.bs-border-all.gris-50 {
  border-color: var(--bs--color-grises-gris-50);
}
.bs-border-all.gris-80 {
  border-color: var(--bs--color-grises-gris-80);
}
@media only screen and (max-width : 810px) {
  .bs-border-all.no-border-tablet {
    border: none;
  }
}
@media only screen and (max-width : 500px) {
  .bs-border-all.no-border-mobile {
    border: none;
  }
}

.bs-border-left {
  border-left: 2px solid var(--bs--color-grises-gris-20);
}
.bs-border-right {
  border-right: 2px solid var(--bs--color-grises-gris-20);
}
.bs-border-top {
  border-top: 2px solid var(--bs--color-grises-gris-20);
}
.bs-border-bottom {
  border-bottom: 2px solid var(--bs--color-grises-gris-20);
}
@media only screen and (max-width : 810px) {
  .bs-border-left.no-border-tablet, .bs-border-right.no-border-tablet, .bs-border-top.no-border-tablet, .bs-border-bottom.no-border-tablet {
    border: none;
  }
}
@media only screen and (max-width : 500px) {
  .bs-border-left.no-border-mobile, .bs-border-right.no-border-mobile, .bs-border-top.no-border-mobile, .bs-border-bottom.no-border-mobile {
    border: none;
  }
}
.bs-border-left.gris-10, .bs-border-right.gris-10, .bs-border-top.gris-10, .bs-border-bottom.gris-10 {
  border-color: var(--bs--color-grises-gris-10);
}
.bs-border-left.gris-15, .bs-border-right.gris-15, .bs-border-top.gris-15, .bs-border-bottom.gris-15 {
  border-color: var(--bs--color-grises-gris-15);
}
.bs-border-left.gris-20, .bs-border-right.gris-20, .bs-border-top.gris-20, .bs-border-bottom.gris-20 {
  border-color: var(--bs--color-grises-gris-20);
}
.bs-border-left.gris-40, .bs-border-right.gris-40, .bs-border-top.gris-40, .bs-border-bottom.gris-40 {
  border-color: var(--bs--color-grises-gris-40);
}
.bs-border-left.gris-50, .bs-border-right.gris-50, .bs-border-top.gris-50, .bs-border-bottom.gris-50 {
  border-color: var(--bs--color-grises-gris-50);
}
.bs-border-left.gris-80, .bs-border-right.gris-80, .bs-border-top.gris-80, .bs-border-bottom.gris-80 {
  border-color: var(--bs--color-grises-gris-80);
}
@media only screen and (max-width : 810px) {
  .bs-border.no-border-tablet {
    border: none;
  }
}
@media only screen and (max-width : 500px) {
  .bs-border.no-border-mobile {
    border: none;
  }
}

.bs-block-color {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  margin: 6px 0;
  width: calc(100% - 12px);
  min-height: 50px;
  padding: 16px 6px;
  background: var(--bs--color-principal-verde);
}

.bs-bloque {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 50px;
  display: block;
}
.bs-bloque > article {
  width: calc(100% - 16px);
  padding: 16px 8px;
  margin: auto;
}
.bs-bloque > article.padding-h-4 {
  padding: 4px 8px;
}
.bs-bloque > article.padding-h-8 {
  padding: 8px 8px;
}
.bs-bloque > article.padding-h-12 {
  padding: 12px 8px;
}
.bs-bloque.color.verde {
  background-color: var(--bs--color-principal-verde);
}
.bs-bloque.color.azul {
  background-color: var(--bs--color-principal-azul);
}
.bs-bloque.h-0 {
  height: 0px;
}
.bs-bloque.h-5 {
  height: 5px;
}
.bs-bloque.h-10 {
  height: 10px;
}
.bs-bloque.h-15 {
  height: 15px;
}
.bs-bloque.h-20 {
  height: 20px;
}
.bs-bloque.h-25 {
  height: 25px;
}
.bs-bloque.h-30 {
  height: 30px;
}
.bs-bloque.h-35 {
  height: 35px;
}
.bs-bloque.h-40 {
  height: 40px;
}
.bs-bloque.h-45 {
  height: 45px;
}
.bs-bloque.h-50 {
  height: 50px;
}
.bs-bloque.h-55 {
  height: 55px;
}
.bs-bloque.h-60 {
  height: 60px;
}
.bs-bloque.h-65 {
  height: 65px;
}
.bs-bloque.h-70 {
  height: 70px;
}
.bs-bloque.h-75 {
  height: 75px;
}
.bs-bloque.h-80 {
  height: 80px;
}
.bs-bloque.h-85 {
  height: 85px;
}
.bs-bloque.h-90 {
  height: 90px;
}
.bs-bloque.h-95 {
  height: 95px;
}
.bs-bloque.h-100 {
  height: 100px;
}
.bs-bloque.h-105 {
  height: 105px;
}
.bs-bloque.h-110 {
  height: 110px;
}
.bs-bloque.h-115 {
  height: 115px;
}
.bs-bloque.h-120 {
  height: 120px;
}
.bs-bloque.h-125 {
  height: 125px;
}
.bs-bloque.h-130 {
  height: 130px;
}
.bs-bloque.h-135 {
  height: 135px;
}
.bs-bloque.h-140 {
  height: 140px;
}
.bs-bloque.h-145 {
  height: 145px;
}
.bs-bloque.h-150 {
  height: 150px;
}
.bs-bloque.h-155 {
  height: 155px;
}
.bs-bloque.h-160 {
  height: 160px;
}
.bs-bloque.h-165 {
  height: 165px;
}
.bs-bloque.h-170 {
  height: 170px;
}
.bs-bloque.h-175 {
  height: 175px;
}
.bs-bloque.h-180 {
  height: 180px;
}
.bs-bloque.h-185 {
  height: 185px;
}
.bs-bloque.h-190 {
  height: 190px;
}
.bs-bloque.h-195 {
  height: 195px;
}
.bs-bloque.h-200 {
  height: 200px;
}
.bs-bloque.h-205 {
  height: 205px;
}
.bs-bloque.h-210 {
  height: 210px;
}
.bs-bloque.h-215 {
  height: 215px;
}
.bs-bloque.h-220 {
  height: 220px;
}
.bs-bloque.h-225 {
  height: 225px;
}
.bs-bloque.h-230 {
  height: 230px;
}
.bs-bloque.h-235 {
  height: 235px;
}
.bs-bloque.h-240 {
  height: 240px;
}
.bs-bloque.h-245 {
  height: 245px;
}
.bs-bloque.h-250 {
  height: 250px;
}
.bs-bloque.h-255 {
  height: 255px;
}
.bs-bloque.h-260 {
  height: 260px;
}
.bs-bloque.h-265 {
  height: 265px;
}
.bs-bloque.h-270 {
  height: 270px;
}
.bs-bloque.h-275 {
  height: 275px;
}
.bs-bloque.h-280 {
  height: 280px;
}
.bs-bloque.h-285 {
  height: 285px;
}
.bs-bloque.h-290 {
  height: 290px;
}
.bs-bloque.h-295 {
  height: 295px;
}
.bs-bloque.h-300 {
  height: 300px;
}
.bs-bloque.h-305 {
  height: 305px;
}
.bs-bloque.h-310 {
  height: 310px;
}
.bs-bloque.h-315 {
  height: 315px;
}
.bs-bloque.h-320 {
  height: 320px;
}
.bs-bloque.h-325 {
  height: 325px;
}
.bs-bloque.h-330 {
  height: 330px;
}
.bs-bloque.h-335 {
  height: 335px;
}
.bs-bloque.h-340 {
  height: 340px;
}
.bs-bloque.h-345 {
  height: 345px;
}
.bs-bloque.h-350 {
  height: 350px;
}
.bs-bloque.h-355 {
  height: 355px;
}
.bs-bloque.h-360 {
  height: 360px;
}
.bs-bloque.h-365 {
  height: 365px;
}
.bs-bloque.h-370 {
  height: 370px;
}
.bs-bloque.h-375 {
  height: 375px;
}
.bs-bloque.h-380 {
  height: 380px;
}
.bs-bloque.h-385 {
  height: 385px;
}
.bs-bloque.h-390 {
  height: 390px;
}
.bs-bloque.h-395 {
  height: 395px;
}
.bs-bloque.h-400 {
  height: 400px;
}
.bs-bloque.h-405 {
  height: 405px;
}
.bs-bloque.h-410 {
  height: 410px;
}
.bs-bloque.h-415 {
  height: 415px;
}
.bs-bloque.h-420 {
  height: 420px;
}
.bs-bloque.h-425 {
  height: 425px;
}
.bs-bloque.h-430 {
  height: 430px;
}
.bs-bloque.h-435 {
  height: 435px;
}
.bs-bloque.h-440 {
  height: 440px;
}
.bs-bloque.h-445 {
  height: 445px;
}
.bs-bloque.h-450 {
  height: 450px;
}
.bs-bloque.h-455 {
  height: 455px;
}
.bs-bloque.h-460 {
  height: 460px;
}
.bs-bloque.h-465 {
  height: 465px;
}
.bs-bloque.h-470 {
  height: 470px;
}
.bs-bloque.h-475 {
  height: 475px;
}
.bs-bloque.h-480 {
  height: 480px;
}
.bs-bloque.h-485 {
  height: 485px;
}
.bs-bloque.h-490 {
  height: 490px;
}
.bs-bloque.h-495 {
  height: 495px;
}
.bs-bloque.h-500 {
  height: 500px;
}
.bs-bloque.h-505 {
  height: 505px;
}
.bs-bloque.h-510 {
  height: 510px;
}
.bs-bloque.h-515 {
  height: 515px;
}
.bs-bloque.h-520 {
  height: 520px;
}
.bs-bloque.h-525 {
  height: 525px;
}
.bs-bloque.h-530 {
  height: 530px;
}
.bs-bloque.h-535 {
  height: 535px;
}
.bs-bloque.h-540 {
  height: 540px;
}
.bs-bloque.h-545 {
  height: 545px;
}
.bs-bloque.h-550 {
  height: 550px;
}
.bs-bloque.h-555 {
  height: 555px;
}
.bs-bloque.h-560 {
  height: 560px;
}
.bs-bloque.h-565 {
  height: 565px;
}
.bs-bloque.h-570 {
  height: 570px;
}
.bs-bloque.h-575 {
  height: 575px;
}
.bs-bloque.h-580 {
  height: 580px;
}
.bs-bloque.h-585 {
  height: 585px;
}
.bs-bloque.h-590 {
  height: 590px;
}
.bs-bloque.h-595 {
  height: 595px;
}
.bs-bloque.h-600 {
  height: 600px;
}
.bs-bloque.h-605 {
  height: 605px;
}
.bs-bloque.h-610 {
  height: 610px;
}
.bs-bloque.h-615 {
  height: 615px;
}
.bs-bloque.h-620 {
  height: 620px;
}
.bs-bloque.h-625 {
  height: 625px;
}
.bs-bloque.h-630 {
  height: 630px;
}
.bs-bloque.h-635 {
  height: 635px;
}
.bs-bloque.h-640 {
  height: 640px;
}
.bs-bloque.h-645 {
  height: 645px;
}
.bs-bloque.h-650 {
  height: 650px;
}
.bs-bloque.h-655 {
  height: 655px;
}
.bs-bloque.h-660 {
  height: 660px;
}
.bs-bloque.h-665 {
  height: 665px;
}
.bs-bloque.h-670 {
  height: 670px;
}
.bs-bloque.h-675 {
  height: 675px;
}
.bs-bloque.h-680 {
  height: 680px;
}
.bs-bloque.h-685 {
  height: 685px;
}
.bs-bloque.h-690 {
  height: 690px;
}
.bs-bloque.h-695 {
  height: 695px;
}
.bs-bloque.h-700 {
  height: 700px;
}
.bs-bloque.h-705 {
  height: 705px;
}
.bs-bloque.h-710 {
  height: 710px;
}
.bs-bloque.h-715 {
  height: 715px;
}
.bs-bloque.h-720 {
  height: 720px;
}
.bs-bloque.h-725 {
  height: 725px;
}
.bs-bloque.h-730 {
  height: 730px;
}
.bs-bloque.h-735 {
  height: 735px;
}
.bs-bloque.h-740 {
  height: 740px;
}
.bs-bloque.h-745 {
  height: 745px;
}
.bs-bloque.h-750 {
  height: 750px;
}
.bs-bloque.h-755 {
  height: 755px;
}
.bs-bloque.h-760 {
  height: 760px;
}
.bs-bloque.h-765 {
  height: 765px;
}
.bs-bloque.h-770 {
  height: 770px;
}
.bs-bloque.h-775 {
  height: 775px;
}
.bs-bloque.h-780 {
  height: 780px;
}
.bs-bloque.h-785 {
  height: 785px;
}
.bs-bloque.h-790 {
  height: 790px;
}
.bs-bloque.h-795 {
  height: 795px;
}
.bs-bloque.h-800 {
  height: 800px;
}
.bs-bloque.h-805 {
  height: 805px;
}
.bs-bloque.h-810 {
  height: 810px;
}
.bs-bloque.h-815 {
  height: 815px;
}
.bs-bloque.h-820 {
  height: 820px;
}
.bs-bloque.h-825 {
  height: 825px;
}
.bs-bloque.h-830 {
  height: 830px;
}
.bs-bloque.h-835 {
  height: 835px;
}
.bs-bloque.h-840 {
  height: 840px;
}
.bs-bloque.h-845 {
  height: 845px;
}
.bs-bloque.h-850 {
  height: 850px;
}
.bs-bloque.h-855 {
  height: 855px;
}
.bs-bloque.h-860 {
  height: 860px;
}
.bs-bloque.h-865 {
  height: 865px;
}
.bs-bloque.h-870 {
  height: 870px;
}
.bs-bloque.h-875 {
  height: 875px;
}
.bs-bloque.h-880 {
  height: 880px;
}
.bs-bloque.h-885 {
  height: 885px;
}
.bs-bloque.h-890 {
  height: 890px;
}
.bs-bloque.h-895 {
  height: 895px;
}
.bs-bloque.h-900 {
  height: 900px;
}
.bs-bloque.h-905 {
  height: 905px;
}
.bs-bloque.h-910 {
  height: 910px;
}
.bs-bloque.h-915 {
  height: 915px;
}
.bs-bloque.h-920 {
  height: 920px;
}
.bs-bloque.h-925 {
  height: 925px;
}
.bs-bloque.h-930 {
  height: 930px;
}
.bs-bloque.h-935 {
  height: 935px;
}
.bs-bloque.h-940 {
  height: 940px;
}
.bs-bloque.h-945 {
  height: 945px;
}
.bs-bloque.h-950 {
  height: 950px;
}
.bs-bloque.h-955 {
  height: 955px;
}
.bs-bloque.h-960 {
  height: 960px;
}
.bs-bloque.h-965 {
  height: 965px;
}
.bs-bloque.h-970 {
  height: 970px;
}
.bs-bloque.h-975 {
  height: 975px;
}
.bs-bloque.h-980 {
  height: 980px;
}
.bs-bloque.h-985 {
  height: 985px;
}
.bs-bloque.h-990 {
  height: 990px;
}
.bs-bloque.h-995 {
  height: 995px;
}
.bs-bloque.h-1000 {
  height: 1000px;
}
.bs-bloque.min-h-0 {
  min-height: 0px;
}
.bs-bloque.m-h-0 {
  max-height: 0px;
}
.bs-bloque.min-h-5 {
  min-height: 5px;
}
.bs-bloque.m-h-5 {
  max-height: 5px;
}
.bs-bloque.min-h-10 {
  min-height: 10px;
}
.bs-bloque.m-h-10 {
  max-height: 10px;
}
.bs-bloque.min-h-15 {
  min-height: 15px;
}
.bs-bloque.m-h-15 {
  max-height: 15px;
}
.bs-bloque.min-h-20 {
  min-height: 20px;
}
.bs-bloque.m-h-20 {
  max-height: 20px;
}
.bs-bloque.min-h-25 {
  min-height: 25px;
}
.bs-bloque.m-h-25 {
  max-height: 25px;
}
.bs-bloque.min-h-30 {
  min-height: 30px;
}
.bs-bloque.m-h-30 {
  max-height: 30px;
}
.bs-bloque.min-h-35 {
  min-height: 35px;
}
.bs-bloque.m-h-35 {
  max-height: 35px;
}
.bs-bloque.min-h-40 {
  min-height: 40px;
}
.bs-bloque.m-h-40 {
  max-height: 40px;
}
.bs-bloque.min-h-45 {
  min-height: 45px;
}
.bs-bloque.m-h-45 {
  max-height: 45px;
}
.bs-bloque.min-h-50 {
  min-height: 50px;
}
.bs-bloque.m-h-50 {
  max-height: 50px;
}
.bs-bloque.min-h-55 {
  min-height: 55px;
}
.bs-bloque.m-h-55 {
  max-height: 55px;
}
.bs-bloque.min-h-60 {
  min-height: 60px;
}
.bs-bloque.m-h-60 {
  max-height: 60px;
}
.bs-bloque.min-h-65 {
  min-height: 65px;
}
.bs-bloque.m-h-65 {
  max-height: 65px;
}
.bs-bloque.min-h-70 {
  min-height: 70px;
}
.bs-bloque.m-h-70 {
  max-height: 70px;
}
.bs-bloque.min-h-75 {
  min-height: 75px;
}
.bs-bloque.m-h-75 {
  max-height: 75px;
}
.bs-bloque.min-h-80 {
  min-height: 80px;
}
.bs-bloque.m-h-80 {
  max-height: 80px;
}
.bs-bloque.min-h-85 {
  min-height: 85px;
}
.bs-bloque.m-h-85 {
  max-height: 85px;
}
.bs-bloque.min-h-90 {
  min-height: 90px;
}
.bs-bloque.m-h-90 {
  max-height: 90px;
}
.bs-bloque.min-h-95 {
  min-height: 95px;
}
.bs-bloque.m-h-95 {
  max-height: 95px;
}
.bs-bloque.min-h-100 {
  min-height: 100px;
}
.bs-bloque.m-h-100 {
  max-height: 100px;
}
.bs-bloque.min-h-105 {
  min-height: 105px;
}
.bs-bloque.m-h-105 {
  max-height: 105px;
}
.bs-bloque.min-h-110 {
  min-height: 110px;
}
.bs-bloque.m-h-110 {
  max-height: 110px;
}
.bs-bloque.min-h-115 {
  min-height: 115px;
}
.bs-bloque.m-h-115 {
  max-height: 115px;
}
.bs-bloque.min-h-120 {
  min-height: 120px;
}
.bs-bloque.m-h-120 {
  max-height: 120px;
}
.bs-bloque.min-h-125 {
  min-height: 125px;
}
.bs-bloque.m-h-125 {
  max-height: 125px;
}
.bs-bloque.min-h-130 {
  min-height: 130px;
}
.bs-bloque.m-h-130 {
  max-height: 130px;
}
.bs-bloque.min-h-135 {
  min-height: 135px;
}
.bs-bloque.m-h-135 {
  max-height: 135px;
}
.bs-bloque.min-h-140 {
  min-height: 140px;
}
.bs-bloque.m-h-140 {
  max-height: 140px;
}
.bs-bloque.min-h-145 {
  min-height: 145px;
}
.bs-bloque.m-h-145 {
  max-height: 145px;
}
.bs-bloque.min-h-150 {
  min-height: 150px;
}
.bs-bloque.m-h-150 {
  max-height: 150px;
}
.bs-bloque.min-h-155 {
  min-height: 155px;
}
.bs-bloque.m-h-155 {
  max-height: 155px;
}
.bs-bloque.min-h-160 {
  min-height: 160px;
}
.bs-bloque.m-h-160 {
  max-height: 160px;
}
.bs-bloque.min-h-165 {
  min-height: 165px;
}
.bs-bloque.m-h-165 {
  max-height: 165px;
}
.bs-bloque.min-h-170 {
  min-height: 170px;
}
.bs-bloque.m-h-170 {
  max-height: 170px;
}
.bs-bloque.min-h-175 {
  min-height: 175px;
}
.bs-bloque.m-h-175 {
  max-height: 175px;
}
.bs-bloque.min-h-180 {
  min-height: 180px;
}
.bs-bloque.m-h-180 {
  max-height: 180px;
}
.bs-bloque.min-h-185 {
  min-height: 185px;
}
.bs-bloque.m-h-185 {
  max-height: 185px;
}
.bs-bloque.min-h-190 {
  min-height: 190px;
}
.bs-bloque.m-h-190 {
  max-height: 190px;
}
.bs-bloque.min-h-195 {
  min-height: 195px;
}
.bs-bloque.m-h-195 {
  max-height: 195px;
}
.bs-bloque.min-h-200 {
  min-height: 200px;
}
.bs-bloque.m-h-200 {
  max-height: 200px;
}
.bs-bloque.min-h-205 {
  min-height: 205px;
}
.bs-bloque.m-h-205 {
  max-height: 205px;
}
.bs-bloque.min-h-210 {
  min-height: 210px;
}
.bs-bloque.m-h-210 {
  max-height: 210px;
}
.bs-bloque.min-h-215 {
  min-height: 215px;
}
.bs-bloque.m-h-215 {
  max-height: 215px;
}
.bs-bloque.min-h-220 {
  min-height: 220px;
}
.bs-bloque.m-h-220 {
  max-height: 220px;
}
.bs-bloque.min-h-225 {
  min-height: 225px;
}
.bs-bloque.m-h-225 {
  max-height: 225px;
}
.bs-bloque.min-h-230 {
  min-height: 230px;
}
.bs-bloque.m-h-230 {
  max-height: 230px;
}
.bs-bloque.min-h-235 {
  min-height: 235px;
}
.bs-bloque.m-h-235 {
  max-height: 235px;
}
.bs-bloque.min-h-240 {
  min-height: 240px;
}
.bs-bloque.m-h-240 {
  max-height: 240px;
}
.bs-bloque.min-h-245 {
  min-height: 245px;
}
.bs-bloque.m-h-245 {
  max-height: 245px;
}
.bs-bloque.min-h-250 {
  min-height: 250px;
}
.bs-bloque.m-h-250 {
  max-height: 250px;
}
.bs-bloque.min-h-255 {
  min-height: 255px;
}
.bs-bloque.m-h-255 {
  max-height: 255px;
}
.bs-bloque.min-h-260 {
  min-height: 260px;
}
.bs-bloque.m-h-260 {
  max-height: 260px;
}
.bs-bloque.min-h-265 {
  min-height: 265px;
}
.bs-bloque.m-h-265 {
  max-height: 265px;
}
.bs-bloque.min-h-270 {
  min-height: 270px;
}
.bs-bloque.m-h-270 {
  max-height: 270px;
}
.bs-bloque.min-h-275 {
  min-height: 275px;
}
.bs-bloque.m-h-275 {
  max-height: 275px;
}
.bs-bloque.min-h-280 {
  min-height: 280px;
}
.bs-bloque.m-h-280 {
  max-height: 280px;
}
.bs-bloque.min-h-285 {
  min-height: 285px;
}
.bs-bloque.m-h-285 {
  max-height: 285px;
}
.bs-bloque.min-h-290 {
  min-height: 290px;
}
.bs-bloque.m-h-290 {
  max-height: 290px;
}
.bs-bloque.min-h-295 {
  min-height: 295px;
}
.bs-bloque.m-h-295 {
  max-height: 295px;
}
.bs-bloque.min-h-300 {
  min-height: 300px;
}
.bs-bloque.m-h-300 {
  max-height: 300px;
}
.bs-bloque.min-h-305 {
  min-height: 305px;
}
.bs-bloque.m-h-305 {
  max-height: 305px;
}
.bs-bloque.min-h-310 {
  min-height: 310px;
}
.bs-bloque.m-h-310 {
  max-height: 310px;
}
.bs-bloque.min-h-315 {
  min-height: 315px;
}
.bs-bloque.m-h-315 {
  max-height: 315px;
}
.bs-bloque.min-h-320 {
  min-height: 320px;
}
.bs-bloque.m-h-320 {
  max-height: 320px;
}
.bs-bloque.min-h-325 {
  min-height: 325px;
}
.bs-bloque.m-h-325 {
  max-height: 325px;
}
.bs-bloque.min-h-330 {
  min-height: 330px;
}
.bs-bloque.m-h-330 {
  max-height: 330px;
}
.bs-bloque.min-h-335 {
  min-height: 335px;
}
.bs-bloque.m-h-335 {
  max-height: 335px;
}
.bs-bloque.min-h-340 {
  min-height: 340px;
}
.bs-bloque.m-h-340 {
  max-height: 340px;
}
.bs-bloque.min-h-345 {
  min-height: 345px;
}
.bs-bloque.m-h-345 {
  max-height: 345px;
}
.bs-bloque.min-h-350 {
  min-height: 350px;
}
.bs-bloque.m-h-350 {
  max-height: 350px;
}
.bs-bloque.min-h-355 {
  min-height: 355px;
}
.bs-bloque.m-h-355 {
  max-height: 355px;
}
.bs-bloque.min-h-360 {
  min-height: 360px;
}
.bs-bloque.m-h-360 {
  max-height: 360px;
}
.bs-bloque.min-h-365 {
  min-height: 365px;
}
.bs-bloque.m-h-365 {
  max-height: 365px;
}
.bs-bloque.min-h-370 {
  min-height: 370px;
}
.bs-bloque.m-h-370 {
  max-height: 370px;
}
.bs-bloque.min-h-375 {
  min-height: 375px;
}
.bs-bloque.m-h-375 {
  max-height: 375px;
}
.bs-bloque.min-h-380 {
  min-height: 380px;
}
.bs-bloque.m-h-380 {
  max-height: 380px;
}
.bs-bloque.min-h-385 {
  min-height: 385px;
}
.bs-bloque.m-h-385 {
  max-height: 385px;
}
.bs-bloque.min-h-390 {
  min-height: 390px;
}
.bs-bloque.m-h-390 {
  max-height: 390px;
}
.bs-bloque.min-h-395 {
  min-height: 395px;
}
.bs-bloque.m-h-395 {
  max-height: 395px;
}
.bs-bloque.min-h-400 {
  min-height: 400px;
}
.bs-bloque.m-h-400 {
  max-height: 400px;
}
.bs-bloque.min-h-405 {
  min-height: 405px;
}
.bs-bloque.m-h-405 {
  max-height: 405px;
}
.bs-bloque.min-h-410 {
  min-height: 410px;
}
.bs-bloque.m-h-410 {
  max-height: 410px;
}
.bs-bloque.min-h-415 {
  min-height: 415px;
}
.bs-bloque.m-h-415 {
  max-height: 415px;
}
.bs-bloque.min-h-420 {
  min-height: 420px;
}
.bs-bloque.m-h-420 {
  max-height: 420px;
}
.bs-bloque.min-h-425 {
  min-height: 425px;
}
.bs-bloque.m-h-425 {
  max-height: 425px;
}
.bs-bloque.min-h-430 {
  min-height: 430px;
}
.bs-bloque.m-h-430 {
  max-height: 430px;
}
.bs-bloque.min-h-435 {
  min-height: 435px;
}
.bs-bloque.m-h-435 {
  max-height: 435px;
}
.bs-bloque.min-h-440 {
  min-height: 440px;
}
.bs-bloque.m-h-440 {
  max-height: 440px;
}
.bs-bloque.min-h-445 {
  min-height: 445px;
}
.bs-bloque.m-h-445 {
  max-height: 445px;
}
.bs-bloque.min-h-450 {
  min-height: 450px;
}
.bs-bloque.m-h-450 {
  max-height: 450px;
}
.bs-bloque.min-h-455 {
  min-height: 455px;
}
.bs-bloque.m-h-455 {
  max-height: 455px;
}
.bs-bloque.min-h-460 {
  min-height: 460px;
}
.bs-bloque.m-h-460 {
  max-height: 460px;
}
.bs-bloque.min-h-465 {
  min-height: 465px;
}
.bs-bloque.m-h-465 {
  max-height: 465px;
}
.bs-bloque.min-h-470 {
  min-height: 470px;
}
.bs-bloque.m-h-470 {
  max-height: 470px;
}
.bs-bloque.min-h-475 {
  min-height: 475px;
}
.bs-bloque.m-h-475 {
  max-height: 475px;
}
.bs-bloque.min-h-480 {
  min-height: 480px;
}
.bs-bloque.m-h-480 {
  max-height: 480px;
}
.bs-bloque.min-h-485 {
  min-height: 485px;
}
.bs-bloque.m-h-485 {
  max-height: 485px;
}
.bs-bloque.min-h-490 {
  min-height: 490px;
}
.bs-bloque.m-h-490 {
  max-height: 490px;
}
.bs-bloque.min-h-495 {
  min-height: 495px;
}
.bs-bloque.m-h-495 {
  max-height: 495px;
}
.bs-bloque.min-h-500 {
  min-height: 500px;
}
.bs-bloque.m-h-500 {
  max-height: 500px;
}
.bs-bloque.min-h-505 {
  min-height: 505px;
}
.bs-bloque.m-h-505 {
  max-height: 505px;
}
.bs-bloque.min-h-510 {
  min-height: 510px;
}
.bs-bloque.m-h-510 {
  max-height: 510px;
}
.bs-bloque.min-h-515 {
  min-height: 515px;
}
.bs-bloque.m-h-515 {
  max-height: 515px;
}
.bs-bloque.min-h-520 {
  min-height: 520px;
}
.bs-bloque.m-h-520 {
  max-height: 520px;
}
.bs-bloque.min-h-525 {
  min-height: 525px;
}
.bs-bloque.m-h-525 {
  max-height: 525px;
}
.bs-bloque.min-h-530 {
  min-height: 530px;
}
.bs-bloque.m-h-530 {
  max-height: 530px;
}
.bs-bloque.min-h-535 {
  min-height: 535px;
}
.bs-bloque.m-h-535 {
  max-height: 535px;
}
.bs-bloque.min-h-540 {
  min-height: 540px;
}
.bs-bloque.m-h-540 {
  max-height: 540px;
}
.bs-bloque.min-h-545 {
  min-height: 545px;
}
.bs-bloque.m-h-545 {
  max-height: 545px;
}
.bs-bloque.min-h-550 {
  min-height: 550px;
}
.bs-bloque.m-h-550 {
  max-height: 550px;
}
.bs-bloque.min-h-555 {
  min-height: 555px;
}
.bs-bloque.m-h-555 {
  max-height: 555px;
}
.bs-bloque.min-h-560 {
  min-height: 560px;
}
.bs-bloque.m-h-560 {
  max-height: 560px;
}
.bs-bloque.min-h-565 {
  min-height: 565px;
}
.bs-bloque.m-h-565 {
  max-height: 565px;
}
.bs-bloque.min-h-570 {
  min-height: 570px;
}
.bs-bloque.m-h-570 {
  max-height: 570px;
}
.bs-bloque.min-h-575 {
  min-height: 575px;
}
.bs-bloque.m-h-575 {
  max-height: 575px;
}
.bs-bloque.min-h-580 {
  min-height: 580px;
}
.bs-bloque.m-h-580 {
  max-height: 580px;
}
.bs-bloque.min-h-585 {
  min-height: 585px;
}
.bs-bloque.m-h-585 {
  max-height: 585px;
}
.bs-bloque.min-h-590 {
  min-height: 590px;
}
.bs-bloque.m-h-590 {
  max-height: 590px;
}
.bs-bloque.min-h-595 {
  min-height: 595px;
}
.bs-bloque.m-h-595 {
  max-height: 595px;
}
.bs-bloque.min-h-600 {
  min-height: 600px;
}
.bs-bloque.m-h-600 {
  max-height: 600px;
}
.bs-bloque.min-h-605 {
  min-height: 605px;
}
.bs-bloque.m-h-605 {
  max-height: 605px;
}
.bs-bloque.min-h-610 {
  min-height: 610px;
}
.bs-bloque.m-h-610 {
  max-height: 610px;
}
.bs-bloque.min-h-615 {
  min-height: 615px;
}
.bs-bloque.m-h-615 {
  max-height: 615px;
}
.bs-bloque.min-h-620 {
  min-height: 620px;
}
.bs-bloque.m-h-620 {
  max-height: 620px;
}
.bs-bloque.min-h-625 {
  min-height: 625px;
}
.bs-bloque.m-h-625 {
  max-height: 625px;
}
.bs-bloque.min-h-630 {
  min-height: 630px;
}
.bs-bloque.m-h-630 {
  max-height: 630px;
}
.bs-bloque.min-h-635 {
  min-height: 635px;
}
.bs-bloque.m-h-635 {
  max-height: 635px;
}
.bs-bloque.min-h-640 {
  min-height: 640px;
}
.bs-bloque.m-h-640 {
  max-height: 640px;
}
.bs-bloque.min-h-645 {
  min-height: 645px;
}
.bs-bloque.m-h-645 {
  max-height: 645px;
}
.bs-bloque.min-h-650 {
  min-height: 650px;
}
.bs-bloque.m-h-650 {
  max-height: 650px;
}
.bs-bloque.min-h-655 {
  min-height: 655px;
}
.bs-bloque.m-h-655 {
  max-height: 655px;
}
.bs-bloque.min-h-660 {
  min-height: 660px;
}
.bs-bloque.m-h-660 {
  max-height: 660px;
}
.bs-bloque.min-h-665 {
  min-height: 665px;
}
.bs-bloque.m-h-665 {
  max-height: 665px;
}
.bs-bloque.min-h-670 {
  min-height: 670px;
}
.bs-bloque.m-h-670 {
  max-height: 670px;
}
.bs-bloque.min-h-675 {
  min-height: 675px;
}
.bs-bloque.m-h-675 {
  max-height: 675px;
}
.bs-bloque.min-h-680 {
  min-height: 680px;
}
.bs-bloque.m-h-680 {
  max-height: 680px;
}
.bs-bloque.min-h-685 {
  min-height: 685px;
}
.bs-bloque.m-h-685 {
  max-height: 685px;
}
.bs-bloque.min-h-690 {
  min-height: 690px;
}
.bs-bloque.m-h-690 {
  max-height: 690px;
}
.bs-bloque.min-h-695 {
  min-height: 695px;
}
.bs-bloque.m-h-695 {
  max-height: 695px;
}
.bs-bloque.min-h-700 {
  min-height: 700px;
}
.bs-bloque.m-h-700 {
  max-height: 700px;
}
.bs-bloque.min-h-705 {
  min-height: 705px;
}
.bs-bloque.m-h-705 {
  max-height: 705px;
}
.bs-bloque.min-h-710 {
  min-height: 710px;
}
.bs-bloque.m-h-710 {
  max-height: 710px;
}
.bs-bloque.min-h-715 {
  min-height: 715px;
}
.bs-bloque.m-h-715 {
  max-height: 715px;
}
.bs-bloque.min-h-720 {
  min-height: 720px;
}
.bs-bloque.m-h-720 {
  max-height: 720px;
}
.bs-bloque.min-h-725 {
  min-height: 725px;
}
.bs-bloque.m-h-725 {
  max-height: 725px;
}
.bs-bloque.min-h-730 {
  min-height: 730px;
}
.bs-bloque.m-h-730 {
  max-height: 730px;
}
.bs-bloque.min-h-735 {
  min-height: 735px;
}
.bs-bloque.m-h-735 {
  max-height: 735px;
}
.bs-bloque.min-h-740 {
  min-height: 740px;
}
.bs-bloque.m-h-740 {
  max-height: 740px;
}
.bs-bloque.min-h-745 {
  min-height: 745px;
}
.bs-bloque.m-h-745 {
  max-height: 745px;
}
.bs-bloque.min-h-750 {
  min-height: 750px;
}
.bs-bloque.m-h-750 {
  max-height: 750px;
}
.bs-bloque.min-h-755 {
  min-height: 755px;
}
.bs-bloque.m-h-755 {
  max-height: 755px;
}
.bs-bloque.min-h-760 {
  min-height: 760px;
}
.bs-bloque.m-h-760 {
  max-height: 760px;
}
.bs-bloque.min-h-765 {
  min-height: 765px;
}
.bs-bloque.m-h-765 {
  max-height: 765px;
}
.bs-bloque.min-h-770 {
  min-height: 770px;
}
.bs-bloque.m-h-770 {
  max-height: 770px;
}
.bs-bloque.min-h-775 {
  min-height: 775px;
}
.bs-bloque.m-h-775 {
  max-height: 775px;
}
.bs-bloque.min-h-780 {
  min-height: 780px;
}
.bs-bloque.m-h-780 {
  max-height: 780px;
}
.bs-bloque.min-h-785 {
  min-height: 785px;
}
.bs-bloque.m-h-785 {
  max-height: 785px;
}
.bs-bloque.min-h-790 {
  min-height: 790px;
}
.bs-bloque.m-h-790 {
  max-height: 790px;
}
.bs-bloque.min-h-795 {
  min-height: 795px;
}
.bs-bloque.m-h-795 {
  max-height: 795px;
}
.bs-bloque.min-h-800 {
  min-height: 800px;
}
.bs-bloque.m-h-800 {
  max-height: 800px;
}
.bs-bloque.min-h-805 {
  min-height: 805px;
}
.bs-bloque.m-h-805 {
  max-height: 805px;
}
.bs-bloque.min-h-810 {
  min-height: 810px;
}
.bs-bloque.m-h-810 {
  max-height: 810px;
}
.bs-bloque.min-h-815 {
  min-height: 815px;
}
.bs-bloque.m-h-815 {
  max-height: 815px;
}
.bs-bloque.min-h-820 {
  min-height: 820px;
}
.bs-bloque.m-h-820 {
  max-height: 820px;
}
.bs-bloque.min-h-825 {
  min-height: 825px;
}
.bs-bloque.m-h-825 {
  max-height: 825px;
}
.bs-bloque.min-h-830 {
  min-height: 830px;
}
.bs-bloque.m-h-830 {
  max-height: 830px;
}
.bs-bloque.min-h-835 {
  min-height: 835px;
}
.bs-bloque.m-h-835 {
  max-height: 835px;
}
.bs-bloque.min-h-840 {
  min-height: 840px;
}
.bs-bloque.m-h-840 {
  max-height: 840px;
}
.bs-bloque.min-h-845 {
  min-height: 845px;
}
.bs-bloque.m-h-845 {
  max-height: 845px;
}
.bs-bloque.min-h-850 {
  min-height: 850px;
}
.bs-bloque.m-h-850 {
  max-height: 850px;
}
.bs-bloque.min-h-855 {
  min-height: 855px;
}
.bs-bloque.m-h-855 {
  max-height: 855px;
}
.bs-bloque.min-h-860 {
  min-height: 860px;
}
.bs-bloque.m-h-860 {
  max-height: 860px;
}
.bs-bloque.min-h-865 {
  min-height: 865px;
}
.bs-bloque.m-h-865 {
  max-height: 865px;
}
.bs-bloque.min-h-870 {
  min-height: 870px;
}
.bs-bloque.m-h-870 {
  max-height: 870px;
}
.bs-bloque.min-h-875 {
  min-height: 875px;
}
.bs-bloque.m-h-875 {
  max-height: 875px;
}
.bs-bloque.min-h-880 {
  min-height: 880px;
}
.bs-bloque.m-h-880 {
  max-height: 880px;
}
.bs-bloque.min-h-885 {
  min-height: 885px;
}
.bs-bloque.m-h-885 {
  max-height: 885px;
}
.bs-bloque.min-h-890 {
  min-height: 890px;
}
.bs-bloque.m-h-890 {
  max-height: 890px;
}
.bs-bloque.min-h-895 {
  min-height: 895px;
}
.bs-bloque.m-h-895 {
  max-height: 895px;
}
.bs-bloque.min-h-900 {
  min-height: 900px;
}
.bs-bloque.m-h-900 {
  max-height: 900px;
}
.bs-bloque.min-h-905 {
  min-height: 905px;
}
.bs-bloque.m-h-905 {
  max-height: 905px;
}
.bs-bloque.min-h-910 {
  min-height: 910px;
}
.bs-bloque.m-h-910 {
  max-height: 910px;
}
.bs-bloque.min-h-915 {
  min-height: 915px;
}
.bs-bloque.m-h-915 {
  max-height: 915px;
}
.bs-bloque.min-h-920 {
  min-height: 920px;
}
.bs-bloque.m-h-920 {
  max-height: 920px;
}
.bs-bloque.min-h-925 {
  min-height: 925px;
}
.bs-bloque.m-h-925 {
  max-height: 925px;
}
.bs-bloque.min-h-930 {
  min-height: 930px;
}
.bs-bloque.m-h-930 {
  max-height: 930px;
}
.bs-bloque.min-h-935 {
  min-height: 935px;
}
.bs-bloque.m-h-935 {
  max-height: 935px;
}
.bs-bloque.min-h-940 {
  min-height: 940px;
}
.bs-bloque.m-h-940 {
  max-height: 940px;
}
.bs-bloque.min-h-945 {
  min-height: 945px;
}
.bs-bloque.m-h-945 {
  max-height: 945px;
}
.bs-bloque.min-h-950 {
  min-height: 950px;
}
.bs-bloque.m-h-950 {
  max-height: 950px;
}
.bs-bloque.min-h-955 {
  min-height: 955px;
}
.bs-bloque.m-h-955 {
  max-height: 955px;
}
.bs-bloque.min-h-960 {
  min-height: 960px;
}
.bs-bloque.m-h-960 {
  max-height: 960px;
}
.bs-bloque.min-h-965 {
  min-height: 965px;
}
.bs-bloque.m-h-965 {
  max-height: 965px;
}
.bs-bloque.min-h-970 {
  min-height: 970px;
}
.bs-bloque.m-h-970 {
  max-height: 970px;
}
.bs-bloque.min-h-975 {
  min-height: 975px;
}
.bs-bloque.m-h-975 {
  max-height: 975px;
}
.bs-bloque.min-h-980 {
  min-height: 980px;
}
.bs-bloque.m-h-980 {
  max-height: 980px;
}
.bs-bloque.min-h-985 {
  min-height: 985px;
}
.bs-bloque.m-h-985 {
  max-height: 985px;
}
.bs-bloque.min-h-990 {
  min-height: 990px;
}
.bs-bloque.m-h-990 {
  max-height: 990px;
}
.bs-bloque.min-h-995 {
  min-height: 995px;
}
.bs-bloque.m-h-995 {
  max-height: 995px;
}
.bs-bloque.min-h-1000 {
  min-height: 1000px;
}
.bs-bloque.m-h-1000 {
  max-height: 1000px;
}
.bs-bloque.botonera {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-bloque.botonera:not(.extremos) {
  flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
}
@media only screen and (min-width : 600px) {
  .bs-bloque.botonera:not(.extremos) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
  }
}
@media only screen and (min-width : 600px) {
  .bs-bloque.botonera:not(.extremos).right {
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
  }
}
@media only screen and (min-width : 600px) {
  .bs-bloque.botonera:not(.extremos).center {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
  }
}
.bs-bloque.botonera.extremos .bs-btn-primary,
.bs-bloque.botonera.extremos .bs-btn-secondary,
.bs-bloque.botonera.extremos .bs-btn-back,
.bs-bloque.botonera.extremos .bs-btn-terciary {
  width: auto;
}
.bs-bloque.important section {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
.bs-bloque.important section figure {
  width: 16px;
  margin-right: 16px;
  margin-top: 5px;
}
.bs-bloque.important section figure img {
  width: 16px;
  height: auto;
}
.bs-bloque.important section p {
  flex: 1;
}
.bs-bloque > img {
  width: 100%;
  height: auto;
}
.bs-bloque.background {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  padding: 16px;
  width: calc(100% - 32px);
}
.bs-bloque.background.gris {
  background-color: var(--bs--color-grises-gris-10);
}
.bs-bloque.background.verde {
  background-color: var(--bs--color-principal-verde);
}
.bs-bloque.background.azul {
  background-color: var(--bs--color-principal-azul);
}

.bs-chip {
  margin: 0 8px 8px 0;
  background-color: var(--bs--color-principal-negro);
  color: var(--bs--color-principal-blanco);
  display: inline-block;
  padding: 4px 8px 3px;
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "overpassregular", sans-serif;
}
.bs-chip.mini {
  font-size: 1.2rem;
  margin: 4px 0;
  padding: 2px 8px;
  font-family: "overpassregular", sans-serif;
}
.bs-chip.gris-40 {
  background-color: var(--bs--color-grises-gris-40);
}
.bs-chip.gris-50 {
  background-color: var(--bs--color-grises-gris-50);
}
.bs-chip.gris-80 {
  background-color: var(--bs--color-grises-gris-80);
}
.bs-chip.rojo {
  background-color: var(--bs--color-secundario-rojo);
}
.bs-chip.verde {
  background-color: var(--bs--color-principal-verde);
}
.bs-chip.azul {
  background-color: var(--bs--color-principal-azul);
}
.bs-chip.amarillo {
  background-color: var(--bs--color-secundario-amarillo);
}

@media only screen and (max-width : 500px) {
  .no-mobile {
    display: none;
  }
}

@media only screen and (max-width : 810px) {
  .no-tablet {
    display: none;
  }
}

.bs-pasos-indice {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.bs-pasos-indice p-progressbar {
  flex: 0 0 100%;
}
.bs-pasos-indice .index {
  height: 20px;
  width: 20px;
  display: grid;
  place-items: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: var(--bs--color-grises-gris-50);
  border: 2px solid var(--bs--color-grises-gris-50);
  font-size: 1.3rem;
  font-family: "overpassbold", sans-serif;
  text-align: center;
  padding: 0;
  margin: 0 8px 0 0;
}
.bs-pasos-indice .pasos {
  color: var(--bs--color-grises-gris-80);
  font-size: 1.3rem;
  text-transform: uppercase;
  font-family: "overpassregular", sans-serif;
  text-align: right;
}
.bs-pasos-indice h2.bs-contenido {
  font-family: "overpassbold", sans-serif;
  flex: 1;
  color: var(--bs--color-grises-gris-50);
}
.bs-pasos-indice.active .index {
  color: var(--bs--color-principal-azul);
  border: 2px solid var(--bs--color-principal-azul);
}
.bs-pasos-indice.active h2 {
  color: var(--bs--color-principal-negro);
}
.bs-pasos-indice.active h2 span {
  color: var(--bs--color-principal-azul);
}
.bs-pasos-indice.completed .index {
  color: rgba(0, 0, 0, 0);
  border: 2px solid var(--bs--color-principal-azul);
  background: url(~src/assets/img/svg/check-button-white.svg) var(--bs--color-principal-azul) center center no-repeat;
  background-size: auto 12px;
}
.bs-pasos-indice.completed h2 {
  color: var(--bs--color-principal-azul);
}

.bs-titular-seccional {
  width: 100%;
  min-height: 40px;
  display: block;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  border-bottom: 1px solid var(--bs--color-grises-gris-80);
}
.bs-titular-seccional figcaption {
  flex: 1;
}
.bs-titular-seccional figcaption h3 {
  font-size: 1.4rem;
  line-height: 1;
}
.bs-titular-seccional figcaption h3 span {
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-grises-gris-80);
  display: block;
}
@media only screen and (min-width : 600px) {
  .bs-titular-seccional figcaption h3 span {
    display: inline;
  }
}
.bs-titular-seccional figure {
  height: 40px;
  display: inline-flex;
  margin: 4px 10px 4px 0;
  padding: 0;
  flex: 0 0 40px;
}
.bs-titular-seccional figure img {
  width: 100%;
  height: auto;
}

.bs-simple-data {
  width: 100%;
  display: block;
  margin-bottom: 16px;
}
.bs-simple-data p,
.bs-simple-data h3 {
  color: var(--bs--color-principal-negro);
  line-height: normal;
}
.bs-simple-data h3 {
  font-size: 1.5rem;
  font-family: "overpassbold", sans-serif;
}
.bs-simple-data h4 {
  font-size: 1.4rem;
  font-family: "overpassregular", sans-serif;
}
.bs-simple-data p {
  font-size: 1.5rem;
  font-family: "overpassregular", sans-serif;
}

.bs-simple-data-tabla {
  border: 1px solid var(--bs--color-grises-gris-40);
}
.bs-simple-data-tabla .modulo {
  width: calc(100% - 20px);
  min-height: 48px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  padding: 10px;
  border-bottom: 1px solid var(--bs--color-grises-gris-40);
}
.bs-simple-data-tabla .modulo:last-child {
  border-bottom: none;
}
.bs-simple-data-tabla .modulo figure {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
.bs-simple-data-tabla .modulo p {
  flex: 1;
  line-height: 1.8rem;
}

.bs-desplegable-resumen {
  width: 100%;
  display: block;
  margin: 4px 0 0;
  /**/
}
.bs-desplegable-resumen .titular {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  padding: 4px 0;
  background: var(--bs--color-secundario-azul-10);
  position: relative;
}
.bs-desplegable-resumen .titular::before {
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 29px);
  left: 19px;
  bottom: 0;
  display: block;
  background: var(--bs--color-principal-azul);
  z-index: 1;
}
@media only screen and (max-width : 500px) {
  .bs-desplegable-resumen .titular::before {
    left: 10px;
  }
}
@media only screen and (max-width : 500px) {
  .bs-desplegable-resumen .titular {
    background: none;
  }
}
.bs-desplegable-resumen .titular figure {
  z-index: 10;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
  height: 40px;
  width: 40px;
}
.bs-desplegable-resumen .titular figure img {
  margin-top: 8px;
  height: 22px;
  width: auto;
  z-index: 10;
}
@media only screen and (max-width : 500px) {
  .bs-desplegable-resumen .titular figure {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.bs-desplegable-resumen .titular figcaption h2 {
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: var(--bs--color-principal-azul);
  font-family: "overpassbold", sans-serif;
  text-transform: uppercase;
}
.bs-desplegable-resumen .titular figcaption h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}
.bs-desplegable-resumen .titular figcaption h3 span {
  font-family: "overpassregular", sans-serif;
}
.bs-desplegable-resumen .deploy .data {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
  padding-right: 8px;
  position: relative;
}
@media only screen and (max-width : 500px) {
  .bs-desplegable-resumen .deploy .data {
    padding-right: 0;
  }
}
.bs-desplegable-resumen .deploy .data::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  left: 19px;
  top: 0;
  display: block;
  background: var(--bs--color-principal-azul);
}
@media only screen and (max-width : 500px) {
  .bs-desplegable-resumen .deploy .data::before {
    left: 10px;
  }
}
.bs-desplegable-resumen .deploy .data span {
  width: 36px;
  height: 40px;
  position: relative;
}
.bs-desplegable-resumen .deploy .data span::after {
  display: block;
  height: 18px;
  width: 18px;
  border: 2px solid var(--bs--color-principal-azul);
  content: "";
  position: absolute;
  left: 9px;
  top: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--bs--color-principal-blanco) center center no-repeat;
}
@media only screen and (max-width : 500px) {
  .bs-desplegable-resumen .deploy .data span::after {
    left: 0;
  }
}
.bs-desplegable-resumen .deploy .data .data-item {
  flex: 1;
  min-height: 4rem;
  border-bottom: 2px solid var(--bs--color-grises-gris-20);
}
.bs-desplegable-resumen .deploy .data.completed span::after {
  background-image: url(~src/assets/img/svg/check-button-blue.svg);
}
.bs-desplegable-resumen .deploy .data.completed button.data-interaction {
  line-height: 4rem;
  color: var(--bs--color-principal-negro);
  text-transform: uppercase;
  font-size: 1.3rem;
  font-family: "overpassbold", sans-serif;
  padding: 0 0 0 4px;
  width: 100%;
  display: inline-block;
  text-align: left;
  background: url(~src/assets/img/svg/arrow-button-down-green.svg) calc(100% - 10px) center no-repeat;
}
.bs-desplegable-resumen .deploy .data.completed button.data-interaction.active {
  background-image: url(~src/assets/img/svg/arrow-button-up-green.svg);
  color: var(--bs--color-principal-azul);
}
.bs-desplegable-resumen .deploy .data.completed button.data-interaction.active:hover {
  color: var(--bs--color-principal-azul-hover);
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
.bs-desplegable-resumen .deploy .data.completed button.data-interaction:hover {
  color: var(--bs--color-grises-gris-50);
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.bs-desplegable-resumen .deploy .data.completed .data-item .extra {
  padding-left: 16px;
}
.bs-desplegable-resumen .deploy .data.current::before {
  height: 50%;
}
.bs-desplegable-resumen .deploy .data.current .data-item {
  background: var(--bs--color-secundario-azul-10);
  padding: 0 4px;
}
.bs-desplegable-resumen .deploy .data.current .data-item h2 {
  color: var(--bs--color-principal-azul);
  text-transform: uppercase;
  font-size: 1.3rem;
  font-family: "overpassbold", sans-serif;
  line-height: 4rem;
}

.bs-paginador {
  margin-top: 20px;
  min-height: 40px;
  width: 100%;
  display: block;
  flex: 0 0 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  position: relative;
}
.bs-paginador .p-paginator {
  flex: 0 0 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  align-items: center;
}
.bs-paginador .custom-nav {
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-paginador .custom-nav section {
  flex: 1;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-paginador .custom-nav .elipsis {
  font-size: 2rem;
  min-height: 28px;
  margin: 0 4px;
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassbold", sans-serif;
  text-align: center;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
  -webkit-align-content: flex-end;
  -moz-flex-direction: flex-end;
  -ms-flex-direction: flex-end;
  line-height: 2.4rem;
  margin: 0 2px;
}
@media only screen and (min-width : 600px) {
  .bs-paginador .custom-nav .elipsis {
    min-height: 32px;
  }
}
.bs-paginador .custom-nav button {
  font-size: 1.5rem;
  min-height: 32px;
  margin-right: 0px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}
@media only screen and (min-width : 600px) {
  .bs-paginador .custom-nav button {
    margin: 0 2px;
  }
}
.bs-paginador .custom-nav button.disabled {
  background: none;
  color: var(--bs--color-grises-gris-50);
  cursor: not-allowed;
  opacity: 0.3;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.bs-paginador .custom-nav button.number {
  padding: 0 4px;
  min-width: 24px;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-grises-gris-80);
}
@media only screen and (min-width : 600px) {
  .bs-paginador .custom-nav button.number {
    min-width: 32px;
  }
}
.bs-paginador .custom-nav button.number.activo {
  font-family: "overpassbold", sans-serif;
  color: #ffffff;
  background-color: var(--bs--color-principal-verde);
}
.bs-paginador .custom-nav button.number:hover {
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-verde);
}
.bs-paginador .custom-nav button.number:hover.activo {
  color: #ffffff;
  background-color: var(--bs--color-principal-verde-hover);
}
.bs-paginador .custom-nav button.nav {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 20px;
  width: 24px;
}
@media only screen and (min-width : 600px) {
  .bs-paginador .custom-nav button.nav {
    width: 48px;
  }
}
.bs-paginador .custom-nav button.nav.nav-first {
  background-image: url(~src/assets/img/svg/icon-paginator-first.svg);
}
.bs-paginador .custom-nav button.nav.nav-last {
  background-image: url(~src/assets/img/svg/icon-paginator-last.svg);
}
.bs-paginador .custom-nav button.nav.nav-next {
  background-image: url(~src/assets/img/svg/icon-paginator-next.svg);
}
.bs-paginador .custom-nav button.nav.nav-back {
  background-image: url(~src/assets/img/svg/icon-paginator-prev.svg);
}
.bs-paginador .custom-nav button.nav:hover {
  background-color: var(--bs--color-grises-gris-10) !important;
}
.bs-paginador .custom-nav button.nav:hover.disabled {
  background-color: var(--bs--color-principal-blanco) !important;
}

.bs-forms {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  position: relative;
}
.bs-forms .mix {
  position: relative;
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
.bs-forms .mix input.p-inputtext {
  flex: 1;
  padding-right: 40px;
}
.bs-forms .mix .btn {
  margin-bottom: 6px;
}
.bs-forms .mix p-checkbox {
  flex: 1;
}
.bs-forms p-checkbox {
  width: 100%;
}
.bs-forms.bs-archivos {
  width: 100%;
}
.bs-forms.bs-archivos .bs-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bs-forms.bs-archivos .modulos-archivos-compactos {
  margin-top: 12px;
}
.bs-forms.bs-archivos .modulos-archivos-compactos .modulo {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  height: 48px;
  width: 56px;
  margin-right: 12px;
  margin-bottom: 12px;
  background-color: var(--bs--color-grises-gris-10);
  display: inline-block;
}
.bs-forms.bs-archivos .modulos-archivos-compactos .modulo p.caption {
  height: 16px;
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
}
.bs-forms.bs-archivos .modulos-archivos-compactos .modulo a.agregar {
  cursor: pointer;
  background: url(~src/assets/img/svg/icon-modulo-imagen-add.svg) center center no-repeat;
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bs-forms.bs-archivos .modulos-archivos-compactos .modulo a.agregar:hover {
  opacity: 0.5;
}
.bs-forms.bs-archivos .modulos-archivos-compactos .modulo a.detalle {
  height: 100%;
  width: 100%;
  display: block;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(~src/assets/img/svg/icon-modulo-archivo-detalle.svg) center center no-repeat;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
.bs-forms.bs-archivos .modulos-archivos-compactos .modulo a.detalle:hover {
  background-color: var(--bs--color-grises-gris-50);
}
.bs-forms.bs-archivos .modulos-archivos-compactos .modulo a.detalle:hover::after {
  filter: brightness(118%);
  -webkit-filter: brightness(118%);
}
.bs-forms.bs-archivos .modulos-archivos-compactos .modulo a.detalle::after {
  content: "";
  display: block;
  height: 23px;
  width: 23px;
  background: url(~src/assets/img/svg/icon-modulo-archivo-ver.svg) center center no-repeat;
  position: absolute;
  right: -8px;
  bottom: -8px;
}
.bs-forms.bs-archivos .modulos-archivos-compactos .modulo a.borrar {
  position: absolute;
  cursor: pointer;
  right: -8px;
  top: -8px;
  display: block;
  height: 23px;
  width: 23px;
  background: url(~src/assets/img/svg/icon-modulo-archivo-quitar.svg) center center no-repeat;
}
.bs-forms.bs-archivos .modulos-archivos-compactos .modulo a.borrar:hover {
  filter: brightness(90%);
  -webkit-filter: brightness(90%);
}
.bs-forms.bs-archivos .modulos-archivos-compactos .modulo.agregado {
  margin-right: 20px;
  background-color: var(--bs--color-grises-gris-80);
}
.bs-forms.bs-archivos .modulos-archivos {
  width: 100%;
  margin: 8px 0;
  display: -ms-grid;
  display: -webkit-inline-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2px;
  row-gap: 2px;
  min-height: none;
}
@media only screen and (min-width : 600px) {
  .bs-forms.bs-archivos .modulos-archivos {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    -webkit-align-content: flex-start;
    -moz-flex-direction: flex-start;
    -ms-flex-direction: flex-start;
  }
}
.bs-forms.bs-archivos .modulos-archivos .modulo {
  position: relative;
  width: 100px;
  height: 80px;
  display: inline-block;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  margin: 0 0px 36px 0;
  background-color: var(--bs--color-grises-gris-10);
}
@media only screen and (min-width : 600px) {
  .bs-forms.bs-archivos .modulos-archivos .modulo {
    width: 100px;
    height: 90px;
    margin: 0 16px 36px 0;
  }
}
.bs-forms.bs-archivos .modulos-archivos .modulo p.caption {
  height: auto;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font-size: 1.1rem;
  line-height: 1;
  margin-top: 4px;
  color: var(--bs--color-grises-gris-80);
}
.bs-forms.bs-archivos .modulos-archivos .modulo figure {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.bs-forms.bs-archivos .modulos-archivos .modulo figure img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bs-forms.bs-archivos .modulos-archivos .modulo.icon figure img {
  max-width: 72px;
  height: auto;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bs-forms.bs-archivos .modulos-archivos .modulo .borrar,
.bs-forms.bs-archivos .modulos-archivos .modulo .detalle,
.bs-forms.bs-archivos .modulos-archivos .modulo .agregar {
  position: absolute;
  display: block;
  cursor: pointer;
}
.bs-forms.bs-archivos .modulos-archivos .modulo .detalle,
.bs-forms.bs-archivos .modulos-archivos .modulo .agregar {
  cursor: pointer;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.bs-forms.bs-archivos .modulos-archivos .modulo .borrar {
  bottom: 0px;
  right: 0px;
  height: 30px;
  width: 30px;
  background: var(--bs--color-secundario-rojo) url(~src/assets/img/svg/icon-modulo-imagen-dell.svg) center center no-repeat;
  -webkit-border-radius: 15px 15px 5px 15px;
  -moz-border-radius: 15px 15px 5px 15px;
  -ms-border-radius: 15px 15px 5px 15px;
  border-radius: 15px 15px 5px 15px;
  display: block;
}
.bs-forms.bs-archivos .modulos-archivos .modulo .borrar:hover {
  background-color: var(--bs--color-secundario-rojo-hover);
}
.bs-forms.bs-archivos .modulos-archivos .modulo .detalle:hover {
  opacity: 0.3;
  background-color: var(--bs--color-principal-blanco);
}
.bs-forms.bs-archivos .modulos-archivos .modulo .agregar {
  background: url(~src/assets/img/svg/icon-modulo-imagen-add.svg) center center no-repeat;
}
.bs-forms.bs-archivos .modulos-archivos .modulo .agregar:hover {
  opacity: 0.5;
}

.bs-time-line {
  width: 100%;
  min-height: 40px;
}
.bs-time-line .time-line-block {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0;
}
.bs-time-line .time-line-block.background {
  background: var(--bs--color-grises-gris-10);
}
.bs-time-line .time-line-block:first-child .bs-contenedor::before {
  top: 20px;
  height: calc(100% - 20px);
}
.bs-time-line .time-line-block:last-child .bs-contenedor::before {
  height: 10px !important;
}
@media only screen and (min-width : 600px) {
  .bs-time-line .time-line-block:last-child .bs-contenedor::before {
    height: 30px !important;
  }
}
.bs-time-line .time-line-block .bs-contenedor {
  position: relative;
  padding: 0 0 20px 0;
  width: 100%;
}
.bs-time-line .time-line-block .bs-contenedor::before {
  content: "";
  position: absolute;
  top: 0px;
  width: 2px;
  height: calc(100% + 20px);
  display: block;
  left: 21px;
  background-color: var(--bs--color-principal-azul);
}
@media only screen and (min-width : 600px) {
  .bs-time-line .time-line-block .bs-contenedor::before {
    left: 26px;
  }
}
.bs-time-line .time-line-block .time-line-spot {
  display: block;
  height: 20px;
  width: 20px;
  border: 2px solid var(--bs--color-principal-azul);
  content: "";
  position: absolute;
  left: 10px;
  top: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--bs--color-principal-blanco) center center no-repeat;
}
.bs-time-line .time-line-block .time-line-spot.ok {
  background: var(--bs--color-principal-azul) url(~src/assets/img/svg/check-button-white.svg) center center no-repeat;
}
@media only screen and (min-width : 600px) {
  .bs-time-line .time-line-block .time-line-spot {
    height: 30px;
    width: 30px;
  }
}
.bs-time-line .time-line-block .time-line-module {
  width: calc(100% - 62px);
  margin-left: 46px;
  padding: 8px 16px 0 0;
}
@media only screen and (min-width : 600px) {
  .bs-time-line .time-line-block .time-line-module {
    padding: 14px 16px 4px 0;
    width: calc(100% - 72px);
    margin-left: 56px;
  }
}

.bs-banner {
  background-size: auto 100%;
  background-position: right center;
  min-height: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  padding: 8px;
  width: calc(100% - 16px);
}
@media only screen and (min-width : 600px) {
  .bs-banner {
    padding: 16px;
    width: calc(100% - 32px);
    background-size: cover;
    background-position: center center;
  }
}
.bs-banner.m-w-5 {
  max-width: 5px;
}
.bs-banner.m-w-10 {
  max-width: 10px;
}
.bs-banner.m-w-15 {
  max-width: 15px;
}
.bs-banner.m-w-20 {
  max-width: 20px;
}
.bs-banner.m-w-25 {
  max-width: 25px;
}
.bs-banner.m-w-30 {
  max-width: 30px;
}
.bs-banner.m-w-35 {
  max-width: 35px;
}
.bs-banner.m-w-40 {
  max-width: 40px;
}
.bs-banner.m-w-45 {
  max-width: 45px;
}
.bs-banner.m-w-50 {
  max-width: 50px;
}
.bs-banner.m-w-55 {
  max-width: 55px;
}
.bs-banner.m-w-60 {
  max-width: 60px;
}
.bs-banner.m-w-65 {
  max-width: 65px;
}
.bs-banner.m-w-70 {
  max-width: 70px;
}
.bs-banner.m-w-75 {
  max-width: 75px;
}
.bs-banner.m-w-80 {
  max-width: 80px;
}
.bs-banner.m-w-85 {
  max-width: 85px;
}
.bs-banner.m-w-90 {
  max-width: 90px;
}
.bs-banner.m-w-95 {
  max-width: 95px;
}
.bs-banner.m-w-100 {
  max-width: 100px;
}
.bs-banner.m-w-105 {
  max-width: 105px;
}
.bs-banner.m-w-110 {
  max-width: 110px;
}
.bs-banner.m-w-115 {
  max-width: 115px;
}
.bs-banner.m-w-120 {
  max-width: 120px;
}
.bs-banner.m-w-125 {
  max-width: 125px;
}
.bs-banner.m-w-130 {
  max-width: 130px;
}
.bs-banner.m-w-135 {
  max-width: 135px;
}
.bs-banner.m-w-140 {
  max-width: 140px;
}
.bs-banner.m-w-145 {
  max-width: 145px;
}
.bs-banner.m-w-150 {
  max-width: 150px;
}
.bs-banner.m-w-155 {
  max-width: 155px;
}
.bs-banner.m-w-160 {
  max-width: 160px;
}
.bs-banner.m-w-165 {
  max-width: 165px;
}
.bs-banner.m-w-170 {
  max-width: 170px;
}
.bs-banner.m-w-175 {
  max-width: 175px;
}
.bs-banner.m-w-180 {
  max-width: 180px;
}
.bs-banner.m-w-185 {
  max-width: 185px;
}
.bs-banner.m-w-190 {
  max-width: 190px;
}
.bs-banner.m-w-195 {
  max-width: 195px;
}
.bs-banner.m-w-200 {
  max-width: 200px;
}
.bs-banner.m-w-205 {
  max-width: 205px;
}
.bs-banner.m-w-210 {
  max-width: 210px;
}
.bs-banner.m-w-215 {
  max-width: 215px;
}
.bs-banner.m-w-220 {
  max-width: 220px;
}
.bs-banner.m-w-225 {
  max-width: 225px;
}
.bs-banner.m-w-230 {
  max-width: 230px;
}
.bs-banner.m-w-235 {
  max-width: 235px;
}
.bs-banner.m-w-240 {
  max-width: 240px;
}
.bs-banner.m-w-245 {
  max-width: 245px;
}
.bs-banner.m-w-250 {
  max-width: 250px;
}
.bs-banner.m-w-255 {
  max-width: 255px;
}
.bs-banner.m-w-260 {
  max-width: 260px;
}
.bs-banner.m-w-265 {
  max-width: 265px;
}
.bs-banner.m-w-270 {
  max-width: 270px;
}
.bs-banner.m-w-275 {
  max-width: 275px;
}
.bs-banner.m-w-280 {
  max-width: 280px;
}
.bs-banner.m-w-285 {
  max-width: 285px;
}
.bs-banner.m-w-290 {
  max-width: 290px;
}
.bs-banner.m-w-295 {
  max-width: 295px;
}
.bs-banner.m-w-300 {
  max-width: 300px;
}
.bs-banner.m-w-305 {
  max-width: 305px;
}
.bs-banner.m-w-310 {
  max-width: 310px;
}
.bs-banner.m-w-315 {
  max-width: 315px;
}
.bs-banner.m-w-320 {
  max-width: 320px;
}
.bs-banner.m-w-325 {
  max-width: 325px;
}
.bs-banner.m-w-330 {
  max-width: 330px;
}
.bs-banner.m-w-335 {
  max-width: 335px;
}
.bs-banner.m-w-340 {
  max-width: 340px;
}
.bs-banner.m-w-345 {
  max-width: 345px;
}
.bs-banner.m-w-350 {
  max-width: 350px;
}
.bs-banner.m-w-355 {
  max-width: 355px;
}
.bs-banner.m-w-360 {
  max-width: 360px;
}
.bs-banner.m-w-365 {
  max-width: 365px;
}
.bs-banner.m-w-370 {
  max-width: 370px;
}
.bs-banner.m-w-375 {
  max-width: 375px;
}
.bs-banner.m-w-380 {
  max-width: 380px;
}
.bs-banner.m-w-385 {
  max-width: 385px;
}
.bs-banner.m-w-390 {
  max-width: 390px;
}
.bs-banner.m-w-395 {
  max-width: 395px;
}
.bs-banner.m-w-400 {
  max-width: 400px;
}
.bs-banner.m-w-405 {
  max-width: 405px;
}
.bs-banner.m-w-410 {
  max-width: 410px;
}
.bs-banner.m-w-415 {
  max-width: 415px;
}
.bs-banner.m-w-420 {
  max-width: 420px;
}
.bs-banner.m-w-425 {
  max-width: 425px;
}
.bs-banner.m-w-430 {
  max-width: 430px;
}
.bs-banner.m-w-435 {
  max-width: 435px;
}
.bs-banner.m-w-440 {
  max-width: 440px;
}
.bs-banner.m-w-445 {
  max-width: 445px;
}
.bs-banner.m-w-450 {
  max-width: 450px;
}
.bs-banner.m-w-455 {
  max-width: 455px;
}
.bs-banner.m-w-460 {
  max-width: 460px;
}
.bs-banner.m-w-465 {
  max-width: 465px;
}
.bs-banner.m-w-470 {
  max-width: 470px;
}
.bs-banner.m-w-475 {
  max-width: 475px;
}
.bs-banner.m-w-480 {
  max-width: 480px;
}
.bs-banner.m-w-485 {
  max-width: 485px;
}
.bs-banner.m-w-490 {
  max-width: 490px;
}
.bs-banner.m-w-495 {
  max-width: 495px;
}
.bs-banner.m-w-500 {
  max-width: 500px;
}
.bs-banner.m-w-505 {
  max-width: 505px;
}
.bs-banner.m-w-510 {
  max-width: 510px;
}
.bs-banner.m-w-515 {
  max-width: 515px;
}
.bs-banner.m-w-520 {
  max-width: 520px;
}
.bs-banner.m-w-525 {
  max-width: 525px;
}
.bs-banner.m-w-530 {
  max-width: 530px;
}
.bs-banner.m-w-535 {
  max-width: 535px;
}
.bs-banner.m-w-540 {
  max-width: 540px;
}
.bs-banner.m-w-545 {
  max-width: 545px;
}
.bs-banner.m-w-550 {
  max-width: 550px;
}
.bs-banner.m-w-555 {
  max-width: 555px;
}
.bs-banner.m-w-560 {
  max-width: 560px;
}
.bs-banner.m-w-565 {
  max-width: 565px;
}
.bs-banner.m-w-570 {
  max-width: 570px;
}
.bs-banner.m-w-575 {
  max-width: 575px;
}
.bs-banner.m-w-580 {
  max-width: 580px;
}
.bs-banner.m-w-585 {
  max-width: 585px;
}
.bs-banner.m-w-590 {
  max-width: 590px;
}
.bs-banner.m-w-595 {
  max-width: 595px;
}
.bs-banner.m-w-600 {
  max-width: 600px;
}
.bs-banner.m-w-605 {
  max-width: 605px;
}
.bs-banner.m-w-610 {
  max-width: 610px;
}
.bs-banner.m-w-615 {
  max-width: 615px;
}
.bs-banner.m-w-620 {
  max-width: 620px;
}
.bs-banner.m-w-625 {
  max-width: 625px;
}
.bs-banner.m-w-630 {
  max-width: 630px;
}
.bs-banner.m-w-635 {
  max-width: 635px;
}
.bs-banner.m-w-640 {
  max-width: 640px;
}
.bs-banner.m-w-645 {
  max-width: 645px;
}
.bs-banner.m-w-650 {
  max-width: 650px;
}
.bs-banner.m-w-655 {
  max-width: 655px;
}
.bs-banner.m-w-660 {
  max-width: 660px;
}
.bs-banner.m-w-665 {
  max-width: 665px;
}
.bs-banner.m-w-670 {
  max-width: 670px;
}
.bs-banner.m-w-675 {
  max-width: 675px;
}
.bs-banner.m-w-680 {
  max-width: 680px;
}
.bs-banner.m-w-685 {
  max-width: 685px;
}
.bs-banner.m-w-690 {
  max-width: 690px;
}
.bs-banner.m-w-695 {
  max-width: 695px;
}
.bs-banner.m-w-700 {
  max-width: 700px;
}
.bs-banner.m-w-705 {
  max-width: 705px;
}
.bs-banner.m-w-710 {
  max-width: 710px;
}
.bs-banner.m-w-715 {
  max-width: 715px;
}
.bs-banner.m-w-720 {
  max-width: 720px;
}
.bs-banner.m-w-725 {
  max-width: 725px;
}
.bs-banner.m-w-730 {
  max-width: 730px;
}
.bs-banner.m-w-735 {
  max-width: 735px;
}
.bs-banner.m-w-740 {
  max-width: 740px;
}
.bs-banner.m-w-745 {
  max-width: 745px;
}
.bs-banner.m-w-750 {
  max-width: 750px;
}
.bs-banner.m-w-755 {
  max-width: 755px;
}
.bs-banner.m-w-760 {
  max-width: 760px;
}
.bs-banner.m-w-765 {
  max-width: 765px;
}
.bs-banner.m-w-770 {
  max-width: 770px;
}
.bs-banner.m-w-775 {
  max-width: 775px;
}
.bs-banner.m-w-780 {
  max-width: 780px;
}
.bs-banner.m-w-785 {
  max-width: 785px;
}
.bs-banner.m-w-790 {
  max-width: 790px;
}
.bs-banner.m-w-795 {
  max-width: 795px;
}
.bs-banner.m-w-800 {
  max-width: 800px;
}
.bs-banner.m-w-805 {
  max-width: 805px;
}
.bs-banner.m-w-810 {
  max-width: 810px;
}
.bs-banner.m-w-815 {
  max-width: 815px;
}
.bs-banner.m-w-820 {
  max-width: 820px;
}
.bs-banner.m-w-825 {
  max-width: 825px;
}
.bs-banner.m-w-830 {
  max-width: 830px;
}
.bs-banner.m-w-835 {
  max-width: 835px;
}
.bs-banner.m-w-840 {
  max-width: 840px;
}
.bs-banner.m-w-845 {
  max-width: 845px;
}
.bs-banner.m-w-850 {
  max-width: 850px;
}
.bs-banner.m-w-855 {
  max-width: 855px;
}
.bs-banner.m-w-860 {
  max-width: 860px;
}
.bs-banner.m-w-865 {
  max-width: 865px;
}
.bs-banner.m-w-870 {
  max-width: 870px;
}
.bs-banner.m-w-875 {
  max-width: 875px;
}
.bs-banner.m-w-880 {
  max-width: 880px;
}
.bs-banner.m-w-885 {
  max-width: 885px;
}
.bs-banner.m-w-890 {
  max-width: 890px;
}
.bs-banner.m-w-895 {
  max-width: 895px;
}
.bs-banner.m-w-900 {
  max-width: 900px;
}
.bs-banner.m-w-905 {
  max-width: 905px;
}
.bs-banner.m-w-910 {
  max-width: 910px;
}
.bs-banner.m-w-915 {
  max-width: 915px;
}
.bs-banner.m-w-920 {
  max-width: 920px;
}
.bs-banner.m-w-925 {
  max-width: 925px;
}
.bs-banner.m-w-930 {
  max-width: 930px;
}
.bs-banner.m-w-935 {
  max-width: 935px;
}
.bs-banner.m-w-940 {
  max-width: 940px;
}
.bs-banner.m-w-945 {
  max-width: 945px;
}
.bs-banner.m-w-950 {
  max-width: 950px;
}
.bs-banner.m-w-955 {
  max-width: 955px;
}
.bs-banner.m-w-960 {
  max-width: 960px;
}
.bs-banner.m-w-965 {
  max-width: 965px;
}
.bs-banner.m-w-970 {
  max-width: 970px;
}
.bs-banner.m-w-975 {
  max-width: 975px;
}
.bs-banner.m-w-980 {
  max-width: 980px;
}
.bs-banner.m-w-985 {
  max-width: 985px;
}
.bs-banner.m-w-990 {
  max-width: 990px;
}
.bs-banner.m-w-995 {
  max-width: 995px;
}
.bs-banner.m-w-1000 {
  max-width: 1000px;
}
.bs-banner.m-w-1005 {
  max-width: 1005px;
}
.bs-banner.m-w-1010 {
  max-width: 1010px;
}
.bs-banner.m-w-1015 {
  max-width: 1015px;
}
.bs-banner.m-w-1020 {
  max-width: 1020px;
}
.bs-banner.m-w-1025 {
  max-width: 1025px;
}
.bs-banner.m-w-1030 {
  max-width: 1030px;
}
.bs-banner.m-w-1035 {
  max-width: 1035px;
}
.bs-banner.m-w-1040 {
  max-width: 1040px;
}
.bs-banner.m-w-1045 {
  max-width: 1045px;
}
.bs-banner.m-w-1050 {
  max-width: 1050px;
}
.bs-banner.m-w-1055 {
  max-width: 1055px;
}
.bs-banner.m-w-1060 {
  max-width: 1060px;
}
.bs-banner.m-w-1065 {
  max-width: 1065px;
}
.bs-banner.m-w-1070 {
  max-width: 1070px;
}
.bs-banner.m-w-1075 {
  max-width: 1075px;
}
.bs-banner.m-w-1080 {
  max-width: 1080px;
}
.bs-banner.m-w-1085 {
  max-width: 1085px;
}
.bs-banner.m-w-1090 {
  max-width: 1090px;
}
.bs-banner.m-w-1095 {
  max-width: 1095px;
}
.bs-banner.m-w-1100 {
  max-width: 1100px;
}
.bs-banner.m-w-1105 {
  max-width: 1105px;
}
.bs-banner.m-w-1110 {
  max-width: 1110px;
}
.bs-banner.m-w-1115 {
  max-width: 1115px;
}
.bs-banner.m-w-1120 {
  max-width: 1120px;
}
.bs-banner.m-w-1125 {
  max-width: 1125px;
}
.bs-banner.m-w-1130 {
  max-width: 1130px;
}
.bs-banner.m-w-1135 {
  max-width: 1135px;
}
.bs-banner.m-w-1140 {
  max-width: 1140px;
}
.bs-banner.m-w-1145 {
  max-width: 1145px;
}
.bs-banner.m-w-1150 {
  max-width: 1150px;
}
.bs-banner.m-w-1155 {
  max-width: 1155px;
}
.bs-banner.m-w-1160 {
  max-width: 1160px;
}
.bs-banner.m-w-1165 {
  max-width: 1165px;
}
.bs-banner.m-w-1170 {
  max-width: 1170px;
}
.bs-banner.m-w-1175 {
  max-width: 1175px;
}
.bs-banner.m-w-1180 {
  max-width: 1180px;
}
.bs-banner.m-w-1185 {
  max-width: 1185px;
}
.bs-banner.m-w-1190 {
  max-width: 1190px;
}
.bs-banner.m-w-1195 {
  max-width: 1195px;
}
.bs-banner.m-w-1200 {
  max-width: 1200px;
}
.bs-banner.m-w-1205 {
  max-width: 1205px;
}
.bs-banner.m-w-1210 {
  max-width: 1210px;
}
.bs-banner.m-w-1215 {
  max-width: 1215px;
}
.bs-banner.m-w-1220 {
  max-width: 1220px;
}
.bs-banner.m-w-1225 {
  max-width: 1225px;
}
.bs-banner.m-w-1230 {
  max-width: 1230px;
}
.bs-banner.m-w-1235 {
  max-width: 1235px;
}
.bs-banner.m-w-1240 {
  max-width: 1240px;
}
.bs-banner.m-w-1245 {
  max-width: 1245px;
}
.bs-banner.m-w-1250 {
  max-width: 1250px;
}
.bs-banner.m-w-1255 {
  max-width: 1255px;
}
.bs-banner figure {
  flex: 0 0 64px;
}
.bs-banner figure img {
  width: 100%;
  height: auto;
}
@media only screen and (min-width : 600px) {
  .bs-banner figure {
    flex: 0 0 96px;
  }
}
.bs-banner figcaption {
  flex: 1;
}
.bs-banner figcaption h1 {
  font-size: clamp(1.5rem,1.7vw + 0.2rem, 2rem);
  color: var(--bs--color-principal-blanco);
  font-family: "overpassbold", sans-serif;
}
.bs-banner figcaption h2 {
  font-size: clamp(1.5rem,1.7vw + 0.2rem, 1.8rem);
  color: var(--bs--color-principal-blanco);
  font-family: "overpassregular", sans-serif;
}
.bs-banner figcaption h3 {
  font-size: clamp(1.4rem,1.7vw + 0.2rem, 1.7rem);
  color: var(--bs--color-principal-blanco);
  font-family: "overpassbold", sans-serif;
}
.bs-banner figcaption h4 {
  font-size: clamp(1.3rem,1.7vw + 0.2rem,1.6rem);
  color: var(--bs--color-principal-blanco);
  font-family: "overpassregular", sans-serif;
}
.bs-banner figcaption h5 {
  font-size: clamp(1.2rem,1.7vw + 0.2rem, 1.5rem);
  color: var(--bs--color-principal-blanco);
  font-family: "overpassbold", sans-serif;
}
.bs-banner figcaption p {
  font-size: clamp(1.2rem,1.7vw + 0.2rem, 1.5rem);
  color: var(--bs--color-principal-blanco);
}

.bs-aplicacion-movil {
  width: 100%;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
}
@media only screen and (min-width : 600px) {
  .bs-aplicacion-movil {
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    -webkit-align-content: flex-start;
    -moz-flex-direction: flex-start;
    -ms-flex-direction: flex-start;
    flex-direction: row;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
  }
}
.bs-aplicacion-movil > figcaption {
  display: block;
  flex: 1;
  padding-right: 10px;
}
@media only screen and (min-width : 600px) {
  .bs-aplicacion-movil > figcaption {
    padding-right: 20px;
  }
}
.bs-aplicacion-movil > figure {
  flex: 1;
  max-width: 230px;
  margin-right: 20px;
}
.bs-aplicacion-movil > figure img {
  margin-top: 30px;
  width: 100%;
  height: auto;
}
@media only screen and (min-width : 600px) {
  .bs-aplicacion-movil > figure img {
    margin-top: 0px;
  }
}
@media only screen and (min-width : 1000px) {
  .bs-aplicacion-movil > figure img {
    width: auto;
    height: auto;
  }
}
@media only screen and (min-width : 600px) {
  .bs-aplicacion-movil > figure {
    flex: 1;
    max-width: 100px;
    margin-right: 20px;
  }
}
@media only screen and (min-width : 1000px) {
  .bs-aplicacion-movil > figure {
    flex: 1;
    max-width: 160px;
    margin-right: 40px;
  }
}

.bs-tabla-comparativa {
  width: 100%;
  overflow: auto hidden;
}
.bs-tabla-comparativa::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.bs-tabla-comparativa::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
.bs-tabla-comparativa::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-grises-gris-50);
  cursor: pointer;
}
.bs-tabla-comparativa section {
  display: block;
  width: 100%;
  min-width: 290px;
  padding-top: 40px;
  padding: 40px 0px 8px 0px;
}
.bs-tabla-comparativa section .cabecera .columna {
  border-top: 3px solid var(--bs--color-grises-gris-20);
}
.bs-tabla-comparativa section .cabecera .columna:first-child {
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-left: 3px solid rgba(0, 0, 0, 0);
}
.bs-tabla-comparativa section .cabecera,
.bs-tabla-comparativa section .contenido {
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  min-height: 10px;
  position: relative;
}
.bs-tabla-comparativa section .cabecera .columna,
.bs-tabla-comparativa section .contenido .columna {
  flex: 1;
  min-width: 64px;
  max-width: 320px;
  position: relative;
  min-height: 96px;
  border-bottom: 3px solid var(--bs--color-grises-gris-20);
  border-left: 3px solid var(--bs--color-grises-gris-20);
}
@media only screen and (min-width : 600px) {
  .bs-tabla-comparativa section .cabecera .columna,
.bs-tabla-comparativa section .contenido .columna {
    min-height: 64px;
    padding-top: 0px;
  }
}
.bs-tabla-comparativa section .cabecera .columna:last-child,
.bs-tabla-comparativa section .contenido .columna:last-child {
  border-right: 3px solid var(--bs--color-grises-gris-20);
}
.bs-tabla-comparativa section .cabecera .columna.no-border::before,
.bs-tabla-comparativa section .contenido .columna.no-border::before {
  border-top: none;
}
.bs-tabla-comparativa section .cabecera .columna > p,
.bs-tabla-comparativa section .contenido .columna > p {
  flex: 0 0 100%;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-principal-negro);
  text-align: center;
  line-height: 1;
  font-size: 1.3rem;
}
@media only screen and (min-width : 600px) {
  .bs-tabla-comparativa section .cabecera .columna > p,
.bs-tabla-comparativa section .contenido .columna > p {
    font-size: 1.5rem;
  }
}
.bs-tabla-comparativa section .cabecera .columna > span.ok, .bs-tabla-comparativa section .cabecera .columna > span.nok, .bs-tabla-comparativa section .cabecera .columna > span.min,
.bs-tabla-comparativa section .contenido .columna > span.ok,
.bs-tabla-comparativa section .contenido .columna > span.nok,
.bs-tabla-comparativa section .contenido .columna > span.min {
  height: 30px;
  width: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 6px;
}
.bs-tabla-comparativa section .cabecera .columna > span.ok,
.bs-tabla-comparativa section .contenido .columna > span.ok {
  background-image: url(~src/assets/img/svg/icon-table-ok.svg);
}
.bs-tabla-comparativa section .cabecera .columna > span.nok,
.bs-tabla-comparativa section .contenido .columna > span.nok {
  background-image: url(~src/assets/img/svg/icon-table-nok.svg);
}
.bs-tabla-comparativa section .cabecera .columna > span.min,
.bs-tabla-comparativa section .contenido .columna > span.min {
  background-image: url(~src/assets/img/svg/icon-table-min.svg);
}
.bs-tabla-comparativa section .cabecera .columna figure,
.bs-tabla-comparativa section .contenido .columna figure {
  margin: 0;
  padding: 0;
}
.bs-tabla-comparativa section .cabecera .columna:not(.titulo):not(.descriptivo):not(.subtitulo),
.bs-tabla-comparativa section .contenido .columna:not(.titulo):not(.descriptivo):not(.subtitulo) {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
  -webkit-align-content: flex-end;
  -moz-flex-direction: flex-end;
  -ms-flex-direction: flex-end;
}
.bs-tabla-comparativa section .cabecera .columna:not(.titulo):not(.descriptivo):not(.subtitulo) figure,
.bs-tabla-comparativa section .contenido .columna:not(.titulo):not(.descriptivo):not(.subtitulo) figure {
  width: 30px;
}
.bs-tabla-comparativa section .cabecera .columna:not(.titulo):not(.descriptivo):not(.subtitulo) figure img,
.bs-tabla-comparativa section .contenido .columna:not(.titulo):not(.descriptivo):not(.subtitulo) figure img {
  width: 100%;
  height: auto;
}
.bs-tabla-comparativa section .cabecera .columna.subtitulo,
.bs-tabla-comparativa section .contenido .columna.subtitulo {
  min-height: 30px;
  background: var(--bs--color-grises-gris-10);
  position: static;
  max-width: none;
  padding-top: 0px;
}
.bs-tabla-comparativa section .cabecera .columna.subtitulo h3,
.bs-tabla-comparativa section .contenido .columna.subtitulo h3 {
  color: var(--bs--color-principal-azul);
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 3rem;
  text-indent: 16px;
}
.bs-tabla-comparativa section .cabecera .columna.descriptivo,
.bs-tabla-comparativa section .contenido .columna.descriptivo {
  width: 0px;
  min-width: 0;
  flex: 0 0 0px;
}
.bs-tabla-comparativa section .cabecera .columna.descriptivo p,
.bs-tabla-comparativa section .contenido .columna.descriptivo p {
  position: absolute;
  top: 16px;
  left: 16px;
  white-space: nowrap;
  text-align: left;
  font-size: 1.3rem;
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-negro);
}
@media only screen and (max-width : 500px) {
  .bs-tabla-comparativa section .cabecera .columna.descriptivo,
.bs-tabla-comparativa section .contenido .columna.descriptivo {
    border: none;
  }
}
@media only screen and (min-width : 600px) {
  .bs-tabla-comparativa section .cabecera .columna.descriptivo,
.bs-tabla-comparativa section .contenido .columna.descriptivo {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    -webkit-align-content: center;
    -moz-flex-direction: center;
    -ms-flex-direction: center;
    flex: 1;
    min-width: 60px;
    max-width: 320px;
  }
  .bs-tabla-comparativa section .cabecera .columna.descriptivo p,
.bs-tabla-comparativa section .contenido .columna.descriptivo p {
    position: static;
    font-size: 1.5rem;
    text-indent: 16px;
  }
}
.bs-tabla-comparativa section .cabecera .columna.titulo,
.bs-tabla-comparativa section .contenido .columna.titulo {
  background: var(--bs--color-principal-azul);
  min-height: 80px;
  padding-top: 0px;
}
.bs-tabla-comparativa section .cabecera .columna.titulo figure,
.bs-tabla-comparativa section .contenido .columna.titulo figure {
  display: none;
  width: 50px;
  position: absolute;
  left: calc(50% - 25px);
  top: -30px;
  z-index: 10;
}
.bs-tabla-comparativa section .cabecera .columna.titulo figure img,
.bs-tabla-comparativa section .contenido .columna.titulo figure img {
  width: 100%;
  height: auto;
}
@media only screen and (min-width : 600px) {
  .bs-tabla-comparativa section .cabecera .columna.titulo figure,
.bs-tabla-comparativa section .contenido .columna.titulo figure {
    background: var(--bs--color-principal-blanco);
    display: grid;
    place-items: center;
  }
}
@media only screen and (min-width : 600px) {
  .bs-tabla-comparativa section .cabecera .columna.titulo,
.bs-tabla-comparativa section .contenido .columna.titulo {
    background: none;
  }
}
.bs-tabla-comparativa section .cabecera .columna.titulo h1,
.bs-tabla-comparativa section .contenido .columna.titulo h1 {
  color: var(--bs--color-principal-blanco);
  font-size: 1.3rem;
  font-family: "overpassregular", sans-serif;
  line-height: 1.5;
  margin: 16px auto 8px;
  text-align: center;
}
@media only screen and (min-width : 600px) {
  .bs-tabla-comparativa section .cabecera .columna.titulo h1,
.bs-tabla-comparativa section .contenido .columna.titulo h1 {
    margin: 24px auto 8px;
    color: var(--bs--color-principal-negro);
    font-size: clamp(1.5rem, 1vw + 0.5rem, 1.8rem);
    font-family: "overpassbold", sans-serif;
  }
}

.bs-menu-module {
  z-index: 1000;
  background-color: var(--bs--color-grises-gris-10);
  width: 100%;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}
.bs-menu-module::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.bs-menu-module::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
.bs-menu-module::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-grises-gris-50);
  cursor: pointer;
}
@media only screen and (min-width : 600px) {
  .bs-menu-module {
    max-width: 320px;
    max-height: 100%;
    height: auto;
  }
}
.bs-menu-module.flotante {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
}
@media only screen and (min-width : 600px) {
  .bs-menu-module.flotante {
    position: fixed;
    left: inherit;
    height: auto;
    max-height: 100vh;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  }
}
.bs-menu-module.lateral {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0px;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
@media only screen and (min-width : 600px) {
  .bs-menu-module.lateral {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }
}
.bs-menu-module button.close {
  height: 60px;
  width: 40px;
  display: block;
  background: url(~src/assets/img/svg/delete-button.svg) center center no-repeat;
  background-size: 20px auto;
}
.bs-menu-module button.close:hover {
  opacity: 0.5;
}
.bs-menu-module .menu input {
  display: none;
}
.bs-menu-module .menu input:checked + label span {
  color: var(--bs--color-principal-verde);
}
.bs-menu-module .menu input:checked + label figure {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
.bs-menu-module .menu input:checked ~ .child {
  display: block;
}
.bs-menu-module .menu li {
  list-style: none;
}
.bs-menu-module .menu > li {
  min-height: 2.4rem;
  border-top: 1px solid var(--bs--color-grises-gris-20);
}
.bs-menu-module .menu > li:last-child {
  border-bottom: 1px solid var(--bs--color-grises-gris-20);
}
.bs-menu-module .menu > li a.link,
.bs-menu-module .menu > li label.parent {
  cursor: pointer;
}
.bs-menu-module .menu > li a.link:hover,
.bs-menu-module .menu > li label.parent:hover {
  color: var(--bs--color-principal-verde);
  font-family: "overpassbold", sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
.bs-menu-module .menu > li a.link:hover figure,
.bs-menu-module .menu > li label.parent:hover figure {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
.bs-menu-module .menu > li > a.link:not(.logout) span {
  background: url(~src/assets/img/svg/icon-menu-arrow.svg) right center no-repeat;
  padding-right: 24px;
}
.bs-menu-module .menu > li > a.link,
.bs-menu-module .menu > li > .parent {
  font-size: 1.6rem;
  color: var(--bs--color-principal-negro);
  font-family: "overpassbold", sans-serif;
  line-height: 1;
  min-height: 6rem;
  width: 100%;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-menu-module .menu > li > a.link figure,
.bs-menu-module .menu > li > .parent figure {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin: 20px 10px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.bs-menu-module .menu > li > a.link figure img,
.bs-menu-module .menu > li > .parent figure img {
  height: 100%;
  width: auto;
}
.bs-menu-module .menu > li .child {
  width: 100%;
  margin-bottom: 10px;
}
.bs-menu-module .menu > li > .child {
  margin-top: -10px;
  padding-left: 40px;
  width: calc(100% - 50px);
}
.bs-menu-module .menu > li > .child a.link,
.bs-menu-module .menu > li > .child label.parent span {
  line-height: 2.4rem;
}
.bs-menu-module .menu > li > .child input + label.parent span {
  background: url(~src/assets/img/svg/arrow-button-down-green.svg) right center no-repeat;
}
.bs-menu-module .menu > li > .child input:checked + label.parent span {
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-negro);
  background: url(~src/assets/img/svg/arrow-button-up-green.svg) right center no-repeat;
}
.bs-menu-module .menu > li > .child label.parent {
  display: inline-block;
  width: 100%;
}
.bs-menu-module .menu > li > .child label.parent span {
  width: 100%;
  display: inline-block;
  flex: 1;
}
.bs-menu-module .menu > li .child {
  display: none;
}
.bs-menu-module .menu > li .child li {
  line-height: 2;
}

.bs-menu-desplegable {
  position: fixed;
  background: var(--bs--color-grises-gris-10);
  display: block;
  border: 1px solid var(--bs--color-grises-gris-20);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  z-index: 901;
  width: 100%;
  top: calc(100% + 20px);
}
.bs-menu-desplegable:after {
  position: absolute;
  right: 24px;
  height: 25px;
  top: -22px;
  font-size: 2rem;
  content: "▲";
  display: block;
  color: var(--bs--color-grises-gris-20);
}
@media only screen and (min-width : 600px) {
  .bs-menu-desplegable:before {
    content: "";
    height: 25px;
    width: 100%;
    display: block;
    position: absolute;
    top: -25px;
    left: 0;
  }
}
.bs-menu-desplegable ul {
  margin-bottom: 8px;
}
.bs-menu-desplegable ul li {
  list-style: none;
}
.bs-menu-desplegable ul li > a:not(.bs-btn) {
  min-height: 48px;
  padding: 10px;
}
.bs-menu-desplegable ul li .detalle,
.bs-menu-desplegable ul li .link {
  border-bottom: 1px solid var(--bs--color-grises-gris-40);
  width: calc(100% - 40px);
  padding: 20px;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-menu-desplegable ul li .detalle p {
  line-height: 1.2;
  flex: 1;
  margin-right: 10px;
  font-family: "overpassbold", sans-serif;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bs-menu-desplegable ul li .link {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
}
.bs-menu-desplegable ul li .link.icon {
  width: calc(100% - 30px);
  padding-left: 10px;
}
.bs-menu-desplegable ul li .link span {
  flex: 1;
  font-family: "overpassbold", sans-serif;
  line-height: 1.2;
}
.bs-menu-desplegable ul li .link:hover span {
  color: var(--bs--color-principal-verde);
}
.bs-menu-desplegable ul li .link.center span {
  width: 100%;
  text-align: center;
}
.bs-menu-desplegable ul li .link:not(.center) span {
  background: url(~src/assets/img/svg/icon-menu-arrow.svg) right center no-repeat;
  padding-right: 24px;
}
.bs-menu-desplegable ul li .link:not(.center) figure {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0 10px 0 0;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.bs-menu-desplegable ul li .link:not(.center) figure img {
  height: 100%;
  width: auto;
}
.bs-menu-desplegable ul li .link:not(.center):hover figure {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
@media only screen and (min-width : 600px) {
  .bs-menu-desplegable {
    width: 320px;
    position: absolute;
  }
}
.corporativo .bs-menu-desplegable {
  top: calc(100% + 20px);
  right: 0px;
}
@media only screen and (min-width : 600px) {
  .corporativo .bs-menu-desplegable {
    top: 110%;
  }
}
.privado .bs-menu-desplegable {
  right: 0px;
  top: calc(100% + 20px);
}
@media only screen and (min-width : 600px) {
  .privado .bs-menu-desplegable {
    top: calc(100% + 20px);
  }
}

.bs-accordeon .accordeon-item {
  border: 1px solid var(--bs--color-grises-gris-10);
  margin-bottom: 10px;
}
.bs-accordeon .accordeon-item input {
  display: none;
}
.bs-accordeon .accordeon-item input:checked ~ .accordeon-item-content {
  height: auto;
  display: block;
}
.bs-accordeon .accordeon-item input:checked ~ .accordeon-item-header figure {
  background: var(--bs--color-grises-gris-20);
}
.bs-accordeon .accordeon-item input:checked ~ .accordeon-item-header figure::before {
  transform: rotate(45deg);
}
.bs-accordeon .accordeon-item input:checked ~ .accordeon-item-header span {
  font-family: "overpassbold", sans-serif;
}
.bs-accordeon .accordeon-item .accordeon-item-header {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  width: 100%;
  cursor: pointer;
  min-height: 50px;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  position: relative;
  border-bottom: 1px solid var(--bs--color-grises-gris-10);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.bs-accordeon .accordeon-item .accordeon-item-header figure {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50px;
  background: var(--bs--color-grises-gris-10);
}
@media only screen and (min-width : 600px) {
  .bs-accordeon .accordeon-item .accordeon-item-header figure {
    width: 60px;
  }
}
.bs-accordeon .accordeon-item .accordeon-item-header figure::before {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: block;
  content: "";
  height: 18px;
  width: 18px;
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 9px);
  background: url(~src/assets/img/svg/accordeon-icon.svg) center center no-repeat;
}
.bs-accordeon .accordeon-item .accordeon-item-header span {
  margin-left: 50px;
  width: calc(100% - 50px);
  padding-left: 20px;
}
@media only screen and (min-width : 600px) {
  .bs-accordeon .accordeon-item .accordeon-item-header span {
    margin-left: 60px;
    width: calc(100% - 60px);
    padding-left: 10px;
  }
}
.bs-accordeon .accordeon-item .accordeon-item-content {
  display: none;
  padding: 10px;
}

.bs-tarjeta-detalle {
  width: 100%;
  display: block;
  background: var(--bs--color-principal-blanco);
  border: 2px solid var(--bs--color-grises-gris-20);
  margin-bottom: 20px;
}
.bs-tarjeta-detalle .top {
  border-bottom: 2px solid var(--bs--color-grises-gris-20);
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  padding: 4px 6px;
}
.bs-tarjeta-detalle .top .bs-chip {
  margin: 0;
}
.bs-tarjeta-detalle .top h1 {
  flex: 1;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: var(--bs--color-principal-negro);
  font-family: "overpassbold", sans-serif;
}
.bs-tarjeta-detalle .top h1::before {
  font-family: "overpassregular", sans-serif;
  font-size: 1rem;
  line-height: 0.9;
  content: attr(data-title);
  display: block;
}
.bs-tarjeta-detalle .content {
  background: var(--bs--color-grises-gris-10);
  border-bottom: 2px solid var(--bs--color-grises-gris-20);
}
.bs-tarjeta-detalle .content .data {
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
  border-bottom: 1px solid var(--bs--color-grises-gris-40);
}
.bs-tarjeta-detalle .content .data:last-child {
  border: none;
}
.bs-tarjeta-detalle .content .data .data-module {
  flex: 1;
  padding: 8px;
  display: block;
}
.bs-tarjeta-detalle .content .data .data-module + .data-module {
  flex: 0 1 40%;
}
.bs-tarjeta-detalle .content .data .data-module p {
  line-height: 1.5;
  font-family: "overpassregular", sans-serif;
  font-size: 1.1rem;
}
.bs-tarjeta-detalle .content .data .data-module h5 {
  line-height: 1.2;
  font-family: "overpassbold", sans-serif;
  font-size: 1.5rem;
}
.bs-tarjeta-detalle .extra {
  background: var(--bs--color-principal-blanco);
}
.bs-tarjeta-detalle .extra .expandable-content {
  display: none;
}
@media only screen and (min-width : 600px) {
  .bs-tarjeta-detalle .extra .expandable-content {
    display: block;
  }
}
.bs-tarjeta-detalle .extra .expandable-content .data-footer {
  margin: 0 auto;
  padding: 4px 0;
  width: 90%;
  margin-bottom: 10px;
}
.bs-tarjeta-detalle .extra .expandable-content .data-footer p {
  font-size: 1.2rem;
  line-height: 1.4;
}
.bs-tarjeta-detalle .extra .expandable-content .data-module {
  margin: 0 auto;
  padding: 4px 0;
  width: 90%;
  border-top: 1px solid var(--bs--color-grises-gris-20);
}
.bs-tarjeta-detalle .extra .expandable-content .data-module:first-of-type {
  border: none;
}
.bs-tarjeta-detalle .extra .expandable-content .data-module a:not(.bs-btn) {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  text-decoration: none;
  color: var(--bs--color-principal-negro);
}
.bs-tarjeta-detalle .extra .expandable-content .data-module a:not(.bs-btn):hover {
  color: var(--bs--color-grises-gris-80);
}
.bs-tarjeta-detalle .extra .expandable-content .data-module a:not(.bs-btn):hover figure {
  opacity: 0.6;
}
.bs-tarjeta-detalle .extra .expandable-content .data-module a:not(.bs-btn) figure {
  width: 40px;
}
.bs-tarjeta-detalle .extra .expandable-content .data-module a:not(.bs-btn) figure img {
  height: 100%;
  width: auto;
}
.bs-tarjeta-detalle .extra .expandable-content .data-module a:not(.bs-btn) figcaption {
  flex: 1;
  font-family: "overpassregular", sans-serif;
}
.bs-tarjeta-detalle .extra .expandable-content .data-module a:not(.bs-btn) figcaption span {
  display: block;
  font-size: 1.2rem;
}
.bs-tarjeta-detalle .extra .expandable-content .data-module a:not(.bs-btn) figcaption strong {
  font-family: "overpassbold", sans-serif;
}
.bs-tarjeta-detalle .extra input[type=checkbox] {
  display: none;
}
.bs-tarjeta-detalle .extra input[type=checkbox] + label {
  color: var(--bs--color-principal-verde);
  font-family: "overpassbold", sans-serif;
  line-height: 2.4;
  width: 95%;
  margin: 0 auto;
  text-decoration: underline;
  display: block;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: width 0.2s linear;
  -moz-transition: width 0.2s linear;
  -ms-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
  cursor: pointer;
}
.bs-tarjeta-detalle .extra input[type=checkbox] + label:hover {
  color: var(--bs--color-principal-verde-hover);
}
.bs-tarjeta-detalle .extra input[type=checkbox] + label:before {
  content: attr(data-off);
}
@media only screen and (min-width : 600px) {
  .bs-tarjeta-detalle .extra input[type=checkbox] + label {
    display: none;
  }
}
.bs-tarjeta-detalle .extra input[type=checkbox]:checked + label {
  width: 90%;
}
.bs-tarjeta-detalle .extra input[type=checkbox]:checked + label:before {
  content: attr(data-on);
}
.bs-tarjeta-detalle .extra input[type=checkbox]:checked ~ .expandable-content {
  display: block;
}

.bs-tarjetas-estadisticas {
  width: calc(100% - 16px);
  margin: 0 auto 12px;
  display: block;
  outline: 1px solid var(--bs--color-grises-gris-20);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  padding: 8px;
  min-height: 80px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
@media only screen and (min-width : 600px) {
  .bs-tarjetas-estadisticas {
    max-width: 940px;
  }
}
.bs-tarjetas-estadisticas h3 {
  font-size: 2.2rem;
  font-family: "overpasslight", sans-serif;
  color: var(--bs--color-grises-gris-80);
  text-align: center;
  flex: 0 0 100%;
  line-height: 2;
}
.bs-tarjetas-estadisticas h2 {
  flex: 0 0 100%;
  font-family: "overpassbold", sans-serif;
  font-size: 1.5rem;
  color: var(--bs--color-principal-negro);
  line-height: 1.5;
  text-align: center;
}
.bs-tarjetas-estadisticas h2 em {
  display: block;
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-azul);
}
.bs-tarjetas-estadisticas figure {
  flex: 0 0 100%;
  text-align: center;
}
.bs-tarjetas-estadisticas figure img {
  height: 72px;
  width: auto;
}
.bs-tarjetas-estadisticas > span {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  display: inline-block;
  background-color: var(--bs--color-secundario-azul-40);
  margin: 12px auto;
}
.bs-tarjetas-estadisticas.destacado {
  background-color: var(--bs--color-principal-azul);
}
.bs-tarjetas-estadisticas.destacado figure img {
  filter: brightness(518%);
  -webkit-filter: brightness(518%);
}
.bs-tarjetas-estadisticas.destacado h2,
.bs-tarjetas-estadisticas.destacado h3 {
  color: var(--bs--color-principal-blanco);
}
.bs-tarjetas-estadisticas.destacado h2 {
  font-family: "overpassregular", sans-serif;
}
.bs-tarjetas-estadisticas.destacado h2 em {
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-blanco);
}

.bs-tarjetas-boton {
  padding: 8px;
  margin: 0 auto 12px;
  display: block;
  width: calc(100% - 16px);
  min-height: 20px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  -webkit-align-content: stretch;
  -moz-flex-direction: stretch;
  -ms-flex-direction: stretch;
  background-color: var(--bs--color-grises-gris-10);
  cursor: pointer;
}
@media only screen and (min-width : 600px) {
  .bs-tarjetas-boton {
    max-width: 340px;
  }
}
.bs-tarjetas-boton article {
  text-align: center;
  display: block;
  width: 100%;
  padding: 8px;
  min-height: 60px;
  background-color: var(--bs--color-principal-blanco);
}
.bs-tarjetas-boton article svg {
  display: inline-block;
  margin: 8px auto 0;
  fill: var(--bs--color-grises-gris-40);
  height: 12px;
  width: auto;
}
.bs-tarjetas-boton article figure {
  margin: 0 auto;
  text-align: center;
  display: block;
  height: auto;
  width: 100%;
}
.bs-tarjetas-boton article figure img {
  margin: 0 auto;
  width: auto;
  height: 48px;
}
.bs-tarjetas-boton article h2 {
  margin-top: 0px;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-principal-negro);
  line-height: 1.5;
}
.bs-tarjetas-boton article h2 em {
  color: var(--bs--color-principal-azul);
  font-family: "overpassbold", sans-serif;
}
.bs-tarjetas-boton:not(.destacado):hover article {
  background-color: var(--bs--color-grises-gris-10);
}
.bs-tarjetas-boton.destacado {
  background-color: var(--bs--color-principal-azul-hover);
}
.bs-tarjetas-boton.destacado figure {
  filter: brightness(518%);
  -webkit-filter: brightness(518%);
}
.bs-tarjetas-boton.destacado article {
  background-color: var(--bs--color-principal-azul);
}
.bs-tarjetas-boton.destacado article h2 {
  color: var(--bs--color-principal-blanco);
}
.bs-tarjetas-boton.destacado article h2 em {
  color: var(--bs--color-principal-blanco);
}
.bs-tarjetas-boton.destacado:hover article {
  background-color: var(--bs--color-principal-azul-hover);
}

.bs-modulo-carga-imagenes {
  outline: 2px dashed var(--bs--color-grises-gris-50);
  min-height: 172px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 4px 10px;
  width: calc(100% - 20px);
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  -webkit-align-content: stretch;
  -moz-flex-direction: stretch;
  -ms-flex-direction: stretch;
}
.bs-modulo-carga-imagenes .ver-imagenes {
  flex: 0 0 100% !important;
  height: 24px;
}
.bs-modulo-carga-imagenes .ver-imagenes a {
  color: var(--bs--color-grises-gris-50);
  cursor: pointer;
  width: 100%;
  line-height: 24px;
  text-align: center;
  font-size: 1.4rem;
  display: inline-block;
}
.bs-modulo-carga-imagenes .ver-imagenes a:hover {
  color: var(--bs--color-principal-azul);
}
.bs-modulo-carga-imagenes.invertido .cargas {
  order: 1;
}
.bs-modulo-carga-imagenes.invertido .informacion {
  order: 2;
  border: none;
  border-top: 1px solid var(--bs--color-grises-gris-20);
}
.bs-modulo-carga-imagenes.invertido .informacion span.tooltip .mensaje {
  bottom: 110%;
  top: inherit;
}
.bs-modulo-carga-imagenes.invertido .informacion span.tooltip .mensaje::after {
  content: "▼";
  bottom: -2px;
  top: inherit;
}
.bs-modulo-carga-imagenes .cargas {
  flex: 0 0 100%;
  min-height: 120px;
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
@media only screen and (min-width : 600px) {
  .bs-modulo-carga-imagenes .cargas {
    min-height: 150px;
  }
}
.bs-modulo-carga-imagenes .cargas .elemento-cargado {
  flex: 1;
  max-width: 84px;
  margin: 0 10px 0 0;
  position: relative;
}
.bs-modulo-carga-imagenes .cargas .elemento-cargado a.imagen {
  width: 100%;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  -ms-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}
.bs-modulo-carga-imagenes .cargas .elemento-cargado a.imagen figure {
  display: inline-block;
  width: 100%;
}
.bs-modulo-carga-imagenes .cargas .elemento-cargado a.imagen figure img {
  width: 100%;
  height: 72px;
  object-fit: cover;
}
.bs-modulo-carga-imagenes .cargas .elemento-cargado a.imagen:hover {
  opacity: 0.5;
}
.bs-modulo-carga-imagenes .cargas .elemento-cargado a.eliminar {
  height: 24px;
  width: 24px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: var(--bs--color-secundario-rojo) url(~src/assets/img/svg/icon-modulo-imagen-dell.svg) center center no-repeat;
  background-size: auto 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
}
.bs-modulo-carga-imagenes .cargas .elemento-cargado a.eliminar:hover {
  background-color: var(--bs--color-secundario-rojo-hover);
}
.bs-modulo-carga-imagenes .cargas a.carga {
  max-width: 124px;
  height: 130px;
  display: inline-block;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  cursor: pointer;
}
.bs-modulo-carga-imagenes .cargas a.carga:hover figure {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.bs-modulo-carga-imagenes .cargas a.carga:hover span {
  color: var(--bs--color-grises-gris-80);
}
.bs-modulo-carga-imagenes .cargas a.carga.full span {
  display: none;
}
.bs-modulo-carga-imagenes .cargas a.carga figure {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(~src/assets/img/svg/circle-sum.svg) center center no-repeat;
  background-size: 28px auto;
}
.bs-modulo-carga-imagenes .cargas a.carga span {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  text-align: center;
  font-size: 1.5rem;
  color: var(--bs--color-grises-gris-50);
  text-align: center;
}
.bs-modulo-carga-imagenes .informacion {
  border: none;
  border-bottom: 1px solid var(--bs--color-grises-gris-20);
  flex: 0 0 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  height: 50px;
}
.bs-modulo-carga-imagenes .informacion h4 {
  flex: 1;
  text-align: center;
  color: var(--bs--color-principal-azul);
  font-family: "overpassbold", sans-serif;
  padding-left: 25px;
  line-height: 1.4;
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.bs-modulo-carga-imagenes .informacion h4.no-padding {
  padding-left: 0;
}
.bs-modulo-carga-imagenes .informacion span.tooltip {
  height: 50px;
  width: 50px;
  display: inline-block;
  background: url(~src/assets/img/svg/tooltip-file.svg) center center no-repeat;
  background-size: 28px 28px;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.bs-modulo-carga-imagenes .informacion span.tooltip .mensaje {
  z-index: 401;
  font-family: "overpassbold", sans-serif;
  bottom: inherit;
  top: 110%;
  display: none;
  position: absolute;
  background-color: var(--bs--color-secundario-azul-40);
  color: var(--bs--color-principal-blanco);
  padding: 10px;
  font-size: 1.6rem;
  text-align: right;
  width: 170px;
  line-height: 1.4;
  right: 0px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
.bs-modulo-carga-imagenes .informacion span.tooltip .mensaje.uppercase {
  text-transform: uppercase;
}
.bs-modulo-carga-imagenes .informacion span.tooltip .mensaje::after {
  position: absolute;
  content: "▲";
  bottom: inherit;
  top: -20px;
  right: 26px;
  font-size: 2rem;
  color: var(--bs--color-secundario-azul-40);
  height: 10px;
  width: 10px;
}
.bs-modulo-carga-imagenes .informacion span.tooltip:hover .mensaje {
  display: block;
}

.bs-banner-header {
  width: 100%;
  display: block;
}
.bs-banner-header figure {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  display: block;
}
@media only screen and (min-width : 600px) {
  .bs-banner-header figure {
    position: static;
  }
}
.bs-banner-header figure img {
  width: 100%;
  display: block;
  height: 50px;
  object-fit: cover;
}
@media only screen and (min-width : 600px) {
  .bs-banner-header figure img {
    height: 80px;
  }
}
@media only screen and (min-width : 1000px) {
  .bs-banner-header figure img {
    height: 100px;
  }
}

.bs-banner-informativo {
  width: 100%;
  display: grid;
  height: auto;
  min-height: 20px;
  place-items: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.bs-banner-informativo section {
  width: calc(100% - 15px);
  padding: 20px 10px 20px 5px;
  height: auto;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  -webkit-align-content: stretch;
  -moz-flex-direction: stretch;
  -ms-flex-direction: stretch;
  filter: brightness(380%);
  -webkit-filter: brightness(380%);
}
@media only screen and (min-width : 600px) {
  .bs-banner-informativo section {
    width: calc(100% - 25px);
    padding: 20px 20px 20px 5px;
  }
}
.bs-banner-informativo section figure {
  width: 50px;
  display: grid;
  place-items: center;
}
.bs-banner-informativo section figure img {
  width: 48px;
}
@media only screen and (min-width : 600px) {
  .bs-banner-informativo section figure img {
    width: 64px;
  }
}
@media only screen and (min-width : 600px) {
  .bs-banner-informativo section figure {
    width: 80px;
  }
}
.bs-banner-informativo section p {
  flex: 1;
  font-size: var(--bs--font-size-p);
}
.bs-banner-informativo section p strong {
  display: block;
}

.bs-aplicacion-imagen-login {
  background-repeat: no-repeat;
  background-position: 100% center;
  background-size: auto 100%;
  width: 100%;
  position: relative;
}
@media only screen and (min-width : 992px) {
  .bs-aplicacion-imagen-login .bs-contenedor .image-holder figure {
    position: absolute;
    top: 0%;
    right: calc(50% + 40px);
    height: 100%;
    width: calc(50% - 40px);
  }
}
.bs-aplicacion-imagen-login .bs-contenedor .image-holder figure img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
@media only screen and (min-width : 992px) {
  .bs-aplicacion-imagen-login .bs-contenedor .image-holder figure img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100% !important;
    -webkit-border-radius: 0 2px 2px 0;
    -moz-border-radius: 0 2px 2px 0;
    -ms-border-radius: 0 2px 2px 0;
    border-radius: 0 2px 2px 0;
  }
}

input.p-inputtext {
  padding: 0;
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
  border: none;
  line-height: 46px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  font-size: 1.4rem;
  text-indent: 15px;
  margin: 0 0 6px 0;
  color: var(--bs--color-principal-negro);
  position: relative;
  width: 100%;
  font-family: "overpassbold", sans-serif;
  /*&.ng-invalid,*/
}
input.p-inputtext:focus {
  outline: 2px solid var(--bs--color-principal-negro) !important;
}
input.p-inputtext:focus + .bs-btn-form {
  outline-width: 2px;
}
input.p-inputtext.no-border {
  outline: none !important;
}
input.p-inputtext::-webkit-input-placeholder {
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassregular", sans-serif;
}
input.p-inputtext:-moz-placeholder {
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassregular", sans-serif;
}
input.p-inputtext::-moz-placeholder {
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassregular", sans-serif;
}
input.p-inputtext:-ms-input-placeholder {
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassregular", sans-serif;
}
.mix input.p-inputtext :not(.icon) {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.bs-color input.p-inputtext {
  outline: 1px solid var(--bs--color-validacion-verde-50) !important;
}
.bs-color input.p-inputtext.no-border {
  outline: none !important;
}
input.p-inputtext.is-invalid {
  /*outline: 2px solid var(--bs--color-secundario-rojo) !important;
  color: var(--bs--color-secundario-rojo) !important;
  font-family: $overPass_bold;*/
}
input.p-inputtext.is-invalid::-webkit-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
input.p-inputtext.is-invalid:-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
input.p-inputtext.is-invalid::-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
input.p-inputtext.is-invalid:-ms-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
input.p-inputtext.is-invalid:focus {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
}
input.p-inputtext.is-invalid + .bs-btn-form {
  outline-width: 2px;
}
input.p-inputtext.is-invalid.no-border {
  outline: none !important;
}
input.p-inputtext[disabled] {
  outline: 1px solid var(--bs--color-grises-gris-40) !important;
  cursor: not-allowed;
  color: rgba(172, 173, 172, 0.8);
  opacity: 1;
}
input.p-inputtext[disabled] + .bs-btn-form {
  margin-left: 2px;
}
input.p-inputtext[disabled].no-border {
  outline: none !important;
}
.bs-color input.p-inputtext[disabled] {
  background-color: var(--bs--color-principal-blanco);
  outline: 1px solid var(--bs--color-validacion-verde-10) !important;
}
.bs-color input.p-inputtext[disabled].no-border {
  outline: none !important;
}
.bs-color input.p-inputtext[disabled]::-webkit-input-placeholder {
  color: rgba(172, 173, 172, 0.4) !important;
}
.bs-color input.p-inputtext[disabled]:-moz-placeholder {
  color: rgba(172, 173, 172, 0.4) !important;
}
.bs-color input.p-inputtext[disabled]::-moz-placeholder {
  color: rgba(172, 173, 172, 0.4) !important;
}
.bs-color input.p-inputtext[disabled]:-ms-input-placeholder {
  color: rgba(172, 173, 172, 0.4) !important;
}
input.p-inputtext[disabled]::-webkit-input-placeholder {
  color: rgba(172, 173, 172, 0.8);
}
input.p-inputtext[disabled]:-moz-placeholder {
  color: rgba(172, 173, 172, 0.8);
}
input.p-inputtext[disabled]::-moz-placeholder {
  color: rgba(172, 173, 172, 0.8);
}
input.p-inputtext[disabled]:-ms-input-placeholder {
  color: rgba(172, 173, 172, 0.8);
}
input.p-inputtext[disabled] + .caption, input.p-inputtext[disabled] + .caption.error {
  color: rgba(172, 173, 172, 0.8);
}
.bs-color input.p-inputtext[disabled] + .caption, .bs-color input.p-inputtext[disabled] + .caption.error {
  opacity: 0.5;
  color: var(--bs--color-principal-blanco);
}

p-progressbar .p-progressbar {
  border: 0 none;
  height: 1.5rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background: var(--bs--color-grises-gris-40);
  height: 6px;
  margin-bottom: 6px;
}
p-progressbar .p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: var(--bs--color-principal-azul);
}
p-progressbar .p-progressbar .p-progressbar-label {
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassregular", sans-serif;
  line-height: 1.5rem;
}

p-calendar {
  width: 100%;
}
p-calendar .p-calendar {
  width: 100%;
}
p-calendar .p-calendar input.p-inputttext {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
p-calendar .p-calendar .p-button-icon-only {
  background: url(~src/assets/img/svg/icon-calendar.svg) center center no-repeat;
  width: 55px;
  height: 46px;
  border: none;
  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-100%, 0%);
  margin: 0;
  padding: 0;
  cursor: pointer;
}
p-calendar .p-calendar .p-button-icon-only:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
p-calendar .p-calendar .p-button-icon-only span {
  display: none;
}
p-calendar .p-calendar .p-button-icon-only:hover, p-calendar .p-calendar .p-button-icon-only:active {
  background: url(~src/assets/img/svg/icon-calendar.svg) center center no-repeat;
  opacity: 0.5;
}
p-calendar .p-calendar .p-button-icon-only[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
p-calendar.ng-invalid .p-button-icon-only, p-calendar.is-invalid .p-button-icon-only {
  background-image: url(~src/assets/img/svg/icon-calendar-red.svg);
}
p-calendar.ng-invalid input.p-inputttext, p-calendar.is-invalid input.p-inputttext {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
  color: var(--bs--color-secundario-rojo) !important;
  font-family: "overpassbold", sans-serif;
}
p-calendar.ng-invalid input.p-inputttext::-webkit-input-placeholder, p-calendar.is-invalid input.p-inputttext::-webkit-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
p-calendar.ng-invalid input.p-inputttext:-moz-placeholder, p-calendar.is-invalid input.p-inputttext:-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
p-calendar.ng-invalid input.p-inputttext::-moz-placeholder, p-calendar.is-invalid input.p-inputttext::-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
p-calendar.ng-invalid input.p-inputttext:-ms-input-placeholder, p-calendar.is-invalid input.p-inputttext:-ms-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
p-calendar.ng-invalid input.p-inputttext:focus, p-calendar.is-invalid input.p-inputttext:focus {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
}

.p-calendar-w-btn .p-inputtext {
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  border-radius: 4px !important;
  /*.ng-invalid &,*/
}
.is-invalid .p-calendar-w-btn .p-inputtext {
  /*outline: 2px solid var(--bs--color-secundario-rojo) !important;
  color: var(--bs--color-secundario-rojo) !important;
  font-family: $overPass_bold;*/
}
.is-invalid .p-calendar-w-btn .p-inputtext::-webkit-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-calendar-w-btn .p-inputtext:-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-calendar-w-btn .p-inputtext::-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-calendar-w-btn .p-inputtext:-ms-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-calendar-w-btn .p-inputtext:focus {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
}

.p-timepicker {
  padding: 4px 0;
}
.p-timepicker .p-separator {
  width: 10px;
}
.p-timepicker .p-ampm-picker > span,
.p-timepicker .p-second-picker > span,
.p-timepicker .p-minute-picker > span,
.p-timepicker .p-hour-picker > span {
  display: grid;
  place-items: center;
  place-content: center;
  font-family: "overpassbold", sans-serif;
  text-align: center;
  font-size: 1.4rem;
}
.p-timepicker .p-ampm-picker .p-link span:before,
.p-timepicker .p-second-picker .p-link span:before,
.p-timepicker .p-minute-picker .p-link span:before,
.p-timepicker .p-hour-picker .p-link span:before {
  display: none;
}
.p-timepicker .p-ampm-picker .p-link:first-child:hover span, .p-timepicker .p-ampm-picker .p-link:last-child:hover span,
.p-timepicker .p-second-picker .p-link:first-child:hover span,
.p-timepicker .p-second-picker .p-link:last-child:hover span,
.p-timepicker .p-minute-picker .p-link:first-child:hover span,
.p-timepicker .p-minute-picker .p-link:last-child:hover span,
.p-timepicker .p-hour-picker .p-link:first-child:hover span,
.p-timepicker .p-hour-picker .p-link:last-child:hover span {
  opacity: 0.5;
}
.p-timepicker .p-ampm-picker .p-link:first-child span, .p-timepicker .p-ampm-picker .p-link:last-child span,
.p-timepicker .p-second-picker .p-link:first-child span,
.p-timepicker .p-second-picker .p-link:last-child span,
.p-timepicker .p-minute-picker .p-link:first-child span,
.p-timepicker .p-minute-picker .p-link:last-child span,
.p-timepicker .p-hour-picker .p-link:first-child span,
.p-timepicker .p-hour-picker .p-link:last-child span {
  width: 32px;
  height: 20px;
}
.p-timepicker .p-ampm-picker .p-link:first-child span,
.p-timepicker .p-second-picker .p-link:first-child span,
.p-timepicker .p-minute-picker .p-link:first-child span,
.p-timepicker .p-hour-picker .p-link:first-child span {
  background: url(~src/assets/img/svg/hour-arrow-up.svg) center center no-repeat;
}
.p-timepicker .p-ampm-picker .p-link:last-child span,
.p-timepicker .p-second-picker .p-link:last-child span,
.p-timepicker .p-minute-picker .p-link:last-child span,
.p-timepicker .p-hour-picker .p-link:last-child span {
  background: url(~src/assets/img/svg/hour-arrow-down.svg) center center no-repeat;
}

.p-datepicker-group-container + .p-timepicker {
  border-top: 1px solid var(--bs--color-grises-gris-20);
  background-color: var(--bs--color-grises-gris-10);
}

.p-datepicker {
  -webkit-border-radius: 2px 2px 10px 10px;
  -moz-border-radius: 2px 2px 10px 10px;
  -ms-border-radius: 2px 2px 10px 10px;
  border-radius: 2px 2px 10px 10px;
  background-color: var(--bs--color-principal-blanco);
  border: 1px solid var(--bs--color-grises-gris-20);
}
.p-datepicker .p-datepicker-header {
  border: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 10px 0 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  min-width: 0px;
  width: auto;
  outline: none;
  min-width: 0px !important;
  flex: 1 !important;
  line-height: 46px;
  height: 36px;
  margin-bottom: 4px;
  margin-top: 4px;
  background: url(~src/assets/img/svg/arrow-button-down-green.svg) calc(100% - 4px) center no-repeat;
  border: 1px solid var(--bs--color-grises-gris-50);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  padding: 0.2rem;
  text-indent: 12px;
  font-size: 1.6rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select::before, .p-datepicker .p-datepicker-header .p-datepicker-title select::after {
  box-sizing: border-box;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select::-ms-expand {
  display: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select.p-datepicker-year {
  width: 80px !important;
  max-width: 80px !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select.p-datepicker-month {
  margin-right: 10px;
  width: 120px !important;
  max-width: 120px !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:not(select) {
  margin-right: 5px;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:not(select),
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:not(select),
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  font-size: 1.5rem;
  flex: 1;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:not(select):hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:not(select):hover,
.p-datepicker .p-datepicker-header .p-datepicker-title select:hover {
  color: var(--bs--color-principal-azul-hover) !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev {
  background: url(~src/assets/img/svg/arrow-button-back.svg) center center no-repeat;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:hover {
  opacity: 0.5;
  background: url(~src/assets/img/svg/arrow-button-back.svg) center center no-repeat !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-next {
  background: url(~src/assets/img/svg/arrow-button-secondary.svg) center center no-repeat;
}
.p-datepicker .p-datepicker-header .p-datepicker-next:hover {
  opacity: 0.5;
  background: url(~src/assets/img/svg/arrow-button-secondary.svg) center center no-repeat !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  height: 30px;
  width: 30px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev span,
.p-datepicker .p-datepicker-header .p-datepicker-next span {
  display: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.p-datepicker table td {
  padding: 0.3rem 0.5rem;
}
.p-datepicker table td span {
  width: 30px;
  height: 30px;
}
.p-datepicker table td > span:focus {
  box-shadow: none !important;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: var(--bs--color-grises-gris-50);
  color: var(--bs--color-principal-negro);
}
.p-datepicker:not(.p-disabled) table td.p-datepicker-today span:not(.p-highlight):not(.p-disabled):hover {
  background: #003253;
  color: var(--bs--color-principal-blanco);
}

.p-datepicker-calendar thead th {
  background-color: var(--bs--color-grises-gris-10);
  height: 30px;
  padding: 0.5rem;
}
.p-datepicker-calendar thead th span {
  font-size: 1.2rem;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-principal-negro);
  text-transform: uppercase;
}
.p-datepicker-calendar tbody tr td {
  font-size: 1.6rem;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-grises-gris-80);
  height: 20px;
}
.p-datepicker-calendar tbody tr td span {
  font-size: 1.6rem;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-grises-gris-80);
}
.p-datepicker-calendar tbody tr td span.p-highlight {
  background: var(--bs--color-principal-verde);
  color: white;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.p-datepicker-calendar tbody tr td span.p-disabled {
  opacity: 0.3;
}
.p-datepicker-calendar tbody tr td span:hover, .p-datepicker-calendar tbody tr td span:focus {
  background: var(--bs--color-principal-blanco) !important;
  color: var(--bs--color-principal-azul) !important;
  font-family: "overpassbold", sans-serif !important;
}
.p-datepicker-calendar tbody tr td.p-datepicker-today span {
  background: var(--bs--color-principal-azul);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  color: white;
}
.p-datepicker-calendar tbody tr td.p-datepicker-today span.p-highlight {
  background: var(--bs--color-principal-azul);
  color: var(--bs--color-principal-blanco);
}
.p-datepicker-calendar tbody tr td.p-datepicker-today span:hover, .p-datepicker-calendar tbody tr td.p-datepicker-today span:focus {
  background: var(--bs--color-principal-azul-hover);
  color: var(--bs--color-principal-blanco);
}

.p-dropdown {
  width: 100%;
  border: none;
  outline: none;
  line-height: 46px;
  background: var(--bs--color-principal-blanco);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  font-size: 1.4rem !important;
  text-indent: 15px;
  margin: 0 0 6px 0;
  color: var(--bs--color-principal-negro);
  position: relative;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
  /*.ng-invalid &,*/
}
.p-dropdown.p-dropdown-open {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.p-dropdown.p-dropdown-open .p-inputtext:not(.p-dropdown-filter) {
  font-family: "overpassbold", sans-serif !important;
  color: var(--bs--color-principal-negro) !important;
}
.p-dropdown .p-dropdown-empty-message {
  font-family: "overpassbold", sans-serif;
  font-size: 1.4rem;
  color: var(--bs--color-principal-negro) !important;
  line-height: 46px;
}
.p-dropdown .p-inputtext:not(.p-dropdown-filter) {
  outline: none !important;
  color: var(--bs--color-grises-gris-80) !important;
  font-family: "overpassregular", sans-serif !important;
}
.p-inputwrapper-filled .p-dropdown .p-inputtext:not(.p-dropdown-filter) {
  font-family: "overpassbold", sans-serif !important;
  color: var(--bs--color-principal-negro) !important;
}
.p-dropdown:not(.p-disabled):hover {
  outline: 1px solid var(--bs--color-principal-negro) !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #F4F4F4 !important;
  outline: 1px solid var(--bs--color-principal-negro) !important;
}
.p-dropdown .p-dropdown-label {
  font-size: 1.4rem !important;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  font-family: "overpassregular", sans-serif;
}
.p-dropdown .p-dropdown-open {
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
}
.p-dropdown .p-dropdown-trigger {
  width: 40px;
  background: url(~src/assets/img/svg/arrow-button-down-green.svg) center center no-repeat;
  background-size: 16px auto;
}
.p-dropdown .p-dropdown-trigger span {
  display: none;
}
.p-dropdown .p-dropdown-trigger:hover {
  opacity: 0.5;
}
.p-dropdown .p-placeholder {
  color: var(--bs--color-grises-gris-80) !important;
  font-family: "overpassregular", sans-serif !important;
}
.p-dropdown .p-dropdown-panel {
  background-color: var(--bs--color-principal-blanco);
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.p-dropdown .p-dropdown-item {
  font-size: 1.6rem;
  line-height: 46px;
  padding: 0 !important;
  margin: 0 !important;
  font-family: "overpassbold", sans-serif !important;
}
.p-dropdown .p-dropdown-item:hover {
  background-color: var(--bs--color-grises-gris-10) !important;
}
.p-dropdown .p-dropdown-item.p-highlight {
  background-color: var(--bs--color-grises-gris-20) !important;
}
.p-dropdown .p-dropdown-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: var(--bs--color-grises-gris-10);
}
.p-dropdown .p-dropdown-header .p-dropdown-filter-container {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
.p-dropdown .p-dropdown-header .p-dropdown-filter-container .p-dropdown-filter-icon {
  height: 46px;
  width: 30px;
  top: 0px;
  margin: 8px 0;
  right: 16px;
  background: url(~src/assets/img/svg/icon-search.svg) center center no-repeat;
}
.p-dropdown .p-dropdown-header .p-dropdown-filter-container .p-dropdown-filter-icon::before {
  display: none;
}
.p-dropdown .p-dropdown-header .p-dropdown-filter-container .p-dropdown-filter {
  flex: 1;
  margin: 8px !important;
  width: auto !important;
}
.is-invalid .p-dropdown {
  /*outline: 1px solid  var(--bs--color-secundario-rojo) !important;
  color:  var(--bs--color-secundario-rojo);*/
}
.is-invalid .p-dropdown::-webkit-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-dropdown:-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-dropdown::-moz-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-dropdown:-ms-input-placeholder {
  color: var(--bs--color-secundario-rojo);
}
.is-invalid .p-dropdown .p-placeholder {
  color: var(--bs--color-secundario-rojo) !important;
  font-family: "overpassbold", sans-serif !important;
}
.is-invalid .p-dropdown:hover {
  outline: 1px solid var(--bs--color-secundario-rojo-hover) !important;
}
.is-invalid .p-dropdown:focus {
  outline: 1px solid var(--bs--color-secundario-rojo) !important;
}
.is-invalid .p-dropdown .p-dropdown-trigger {
  background-image: url(~src/assets/img/svg/arrow-button-down-red.svg);
}
.p-dropdown.p-disabled {
  opacity: 1 !important;
  outline-color: var(--bs--color-grises-gris-40) !important;
  cursor: not-allowed !important;
}
.p-dropdown.p-disabled .p-dropdown-label {
  cursor: not-allowed !important;
}
.p-dropdown.p-disabled .p-inputtext:not(.p-dropdown-filter) {
  cursor: not-allowed;
  font-family: "overpassbold", sans-serif !important;
  color: var(--bs--color-grises-gris-50) !important;
}
.p-dropdown.p-disabled .p-dropdown-trigger {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
.p-dropdown.p-disabled .p-inputtext {
  cursor: not-allowed !important;
  color: rgba(172, 173, 172, 0.8) !important;
}

p-dropdown.p-inputwrapper-focus .p-dropdown {
  outline: 2px solid var(--bs--color-principal-negro) !important;
}

p-checkbox {
  margin-bottom: 16px;
  align-items: flex-start !important;
}
.bs-tabla-datos p-checkbox {
  margin-bottom: 0px;
}

.p-checkbox {
  margin: 0 10px 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.bs-tabla-datos .p-checkbox {
  margin: 0 10px 0 0;
}
.p-checkbox.p-checkbox-focused .p-checkbox-box {
  border: 1px solid var(--bs--color-grises-gris-80) !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.p-checkbox.p-checkbox-checked .p-checkbox-box {
  position: relative;
}
.p-checkbox.p-checkbox-checked .p-checkbox-box:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url(~src/assets/img/svg/check-button-blue.svg) center center no-repeat !important;
}
.p-checkbox .p-checkbox-box {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  height: 20px;
  width: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid var(--bs--color-grises-gris-80) !important;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  display: none;
}
.p-checkbox .p-checkbox-box:hover {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.p-checkbox .p-checkbox-box span {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.p-checkbox .p-checkbox-box:focus {
  border: 1px solid var(--bs--color-grises-gris-80) !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background-color: var(--bs--color-principal-blanco) !important;
  border-color: var(--bs--color-principal-negro) !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.p-checkbox .p-checkbox-box.p-highlight span {
  color: var(--bs--color-principal-negro);
  font-weight: 700;
  font-size: 1.2rem;
}
.p-checkbox .p-checkbox-box.p-highlight:hover {
  background: var(--bs--color-principal-negro);
  border-color: var(--bs--color-principal-negro);
}
.p-checkbox .p-checkbox-box.p-disabled {
  cursor: not-allowed;
  border-color: var(--bs--color-grises-gris-50) !important;
  opacity: 1 !important;
}
.p-checkbox .p-checkbox-box.p-disabled:after {
  opacity: 0.5 !important;
}
.ng-invalid .p-checkbox .p-checkbox-box, .is-invalid .p-checkbox .p-checkbox-box {
  border-color: var(--bs--color-validacion-rojo-50) !important;
}
.p-checkbox + .p-checkbox-label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-principal-negro);
  margin-left: 0 !important;
  line-height: 2rem;
}
.p-checkbox.p-disabled + .p-checkbox-label {
  cursor: not-allowed;
  color: var(--bs--color-grises-gris-80);
}

.p-multiselect .p-checkbox-box.p-highlight {
  position: relative;
}
.p-multiselect .p-checkbox-box.p-highlight:after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url(~src/assets/img/svg/check-button-blue-40.svg) center center no-repeat !important;
}

p-radiobutton {
  margin-bottom: 16px;
  align-items: flex-start !important;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
  margin: 0 10px 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.p-radiobutton.p-radiobutton-disabled + .p-radiobutton-label {
  color: var(--bs--color-grises-gris-80);
}
.p-radiobutton .p-radiobutton-box {
  border: 1px solid var(--bs--color-grises-gris-80);
  background: var(--bs--color-principal-blanco);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: var(--bs--color-principal-azul);
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 11px;
  height: 11px;
  transition-duration: 0.2s;
  background-color: var(--bs--color-principal-azul);
}
.p-radiobutton .p-radiobutton-box.p-disabled {
  border: 1px solid var(--bs--color-grises-gris-50);
}
.p-radiobutton .p-radiobutton-box.p-disabled .p-radiobutton-icon {
  opacity: 0.5;
  background-color: var(--bs--color-principal-azul);
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--bs--color-grises-gris-80);
  background: var(--bs--color-principal-blanco);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--bs--color-grises-gris-80);
  background: var(--bs--color-principal-blanco);
  color: var(--bs--color-principal-blanco);
}

p-radiobutton.ng-dirty.is-invalid > .p-radiobutton > .p-radiobutton-box, p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: var(--bs--color-validacion-rojo-50);
}
p-radiobutton.ng-dirty.is-invalid > .p-radiobutton > .p-radiobutton-box .p-radiobutton-icon, p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box .p-radiobutton-icon {
  background-color: var(--bs--color-secundario-rojo);
}
p-radiobutton.ng-dirty.is-invalid > .p-radiobutton > .p-radiobutton-box.p-highlight, p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box.p-highlight {
  border-color: var(--bs--color-validacion-rojo-50);
}
p-radiobutton.ng-dirty.is-invalid > .p-radiobutton > .p-radiobutton-box.p-highlight:not(.p-disabled):hover, p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--bs--color-validacion-rojo-50) !important;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: var(--bs--color-principal-azul);
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--bs--color-principal-azul);
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: var(--bs--color-principal-blanco);
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--bs--color-principal-azul);
}

.p-radiobutton-label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin-right: 16px;
  line-height: 2rem;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-principal-negro);
}

p-multiselect {
  width: 100%;
}
p-multiselect .p-multiselect {
  width: 100%;
  margin-bottom: 6px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: none;
  outline: none;
  line-height: 46px;
  background: var(--bs--color-principal-blanco);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
}
p-multiselect .p-multiselect .p-multiselect-filter-icon {
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 99%;
  transform: translate(-99%, -50%);
  height: 10px;
  width: 22px;
}
p-multiselect .p-multiselect .p-multiselect-token {
  background-color: var(--bs--color-principal-azul);
}
p-multiselect .p-multiselect .p-multiselect-token .p-multiselect-token-label {
  color: var(--bs--color-principal-blanco);
  font-family: "overpassbold", sans-serif;
}
p-multiselect .p-multiselect .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 10px;
  color: var(--bs--color-principal-blanco);
  height: 46px;
  width: 30px;
  top: 0px;
  margin: 8px 0;
  right: 16px;
  background: url(~src/assets/img/svg/icon-search.svg) center center no-repeat;
}
p-multiselect .p-multiselect .p-multiselect-token .p-multiselect-token-icon:before {
  display: none;
}
p-multiselect .p-multiselect .p-multiselect-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
p-multiselect .p-multiselect .p-multiselect-header .p-multiselect-filter-container {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
p-multiselect .p-multiselect .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  height: 46px;
  width: 40px;
  top: 58%;
  left: 98%;
  right: 20px;
  background: url(~src/assets/img/svg/icon-search.svg) center center no-repeat;
}
p-multiselect .p-multiselect .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon::before {
  display: none;
}
p-multiselect .p-multiselect .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter {
  position: relative;
  flex: 1;
  margin: 8px !important;
  width: auto !important;
}
p-multiselect .p-multiselect .p-multiselect-panel {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  background-color: var(--bs--color-principal-blanco);
}
p-multiselect .p-multiselect .p-multiselect-item {
  font-size: 1.6rem;
  line-height: 46px;
  padding: 0 !important;
  margin: 0 !important;
  font-family: "overpassbold", sans-serif !important;
}
p-multiselect .p-multiselect .p-multiselect-item:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
p-multiselect .p-multiselect .p-multiselect-item:hover {
  background: var(--bs--color-grises-gris-10) !important;
}
p-multiselect .p-multiselect .p-multiselect-item.p-highlight {
  background: var(--bs--color-grises-gris-20) !important;
}
p-multiselect .p-multiselect .p-multiselect-trigger {
  width: 40px;
  background: url(~src/assets/img/svg/arrow-button-down-green.svg) center center no-repeat;
  background-size: 16px auto;
}
p-multiselect .p-multiselect .p-multiselect-trigger span {
  display: none;
}
p-multiselect .p-multiselect .p-multiselect-header {
  background-color: var(--bs--color-grises-gris-10);
}
p-multiselect .p-multiselect .p-multiselect-header .p-multiselect-filter-container {
  width: 100%;
}
p-multiselect .p-multiselect .p-multiselect-empty-message {
  font-family: "overpassbold", sans-serif;
  color: #EF3742;
  font-size: 1.5rem;
}
p-multiselect .p-multiselect .p-multiselect-close {
  display: none;
}
p-multiselect .p-multiselect .p-multiselect-close span {
  display: none;
}
p-multiselect .p-multiselect .p-multiselect-label {
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: var(--bs--color-principal-negro) !important;
  font-size: 1.4rem !important;
  font-family: "overpassbold", sans-serif !important;
  text-indent: 16px;
}
p-multiselect .p-multiselect .p-multiselect-label.p-placeholder {
  font-family: "overpassregular", sans-serif !important;
  color: var(--bs--color-grises-gris-50) !important;
}
p-multiselect .p-multiselect .p-multiselect-item span:not(.p-checkbox-icon) {
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-grises-gris-50);
  font-size: 1.6rem;
  text-transform: none;
}
p-multiselect.p-inputwrapper-focus .p-multiselect {
  outline: 2px solid var(--bs--color-principal-negro) !important;
}
p-multiselect.ng-invalid .p-multiselect, p-multiselect.is-invalid .p-multiselect {
  outline: 2px solid var(--bs--color-secundario-rojo) !important;
}
p-multiselect.ng-invalid .p-multiselect .p-multiselect-label, p-multiselect.is-invalid .p-multiselect .p-multiselect-label {
  color: var(--bs--color-secundario-rojo) !important;
  font-family: "overpassbold", sans-serif !important;
}
p-multiselect.ng-invalid .p-multiselect .p-multiselect-label.p-placeholder, p-multiselect.is-invalid .p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--bs--color-secundario-rojo) !important;
}
p-multiselect.ng-invalid .p-multiselect .p-multiselect-trigger, p-multiselect.is-invalid .p-multiselect .p-multiselect-trigger {
  background-image: url(~src/assets/img/svg/arrow-button-down-red.svg);
}

.bs-modal.w-40 .p-dialog {
  width: 98%;
  max-width: 40px;
}
.bs-modal.w-50 .p-dialog {
  width: 98%;
  max-width: 50px;
}
.bs-modal.w-60 .p-dialog {
  width: 98%;
  max-width: 60px;
}
.bs-modal.w-70 .p-dialog {
  width: 98%;
  max-width: 70px;
}
.bs-modal.w-80 .p-dialog {
  width: 98%;
  max-width: 80px;
}
.bs-modal.w-90 .p-dialog {
  width: 98%;
  max-width: 90px;
}
.bs-modal.w-100 .p-dialog {
  width: 98%;
  max-width: 100px;
}
.bs-modal.w-110 .p-dialog {
  width: 98%;
  max-width: 110px;
}
.bs-modal.w-120 .p-dialog {
  width: 98%;
  max-width: 120px;
}
.bs-modal.w-130 .p-dialog {
  width: 98%;
  max-width: 130px;
}
.bs-modal.w-140 .p-dialog {
  width: 98%;
  max-width: 140px;
}
.bs-modal.w-150 .p-dialog {
  width: 98%;
  max-width: 150px;
}
.bs-modal.w-160 .p-dialog {
  width: 98%;
  max-width: 160px;
}
.bs-modal.w-170 .p-dialog {
  width: 98%;
  max-width: 170px;
}
.bs-modal.w-180 .p-dialog {
  width: 98%;
  max-width: 180px;
}
.bs-modal.w-190 .p-dialog {
  width: 98%;
  max-width: 190px;
}
.bs-modal.w-200 .p-dialog {
  width: 98%;
  max-width: 200px;
}
.bs-modal.w-210 .p-dialog {
  width: 98%;
  max-width: 210px;
}
.bs-modal.w-220 .p-dialog {
  width: 98%;
  max-width: 220px;
}
.bs-modal.w-230 .p-dialog {
  width: 98%;
  max-width: 230px;
}
.bs-modal.w-240 .p-dialog {
  width: 98%;
  max-width: 240px;
}
.bs-modal.w-250 .p-dialog {
  width: 98%;
  max-width: 250px;
}
.bs-modal.w-260 .p-dialog {
  width: 98%;
  max-width: 260px;
}
.bs-modal.w-270 .p-dialog {
  width: 98%;
  max-width: 270px;
}
.bs-modal.w-280 .p-dialog {
  width: 98%;
  max-width: 280px;
}
.bs-modal.w-290 .p-dialog {
  width: 98%;
  max-width: 290px;
}
.bs-modal.w-300 .p-dialog {
  width: 98%;
  max-width: 300px;
}
.bs-modal.w-310 .p-dialog {
  width: 98%;
  max-width: 310px;
}
.bs-modal.w-320 .p-dialog {
  width: 98%;
  max-width: 320px;
}
.bs-modal.w-330 .p-dialog {
  width: 98%;
  max-width: 330px;
}
.bs-modal.w-340 .p-dialog {
  width: 98%;
  max-width: 340px;
}
.bs-modal.w-350 .p-dialog {
  width: 98%;
  max-width: 350px;
}
.bs-modal.w-360 .p-dialog {
  width: 98%;
  max-width: 360px;
}
.bs-modal.w-370 .p-dialog {
  width: 98%;
  max-width: 370px;
}
.bs-modal.w-380 .p-dialog {
  width: 98%;
  max-width: 380px;
}
.bs-modal.w-390 .p-dialog {
  width: 98%;
  max-width: 390px;
}
.bs-modal.w-400 .p-dialog {
  width: 98%;
  max-width: 400px;
}
.bs-modal.w-410 .p-dialog {
  width: 98%;
  max-width: 410px;
}
.bs-modal.w-420 .p-dialog {
  width: 98%;
  max-width: 420px;
}
.bs-modal.w-430 .p-dialog {
  width: 98%;
  max-width: 430px;
}
.bs-modal.w-440 .p-dialog {
  width: 98%;
  max-width: 440px;
}
.bs-modal.w-450 .p-dialog {
  width: 98%;
  max-width: 450px;
}
.bs-modal.w-460 .p-dialog {
  width: 98%;
  max-width: 460px;
}
.bs-modal.w-470 .p-dialog {
  width: 98%;
  max-width: 470px;
}
.bs-modal.w-480 .p-dialog {
  width: 98%;
  max-width: 480px;
}
.bs-modal.w-490 .p-dialog {
  width: 98%;
  max-width: 490px;
}
.bs-modal.w-500 .p-dialog {
  width: 98%;
  max-width: 500px;
}
.bs-modal.w-510 .p-dialog {
  width: 98%;
  max-width: 510px;
}
.bs-modal.w-520 .p-dialog {
  width: 98%;
  max-width: 520px;
}
.bs-modal.w-530 .p-dialog {
  width: 98%;
  max-width: 530px;
}
.bs-modal.w-540 .p-dialog {
  width: 98%;
  max-width: 540px;
}
.bs-modal.w-550 .p-dialog {
  width: 98%;
  max-width: 550px;
}
.bs-modal.w-560 .p-dialog {
  width: 98%;
  max-width: 560px;
}
.bs-modal.w-570 .p-dialog {
  width: 98%;
  max-width: 570px;
}
.bs-modal.w-580 .p-dialog {
  width: 98%;
  max-width: 580px;
}
.bs-modal.w-590 .p-dialog {
  width: 98%;
  max-width: 590px;
}
.bs-modal.w-600 .p-dialog {
  width: 98%;
  max-width: 600px;
}
.bs-modal.w-610 .p-dialog {
  width: 98%;
  max-width: 610px;
}
.bs-modal.w-620 .p-dialog {
  width: 98%;
  max-width: 620px;
}
.bs-modal.w-630 .p-dialog {
  width: 98%;
  max-width: 630px;
}
.bs-modal.w-640 .p-dialog {
  width: 98%;
  max-width: 640px;
}
.bs-modal.w-650 .p-dialog {
  width: 98%;
  max-width: 650px;
}
.bs-modal.w-660 .p-dialog {
  width: 98%;
  max-width: 660px;
}
.bs-modal.w-670 .p-dialog {
  width: 98%;
  max-width: 670px;
}
.bs-modal.w-680 .p-dialog {
  width: 98%;
  max-width: 680px;
}
.bs-modal.w-690 .p-dialog {
  width: 98%;
  max-width: 690px;
}
.bs-modal.w-700 .p-dialog {
  width: 98%;
  max-width: 700px;
}
.bs-modal.w-710 .p-dialog {
  width: 98%;
  max-width: 710px;
}
.bs-modal.w-720 .p-dialog {
  width: 98%;
  max-width: 720px;
}
.bs-modal.w-730 .p-dialog {
  width: 98%;
  max-width: 730px;
}
.bs-modal.w-740 .p-dialog {
  width: 98%;
  max-width: 740px;
}
.bs-modal.w-750 .p-dialog {
  width: 98%;
  max-width: 750px;
}
.bs-modal.w-760 .p-dialog {
  width: 98%;
  max-width: 760px;
}
.bs-modal.w-770 .p-dialog {
  width: 98%;
  max-width: 770px;
}
.bs-modal.w-780 .p-dialog {
  width: 98%;
  max-width: 780px;
}
.bs-modal.w-790 .p-dialog {
  width: 98%;
  max-width: 790px;
}
.bs-modal.w-800 .p-dialog {
  width: 98%;
  max-width: 800px;
}
.bs-modal.w-810 .p-dialog {
  width: 98%;
  max-width: 810px;
}
.bs-modal.w-820 .p-dialog {
  width: 98%;
  max-width: 820px;
}
.bs-modal.w-830 .p-dialog {
  width: 98%;
  max-width: 830px;
}
.bs-modal.w-840 .p-dialog {
  width: 98%;
  max-width: 840px;
}
.bs-modal.w-850 .p-dialog {
  width: 98%;
  max-width: 850px;
}
.bs-modal.w-860 .p-dialog {
  width: 98%;
  max-width: 860px;
}
.bs-modal.w-870 .p-dialog {
  width: 98%;
  max-width: 870px;
}
.bs-modal.w-880 .p-dialog {
  width: 98%;
  max-width: 880px;
}
.bs-modal.w-890 .p-dialog {
  width: 98%;
  max-width: 890px;
}
.bs-modal.w-900 .p-dialog {
  width: 98%;
  max-width: 900px;
}
.bs-modal.w-910 .p-dialog {
  width: 98%;
  max-width: 910px;
}
.bs-modal.w-920 .p-dialog {
  width: 98%;
  max-width: 920px;
}
.bs-modal.w-930 .p-dialog {
  width: 98%;
  max-width: 930px;
}
.bs-modal.w-940 .p-dialog {
  width: 98%;
  max-width: 940px;
}
.bs-modal.w-950 .p-dialog {
  width: 98%;
  max-width: 950px;
}
.bs-modal.w-960 .p-dialog {
  width: 98%;
  max-width: 960px;
}
.bs-modal.w-970 .p-dialog {
  width: 98%;
  max-width: 970px;
}
.bs-modal.w-980 .p-dialog {
  width: 98%;
  max-width: 980px;
}
.bs-modal.w-990 .p-dialog {
  width: 98%;
  max-width: 990px;
}
.bs-modal.w-1000 .p-dialog {
  width: 98%;
  max-width: 1000px;
}
.bs-modal .p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}
.bs-modal .p-dialog {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
}
.bs-modal .p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 1.5rem;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  align-items: flex-start;
}
.bs-modal .p-dialog .p-dialog-header h1,
.bs-modal .p-dialog .p-dialog-header h2,
.bs-modal .p-dialog .p-dialog-header h3,
.bs-modal .p-dialog .p-dialog-header h4,
.bs-modal .p-dialog .p-dialog-header h5 {
  width: 100%;
  padding: 15px 0;
}
@media only screen and (min-width : 600px) {
  .bs-modal .p-dialog .p-dialog-header h1,
.bs-modal .p-dialog .p-dialog-header h2,
.bs-modal .p-dialog .p-dialog-header h3,
.bs-modal .p-dialog .p-dialog-header h4,
.bs-modal .p-dialog .p-dialog-header h5 {
    padding: 30px 0;
  }
}
.bs-modal .p-dialog .p-dialog-header h1 figure,
.bs-modal .p-dialog .p-dialog-header h2 figure,
.bs-modal .p-dialog .p-dialog-header h3 figure,
.bs-modal .p-dialog .p-dialog-header h4 figure,
.bs-modal .p-dialog .p-dialog-header h5 figure {
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}
.bs-modal .p-dialog .p-dialog-header .p-dialog-header-icons {
  position: absolute;
  top: 10px;
  right: 10px;
}
.bs-modal .p-dialog .p-dialog-header .p-dialog-header-icons .p-dialog-header-close {
  height: 24px;
  width: 24px;
  background: url(~src/assets/img/svg/delete-button-blue.svg) center center no-repeat;
}
.bs-modal .p-dialog .p-dialog-header .p-dialog-header-icons .p-dialog-header-close .p-dialog-header-close-icon::before {
  display: none;
}
.bs-modal .p-dialog .p-dialog-content {
  background: var(--bs--color-principal-blanco);
  padding: 0 20px;
}
@media only screen and (min-width : 600px) {
  .bs-modal .p-dialog .p-dialog-content {
    padding: 0 30px;
  }
}
@media only screen and (min-width : 1000px) {
  .bs-modal .p-dialog .p-dialog-content {
    padding: 0 60px;
  }
}
.bs-modal .p-dialog .p-dialog-content .simple {
  color: var(--bs--color-grises-gris-50);
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-modal .p-dialog .p-dialog-content .simple ~ .simple {
  padding-top: 10px;
  padding-bottom: 20px;
}
.bs-modal .p-dialog .dummy {
  width: 20px;
  color: var(--bs--color-grises-gris-40);
  text-align: center;
}
.bs-modal .p-dialog .p-dialog-footer {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  background: var(--bs--color-principal-blanco);
  color: var(--bs--color-principal-negro);
  padding: 1rem 16px;
  position: relative;
}
.bs-modal .p-dialog .p-dialog-footer .simple {
  color: var(--bs--color-grises-gris-50);
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-modal .p-dialog .p-dialog-footer .simple ~ .simple {
  padding-top: 10px;
  padding-bottom: 20px;
}
.bs-modal .p-dialog .p-dialog-footer .extremos {
  width: 100%;
  padding: 10px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-modal .p-dialog .p-dialog-footer .derecha {
  width: 100%;
  padding: 10px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-modal .p-dialog .p-dialog-footer .izquierda {
  width: 100%;
  padding: 10px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-modal .p-dialog .p-dialog-footer .centrar {
  width: 100%;
  padding: 10px;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-modal .p-dialog .bs-modulo-ayuda {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: var(--bs--color-grises-gris-10);
}
.bs-modal .p-dialog .bs-modulo-ayuda p,
.bs-modal .p-dialog .bs-modulo-ayuda h5 {
  text-align: center;
  line-height: 1.6;
}
.bs-modal .p-dialog .bs-modulo-ayuda p {
  font-size: 1.7rem;
}
.bs-modal.busqueda-avanzada .p-dialog {
  max-height: calc(100% - 1.5rem);
}
.bs-modal.busqueda-avanzada .p-dialog .p-dialog-content {
  padding: 0 16px;
}
.bs-modal.busqueda-avanzada .p-dialog .p-dialog-content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.bs-modal.busqueda-avanzada .p-dialog .p-dialog-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
.bs-modal.busqueda-avanzada .p-dialog .p-dialog-content::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-grises-gris-50);
  cursor: pointer;
}
.bs-modal.busqueda-avanzada .p-dialog-header {
  padding-top: 2px;
  padding-bottom: 2px;
}
@media only screen and (min-width : 600px) {
  .bs-modal.busqueda-avanzada .p-dialog-header {
    padding-top: 48px;
  }
}
.bs-modal.busqueda-avanzada .p-dialog-header .p-dialog-header-icons {
  top: 8px;
}
.bs-modal.busqueda-avanzada .p-dialog-header h1,
.bs-modal.busqueda-avanzada .p-dialog-header h2,
.bs-modal.busqueda-avanzada .p-dialog-header h3,
.bs-modal.busqueda-avanzada .p-dialog-header h4,
.bs-modal.busqueda-avanzada .p-dialog-header h5 {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
}
@media only screen and (min-width : 600px) {
  .bs-modal.busqueda-avanzada .p-dialog-header h1,
.bs-modal.busqueda-avanzada .p-dialog-header h2,
.bs-modal.busqueda-avanzada .p-dialog-header h3,
.bs-modal.busqueda-avanzada .p-dialog-header h4,
.bs-modal.busqueda-avanzada .p-dialog-header h5 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.bs-modal.compacto .p-dialog-content {
  padding: 0;
}
.bs-modal.compacto .p-dialog-header {
  padding-top: 2px;
  padding-bottom: 2px;
}
.bs-modal.compacto .p-dialog-header .p-dialog-header-icons {
  top: 8px;
}
.bs-modal.compacto .p-dialog-header h1,
.bs-modal.compacto .p-dialog-header h2,
.bs-modal.compacto .p-dialog-header h3,
.bs-modal.compacto .p-dialog-header h4,
.bs-modal.compacto .p-dialog-header h5 {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
}
@media only screen and (min-width : 600px) {
  .bs-modal.compacto .p-dialog-header h1,
.bs-modal.compacto .p-dialog-header h2,
.bs-modal.compacto .p-dialog-header h3,
.bs-modal.compacto .p-dialog-header h4,
.bs-modal.compacto .p-dialog-header h5 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.bs-modal.bs-dividido .p-dialog .p-dialog-header {
  height: 0;
  padding: 0;
}
.bs-modal.bs-dividido .p-dialog .p-dialog-content {
  padding: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  display: block;
}
@media only screen and (min-width : 600px) {
  .bs-modal.bs-dividido .p-dialog .p-dialog-content {
    display: -ms-grid;
    display: -webkit-inline-grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0px;
    row-gap: 0px;
    min-height: none;
  }
}
.bs-modal.bs-dividido .p-dialog .p-dialog-content .dialog-column {
  flex: 0 0 50%;
  padding: 20px;
  height: 100%;
  display: block;
}
.bs-modal.bs-dividido .p-dialog .p-dialog-content .dialog-column.background.gris-10 {
  background: var(--bs--color-grises-gris-10);
}
.bs-modal.bs-dividido .p-dialog .p-dialog-content .dialog-column.background.gris-20 {
  background: var(--bs--color-grises-gris-20);
}
.bs-modal.bs-dividido .p-dialog .p-dialog-content .dialog-column.background.gris-40 {
  background: var(--bs--color-grises-gris-40);
}

.bs-modulo-dialog-imagen-detalle {
  width: 100%;
  min-height: 50px;
  display: block;
  position: relative;
  -webkit-transition: height 0.1s linear;
  -moz-transition: height 0.1s linear;
  -ms-transition: height 0.1s linear;
  -o-transition: height 0.1s linear;
  transition: height 0.1s linear;
}
@media only screen and (min-width : 600px) {
  .bs-modulo-dialog-imagen-detalle {
    min-height: 50px;
  }
}
@media only screen and (min-width : 1000px) {
  .bs-modulo-dialog-imagen-detalle {
    min-height: 50px;
  }
}
.bs-modulo-dialog-imagen-detalle img {
  /*
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  object-fit: cover;*/
  width: calc(100% - 20px);
  height: auto;
  margin: 10px;
}

.bs-modulo-lista-tabla-datos {
  width: 100%;
  padding: 16px 16px 4px;
}
.bs-modulo-lista-tabla-datos .modulo {
  width: 100%;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
  min-height: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid var(--bs--color-grises-gris-20);
  border-bottom: 1px solid var(--bs--color-grises-gris-20);
}
@media only screen and (min-width : 600px) {
  .bs-modulo-lista-tabla-datos .modulo {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.bs-modulo-lista-tabla-datos .modulo:last-child {
  border-bottom: none;
}
.bs-modulo-lista-tabla-datos .modulo figure {
  flex: 1 0 100%;
  max-width: 75px;
  margin-right: 12px;
}
.bs-modulo-lista-tabla-datos .modulo figure img {
  width: 100%;
  height: 75px;
  object-fit: cover;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
.bs-modulo-lista-tabla-datos .modulo figcaption {
  flex: 1;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  -moz-flex-direction: flex-start;
  -ms-flex-direction: flex-start;
}
.bs-modulo-lista-tabla-datos .modulo figcaption .bs-simple-data {
  margin-bottom: 8px;
  display: inline;
  width: auto;
}
.bs-modulo-lista-tabla-datos .modulo figcaption .bs-simple-data:first-child {
  width: 100%;
}
@media only screen and (min-width : 600px) {
  .bs-modulo-lista-tabla-datos .modulo figcaption .bs-simple-data:first-child {
    width: auto;
  }
}
.bs-modulo-lista-tabla-datos .modulo figcaption a {
  flex: 0 0 100%;
  text-align: left;
}

.bs-page-loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
  animation-name: fadeIn;
  animation-duration: 0.1s;
}
.bs-page-loader p {
  flex: 0 0 100%;
  text-align: center;
  line-height: 4.8rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bs-loader-html {
  display: block;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  margin: 0 auto;
}
.bs-loader-html.inline-block {
  display: inline-block;
}
.bs-loader-html::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid rgba(244, 244, 244, 0.4);
  border-top: 3px solid var(--bs--color-principal-azul);
}
.bs-loader-html.w-4 {
  height: 4px;
  width: 4px;
}
.bs-loader-html.w-8 {
  height: 8px;
  width: 8px;
}
.bs-loader-html.w-12 {
  height: 12px;
  width: 12px;
}
.bs-loader-html.w-16 {
  height: 16px;
  width: 16px;
}
.bs-loader-html.w-20 {
  height: 20px;
  width: 20px;
}
.bs-loader-html.w-24 {
  height: 24px;
  width: 24px;
}
.bs-loader-html.w-28 {
  height: 28px;
  width: 28px;
}
.bs-loader-html.w-32 {
  height: 32px;
  width: 32px;
}
.bs-loader-html.w-36 {
  height: 36px;
  width: 36px;
}
.bs-loader-html.w-40 {
  height: 40px;
  width: 40px;
}
.bs-loader-html.w-44 {
  height: 44px;
  width: 44px;
}
.bs-loader-html.w-48 {
  height: 48px;
  width: 48px;
}
.bs-loader-html.w-52 {
  height: 52px;
  width: 52px;
}
.bs-loader-html.w-56 {
  height: 56px;
  width: 56px;
}
.bs-loader-html.w-60 {
  height: 60px;
  width: 60px;
}
.bs-loader-html.w-64 {
  height: 64px;
  width: 64px;
}
.bs-loader-html.w-68 {
  height: 68px;
  width: 68px;
}
.bs-loader-html.w-72 {
  height: 72px;
  width: 72px;
}
.bs-loader-html.w-76 {
  height: 76px;
  width: 76px;
}
.bs-loader-html.w-80 {
  height: 80px;
  width: 80px;
}
.bs-loader-html.w-84 {
  height: 84px;
  width: 84px;
}
.bs-loader-html.w-88 {
  height: 88px;
  width: 88px;
}
.bs-loader-html.w-92 {
  height: 92px;
  width: 92px;
}
.bs-loader-html.w-96 {
  height: 96px;
  width: 96px;
}
.bs-loader-html.w-100 {
  height: 100px;
  width: 100px;
}
.bs-loader-html.w-104 {
  height: 104px;
  width: 104px;
}
.bs-loader-html.w-108 {
  height: 108px;
  width: 108px;
}
.bs-loader-html.w-112 {
  height: 112px;
  width: 112px;
}
.bs-loader-html.w-116 {
  height: 116px;
  width: 116px;
}
.bs-loader-html.w-120 {
  height: 120px;
  width: 120px;
}
.bs-loader-html.w-124 {
  height: 124px;
  width: 124px;
}
.bs-loader-html.w-128 {
  height: 128px;
  width: 128px;
}
.bs-loader-html.w-132 {
  height: 132px;
  width: 132px;
}
.bs-loader-html.w-136 {
  height: 136px;
  width: 136px;
}
.bs-loader-html.w-140 {
  height: 140px;
  width: 140px;
}
.bs-loader-html.w-144 {
  height: 144px;
  width: 144px;
}
.bs-loader-html.w-148 {
  height: 148px;
  width: 148px;
}
.bs-loader-html.w-152 {
  height: 152px;
  width: 152px;
}
.bs-loader-html.w-156 {
  height: 156px;
  width: 156px;
}
.bs-loader-html.w-160 {
  height: 160px;
  width: 160px;
}
.bs-loader-html.w-164 {
  height: 164px;
  width: 164px;
}
.bs-loader-html.w-168 {
  height: 168px;
  width: 168px;
}
.bs-loader-html.w-172 {
  height: 172px;
  width: 172px;
}
.bs-loader-html.w-176 {
  height: 176px;
  width: 176px;
}
.bs-loader-html.w-180 {
  height: 180px;
  width: 180px;
}
.bs-loader-html.w-184 {
  height: 184px;
  width: 184px;
}
.bs-loader-html.w-188 {
  height: 188px;
  width: 188px;
}
.bs-loader-html.w-192 {
  height: 192px;
  width: 192px;
}
.bs-loader-html.w-196 {
  height: 196px;
  width: 196px;
}
.bs-loader-html.w-200 {
  height: 200px;
  width: 200px;
}
.bs-loader-html.w-204 {
  height: 204px;
  width: 204px;
}
.bs-loader-html.w-208 {
  height: 208px;
  width: 208px;
}
.bs-loader-html.w-212 {
  height: 212px;
  width: 212px;
}
.bs-loader-html.w-216 {
  height: 216px;
  width: 216px;
}
.bs-loader-html.w-220 {
  height: 220px;
  width: 220px;
}
.bs-loader-html.w-224 {
  height: 224px;
  width: 224px;
}
.bs-loader-html.w-228 {
  height: 228px;
  width: 228px;
}
.bs-loader-html.w-232 {
  height: 232px;
  width: 232px;
}
.bs-loader-html.w-236 {
  height: 236px;
  width: 236px;
}
.bs-loader-html.w-240 {
  height: 240px;
  width: 240px;
}
.bs-loader-html.w-244 {
  height: 244px;
  width: 244px;
}
.bs-loader-html.w-248 {
  height: 248px;
  width: 248px;
}
.bs-loader-html.w-252 {
  height: 252px;
  width: 252px;
}
.bs-loader-html.w-256 {
  height: 256px;
  width: 256px;
}
.bs-loader-html.w-260 {
  height: 260px;
  width: 260px;
}
.bs-loader-html.w-264 {
  height: 264px;
  width: 264px;
}
.bs-loader-html.w-268 {
  height: 268px;
  width: 268px;
}
.bs-loader-html.w-272 {
  height: 272px;
  width: 272px;
}
.bs-loader-html.w-276 {
  height: 276px;
  width: 276px;
}
.bs-loader-html.w-280 {
  height: 280px;
  width: 280px;
}
.bs-loader-html.w-284 {
  height: 284px;
  width: 284px;
}
.bs-loader-html.w-288 {
  height: 288px;
  width: 288px;
}
.bs-loader-html.w-292 {
  height: 292px;
  width: 292px;
}
.bs-loader-html.w-296 {
  height: 296px;
  width: 296px;
}
.bs-loader-html.w-300 {
  height: 300px;
  width: 300px;
}
.bs-loader-html.w-304 {
  height: 304px;
  width: 304px;
}
.bs-loader-html.w-308 {
  height: 308px;
  width: 308px;
}
.bs-loader-html.w-312 {
  height: 312px;
  width: 312px;
}
.bs-loader-html.w-316 {
  height: 316px;
  width: 316px;
}
.bs-loader-html.w-320 {
  height: 320px;
  width: 320px;
}
.bs-loader-html.w-324 {
  height: 324px;
  width: 324px;
}
.bs-loader-html.w-328 {
  height: 328px;
  width: 328px;
}
.bs-loader-html.w-332 {
  height: 332px;
  width: 332px;
}
.bs-loader-html.w-336 {
  height: 336px;
  width: 336px;
}
.bs-loader-html.w-340 {
  height: 340px;
  width: 340px;
}
.bs-loader-html.w-344 {
  height: 344px;
  width: 344px;
}
.bs-loader-html.w-348 {
  height: 348px;
  width: 348px;
}
.bs-loader-html.w-352 {
  height: 352px;
  width: 352px;
}
.bs-loader-html.w-356 {
  height: 356px;
  width: 356px;
}
.bs-loader-html.w-360 {
  height: 360px;
  width: 360px;
}
.bs-loader-html.w-364 {
  height: 364px;
  width: 364px;
}
.bs-loader-html.w-368 {
  height: 368px;
  width: 368px;
}
.bs-loader-html.w-372 {
  height: 372px;
  width: 372px;
}
.bs-loader-html.w-376 {
  height: 376px;
  width: 376px;
}
.bs-loader-html.w-380 {
  height: 380px;
  width: 380px;
}
.bs-loader-html.w-384 {
  height: 384px;
  width: 384px;
}
.bs-loader-html.w-388 {
  height: 388px;
  width: 388px;
}
.bs-loader-html.w-392 {
  height: 392px;
  width: 392px;
}
.bs-loader-html.w-396 {
  height: 396px;
  width: 396px;
}
.bs-loader-html.w-400 {
  height: 400px;
  width: 400px;
}
.bs-loader-html.w-404 {
  height: 404px;
  width: 404px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.bs-loader-container {
  width: auto;
  height: auto;
  display: block;
}

.bs-pestanas {
  width: 100%;
  display: block;
}

.p-tabview .p-tabview-panels {
  padding: 1rem 0px;
  border: 0 none;
}
.p-tabview .p-tabview-nav {
  background: none;
  flex-wrap: nowrap;
  width: 100%;
  overflow: auto hidden;
  padding-bottom: 2px;
}
.p-tabview .p-tabview-nav::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.p-tabview .p-tabview-nav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
.p-tabview .p-tabview-nav::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
@media only screen and (min-width : 600px) {
  .p-tabview .p-tabview-nav {
    width: 100%;
    flex-wrap: wrap;
  }
}
.p-tabview .p-tabview-nav li {
  margin-right: 4px;
}
@media only screen and (min-width : 600px) {
  .p-tabview .p-tabview-nav li {
    margin-right: 8px;
  }
}
@media only screen and (min-width : 1000px) {
  .p-tabview .p-tabview-nav li {
    margin-right: 16px;
  }
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  border-color: #6c757d;
  color: var(--bs--color-principal-negro);
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid rgba(0, 0, 0, 0);
  border-width: 0 0 3px 0;
  border-color: transparent transparent rgba(0, 0, 0, 0) transparent;
  background: none;
  color: #6c757d;
  padding: 1rem;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
  text-transform: uppercase;
  font-size: clamp(1.3rem, 1vw + 0.3rem, 1.5rem);
  font-family: "overpassbold", sans-serif;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link span {
  white-space: nowrap;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: var(--bs--color-principal-azul);
  color: var(--bs--color-principal-azul);
  font-size: clamp(1.3rem, 1vw + 0.3rem, 1.5rem);
}

.bs-tabla-datos {
  width: 100%;
  overflow: auto hidden;
}
.bs-tabla-datos::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.bs-tabla-datos::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
.bs-tabla-datos::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-grises-gris-50);
  cursor: pointer;
}
.bs-tabla-datos p-datatable {
  width: 100%;
}

.bs-tabla-panel {
  width: 100%;
  padding-right: 1rem;
  margin-left: auto;
  display: -ms-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  -webkit-align-content: center;
  -moz-flex-direction: center;
  -ms-flex-direction: center;
}
.bs-tabla-panel span.label {
  flex: 1;
}

.p-datatable {
  width: 100%;
}
.p-datatable .p-datatable-wrapper {
  margin-bottom: 16px;
}
.p-datatable .p-datatable-wrapper table {
  min-width: 1100px;
  background: var(--bs--color-grises-gris-10);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th {
  position: relative;
  padding: 1rem;
  min-height: 2.4rem;
  font-size: 1.3rem;
  font-family: "overpassbold", sans-serif;
  text-transform: uppercase;
  text-align: left;
  color: var(--bs--color-principal-negro);
  vertical-align: middle;
  border: 4px solid var(--bs--color-grises-gris-20);
  border-top: none !important;
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .bs-btn {
  position: absolute;
  top: 50%;
  left: 97%;
  transform: translate(-97%, -50%);
  min-width: max-content;
  white-space: nowrap;
  width: auto;
  padding-right: 16px;
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th[colspan="2"], .p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th[colspan="3"], .p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th[colspan="4"], .p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th[colspan="5"] {
  text-align: center;
  border-top: none !important;
  border-bottom: none !important;
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th span:not(.arrow) {
  display: inline-block;
  margin-right: auto;
  max-width: calc(100% - 30px);
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .p-sortable-column-icon {
  position: absolute;
  top: calc(50% - 10px);
  right: 10px;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url(~src/assets/img/svg/icon-table-sort-mixto.svg);
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .p-sortable-column-icon::before {
  display: none;
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .p-sortable-column-icon.pi-sort-alt {
  background-image: url(~src/assets/img/svg/icon-table-sort-mixto.svg);
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .p-sortable-column-icon.pi-sort-amount-up-alt {
  background-image: url(~src/assets/img/svg/icon-table-sort-up.svg);
}
.p-datatable .p-datatable-wrapper table thead.p-datatable-thead tr th .p-sortable-column-icon.pi-sort-amount-down {
  background-image: url(~src/assets/img/svg/icon-table-sort-down.svg);
}
.p-datatable .p-datatable-wrapper table tbody.p-datatable-tbody tr {
  background-color: var(--bs--color-principal-blanco);
}
.p-datatable .p-datatable-wrapper table tbody.p-datatable-tbody tr:nth-child(even) {
  background-color: white;
}
.p-datatable .p-datatable-wrapper table tbody.p-datatable-tbody tr td {
  min-height: 48px;
  height: auto;
  padding: 1rem;
  vertical-align: middle;
  text-align: left;
  border: 4px solid var(--bs--color-grises-gris-20);
  font-size: 1.5rem;
  color: var(--bs--color-principal-negro);
  font-family: "overpassregular", sans-serif;
  word-wrap: break-word;
}

.p-paginator {
  padding: 0;
  margin: 10px;
}
.p-paginator .p-paginator-pages {
  margin: 0 10px;
}
.p-paginator .p-paginator-pages .p-paginator-element {
  height: 32px;
  background-color: var(--bs--color-principal-blanco) !important;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-grises-gris-80);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  font-size: 1.5rem;
}
@media only screen and (min-width : 600px) {
  .p-paginator .p-paginator-pages .p-paginator-page {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.p-paginator .p-paginator-pages .p-paginator-page:hover {
  font-family: "overpassbold", sans-serif;
  color: var(--bs--color-principal-verde-hover) !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--bs--color-principal-verde) !important;
  border-color: var(--bs--color-principal-verde) !important;
  color: var(--bs--color-principal-blanco);
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:hover {
  font-family: "overpassregular", sans-serif;
  color: var(--bs--color-principal-blanco) !important;
}
.p-paginator .p-paginator-current {
  margin: 10px;
  flex: 0 0 100%;
  color: var(--bs--color-grises-gris-80);
  font-family: "overpassbold", sans-serif;
}
@media only screen and (min-width : 600px) {
  .p-paginator .p-paginator-current {
    flex: 1;
  }
}
.p-paginator .p-paginator-element {
  height: 32px;
  background: none;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px auto;
  width: 20px;
}
.p-paginator .p-paginator-element.p-disabled {
  opacity: 0.5;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.p-paginator .p-paginator-element .p-paginator-icon:before {
  display: none;
}
.p-paginator .p-paginator-element.p-paginator-next {
  background-image: url(~src/assets/img/svg/icon-paginator-next.svg);
}
.p-paginator .p-paginator-element.p-paginator-prev {
  background-image: url(~src/assets/img/svg/icon-paginator-prev.svg);
}
.p-paginator .p-paginator-element.p-paginator-last {
  background-image: url(~src/assets/img/svg/icon-paginator-last.svg);
}
.p-paginator .p-paginator-element.p-paginator-first {
  background-image: url(~src/assets/img/svg/icon-paginator-first.svg);
}
.p-paginator .p-paginator-element.p-paginator-prev, .p-paginator .p-paginator-element.p-paginator-next {
  width: 40px !important;
}
@media only screen and (min-width : 600px) {
  .p-paginator .p-paginator-element.p-paginator-prev, .p-paginator .p-paginator-element.p-paginator-next {
    width: 80px !important;
  }
}

p-accordion .bs-form-accordion .bs-bloque {
  margin: 0 2px;
  width: calc(100% - 4px);
}
p-accordion .bs-form-accordion p-accordiontab .p-accordion-tab {
  border-bottom: 1px solid var(--bs--color-grises-gris-40);
}
p-accordion .bs-form-accordion p-accordiontab:first-child .p-accordion-tab {
  border-top: 1px solid var(--bs--color-grises-gris-40);
}
p-accordion .bs-form-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  width: 100%;
  height: 44px;
}
@media only screen and (min-width : 600px) {
  p-accordion .bs-form-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    height: 64px;
  }
}
p-accordion .bs-form-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link .p-accordion-header-text {
  font-size: var(--bs--font-size-h3) !important;
  font-family: "overpassbold", sans-serif !important;
  flex: 1;
  order: 1;
}
p-accordion .bs-form-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  order: 2;
  background: url(~src/assets/img/svg/icon-ui-nav-flecha-abajo.svg) center center no-repeat;
  width: 20px;
  height: 20px;
}
p-accordion .bs-form-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon::before {
  display: none;
}
p-accordion .bs-form-accordion .p-accordion-tab.p-accordion-tab-active .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  background-image: url(~src/assets/img/svg/icon-ui-nav-flecha-arriba.svg);
  opacity: 0.7;
}

html {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "overpassregular", sans-serif;
  font-size: var(--bs--font-size-p);
  color: var(--bs--color-principal-negro);
  margin: 0;
  padding: 0;
  height: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--bs--color-grises-gris-10);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--bs--color-grises-gris-50);
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled input,
.disabled select {
  cursor: not-allowed;
}

.no-visible {
  visibility: hidden;
}

.p-dialog-content {
  overflow-y: visible !important;
}

.p-datatable table {
  min-width: 0px !important;
}

input.p-inputtext[readonly] {
  outline: 1px solid var(--bs--color-grises-gris-40) !important;
  cursor: not-allowed;
  color: rgba(172, 173, 172, 0.8);
  opacity: 1;
}