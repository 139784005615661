.bs-aplicacion-imagen-login {
  background-repeat: no-repeat;
  background-position: 100% center;
  background-size: auto 100%;
  width: 100%;
  position: relative;
  .bs-contenedor {
    .image-holder {
      figure {
        @media #{$desktop_b} {
          position: absolute;
          top: 0%;
          right: calc(50% + 40px);
          height: 100%;
          width: calc(50% - 40px);
        }
        img {
          width: 100%;
          height: 150px;
          object-fit: cover;
          @media #{$desktop_b} {
            @include centrar();
            height: 100% !important;
            @include border-radius(0 2px 2px 0);
          }
        }
      }
    }
  }
}
