.bs-contenedor {
  margin: 0 auto;
  display: block;
  width: calc(100% - 30px);
  max-width: $container-size;  

   &.extended {
    max-width:$big-container-size ;
   }

}

.bs-wrapper {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: $container-size;
  &.extended {
    max-width:calc($container-size + 380px) ;
   }
}



