.bs-menu-module {
  z-index: 1000;
  background-color: var(--bs--color-grises-gris-10);
  width: 100%;

  display: block;
  overflow-x: hidden;
  overflow-y: auto;

  @include scrolling(
    2px,
    2px,
    6px,
    var(--bs--color-grises-gris-10),
    var(--bs--color-grises-gris-50)
  );
  @media #{$tablet} {
    max-width: 320px;
    max-height: 100%;
    height: auto;
  }
  &.flotante {
    position: fixed;
    top: 0px;
    left: 0px;

    height: 100%;
    @media #{$tablet} {
      position: fixed;
      left: inherit;
      height: auto;
      max-height: 100vh;
      @include box-shadow(0px, 0px, 20px, 0px, rgba(0, 0, 0, 0.2), false);
    }
  }

  &.lateral {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0px;
    @include border-radius(0 0 4px 4px);
    @media #{$tablet} {
      @include box-shadow(0px, 0px, 20px, 0px, rgba(0, 0, 0, 0.1), false);
    }
  }
  button.close {
    height: 60px;
    width: 40px;
    display: block;
    background: $icon_delete center center no-repeat;
    background-size: 20px auto;
    &:hover {
      opacity: 0.5;
    }
  }
  .menu {
    input {
      display: none;
      &:checked {
        & + label {
          span {
            color: var(--bs--color-principal-verde);
          }
          figure {
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
          }
        }
        & ~ .child {
          display: block;
        }
      }
    }
    li {
      list-style: none;
    }
    > li {
      min-height: 2.4rem;
      border-top: 1px solid var(--bs--color-grises-gris-20);
      &:last-child {
        border-bottom: 1px solid var(--bs--color-grises-gris-20);
      }
      a.link,
      label.parent {
        cursor: pointer;
        &:hover {
          color: var(--bs--color-principal-verde);
          font-family: $overPass_bold;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
          figure {
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
          }
        }
      }

      > a.link:not(.logout) {
        span {
          background: $icon-menu-arrow right center no-repeat;
          padding-right: 24px;
        }
      }
      > a.link,
      > .parent {
        font-size: 1.6rem;
        color: var(--bs--color-principal-negro);
        font-family: $overPass_bold;
        line-height: 1;
        min-height: 6rem;
        width: 100%;
        @include flex-inline();
        @include flex-position-child("left", "center");
        @include flex-position-vertical("center");
        figure {
          height: 20px;
          width: 20px;
          display: inline-block;
          margin: 20px 10px;
          filter: grayscale(100%);
          -webkit-filter: grayscale(100%);
          img {
            height: 100%;
            width: auto;
          }
        }
      }
      .child {
        width: 100%;
        margin-bottom: 10px;
      }
      > .child {
        margin-top: -10px;
        padding-left: 40px;
        width: calc(100% - 50px);
        a.link,
        label.parent span {
          line-height: 2.4rem;
        }
        input {
          & + label.parent {
            span {
              background: $icon_arrow_down_green right center no-repeat;
            }
          }
          &:checked {
            & + label.parent {
              span {
                font-family: $overPass_bold;
                color: var(--bs--color-principal-negro);
                background: $icon_arrow_up_green right center no-repeat;
              }
            }
          }
        }
        label.parent {
          display: inline-block;
          width: 100%;
          span {
            width: 100%;
            display: inline-block;

            flex: 1;
          }
        }
      }

      .child {
        display: none;
        li {
          line-height: 2;
        }
      }
    }
  }
}

.bs-menu-desplegable {
  position: fixed;
  background: var(--bs--color-grises-gris-10);
  display: block;
  border: 1px solid var(--bs--color-grises-gris-20);
  @include box-shadow(0px, 0px, 20px, 0px, rgba(0, 0, 0, 0.1), false);
  @include border-radius(4px);
  z-index: 901;
  &:after {
    position: absolute;
    right: 24px;
    height: 25px;
    top: -22px;
    font-size: 2rem;
    content: "\25B2";
    display: block;
    color: var(--bs--color-grises-gris-20);
  }
  &:before {
    @media #{$tablet} {
      content: "";
      height: 25px;
      width: 100%;
      display: block;
      position: absolute;
      top: -25px;
      left: 0;
    }
  }
  ul {
    margin-bottom: 8px;
    li {
      list-style: none;
      > a:not(.bs-btn) {
        min-height: 48px;

        padding: 10px;
      }

      .detalle,
      .link {
        border-bottom: 1px solid var(--bs--color-grises-gris-40);
        width: calc(100% - 40px);
        padding: 20px;
        @include flex-inline();
        @include flex-position-child("bw", "center");
        @include flex-position-vertical("center");

      }
      .detalle {
        p {
          line-height: 1.2;
          flex:1;
          margin-right: 10px;
          font-family: $overPass_bold;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
      .link {
        &.icon {
              width: calc(100% - 30px);
        padding-left: 10px;
        }
        cursor: pointer;
        span {
          flex:1;
          font-family: $overPass_bold;
            line-height: 1.2;
        }
        @include flex-position-child("left", "center");
        &:hover {
          span {
            color: var(--bs--color-principal-verde);
          }
        }
        &.center {
          span {
            width: 100%;
            text-align: center;
          }
        }
        &:not(.center) {
          span {
             background: $icon-menu-arrow right center no-repeat;
          padding-right: 24px;
          }
          figure {
            height: 20px;
            width: 20px;
            display: inline-block;
            margin: 0 10px 0 0;
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
            img {
              height: 100%;
              width: auto;
            }
          }
          &:hover {
            figure {
              filter: grayscale(0%);
              -webkit-filter: grayscale(0%);
            }
          }
        }
      }
    }
  }
  width: 100%;

  top: calc(100% + 20px);

  @media #{$tablet} {
    width: 320px;
    position: absolute;
  }

  .corporativo & {
    top: calc(100% + 20px);
   right:0px;
    @media #{$tablet} {
      top: 110%;
    }
  }
  .privado & {
      right: 0px;
    top: calc(100% + 20px);
    @media #{$tablet} {
      top: calc(100% + 20px);
    }
  }
}
