.bs-accordeon {
  .accordeon-item {
    border: 1px solid var(--bs--color-grises-gris-10);
    margin-bottom: 10px;
    input {
      display: none;

      &:checked {
        & ~ .accordeon-item-content {
          height: auto;
          display: block;

        }
        & ~ .accordeon-item-header {
          figure {
             background: var(--bs--color-grises-gris-20);
            &::before {
              transform: rotate(45deg);
            }
          }
          span {
            font-family: $overPass_bold;
          }
        }
      }
    }
    .accordeon-item-header {
        -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
      width: 100%;
      cursor: pointer;
      min-height: 50px;
      @include flex-inline();
      @include flex-position-child("left", "center");
      @include flex-position-vertical("center");
      position: relative;
      border-bottom: 1px solid var(--bs--color-grises-gris-10);
             @include animate(all, 0.2s, linear);
      figure {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50px;
        background: var(--bs--color-grises-gris-10);
          @media #{$tablet} {
                 width: 60px;
          }
        &::before {
            @include animate(all, 0.2s, linear);
          display: block;
          content: "";
          height: 18px;
          width: 18px;
          position: absolute;
          top:calc(50% - 9px);
          left:calc(50% - 9px);
          background: $accordeon-icon center center no-repeat;
        }
      }
      span {
        margin-left: 50px;
        width: calc(100% - 50px);
        padding-left: 20px;
           @media #{$tablet} {
              margin-left: 60px;
        width: calc(100% - 60px);
                 padding-left: 10px;
           }
      }
    }
    .accordeon-item-content {

          display: none;
           padding:10px;

    }
  }
}
