.bs-tabla-comparativa {
  width: 100%;
  overflow: auto hidden;
  @include scrolling(
    2px,
    2px,
    6px,
    var(--bs--color-grises-gris-10),
    var(--bs--color-grises-gris-50)
  );
  section {
    display: block;
    width: 100%;
    min-width: 290px;
    padding-top: 40px;
    padding: 40px 0px 8px 0px;
    .cabecera {
      .columna {
        border-top: 3px solid var(--bs--color-grises-gris-20);
        &:first-child {
          border-top: 3px solid rgba(0, 0, 0, 0);
          border-left: 3px solid rgba(0, 0, 0, 0);
        }
      }
    }
    .cabecera,
    .contenido {
      width: 100%;
      @include flex();
      @include flex-position-child("left", "center");
      @include flex-position-vertical("center");
      min-height: 10px;
      position: relative;
      .columna {
        flex: 1;
        min-width: 64px;
        max-width: 320px;
        position: relative;
        min-height: 96px;

        border-bottom: 3px solid var(--bs--color-grises-gris-20);
        border-left: 3px solid var(--bs--color-grises-gris-20);
        @media #{$tablet} {
          min-height: 64px;
          padding-top: 0px;
        }
        &:last-child {
          border-right: 3px solid var(--bs--color-grises-gris-20);
        }
        &.no-border {
          &::before {
            border-top: none;
          }
        }
        > p {
          flex: 0 0 100%;
          font-family: $overPass_regular;
          color: var(--bs--color-principal-negro);
          text-align: center;
          line-height: 1;
          font-size: 1.3rem;
          @media #{$tablet} {
            font-size: 1.5rem;
          }
        }
        > span {
          &.ok,
          &.nok,
          &.min {
            height: 30px;
            width: 30px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            margin-bottom: 6px;
          }
          &.ok {
            background-image: $icon-table-ok;
          }
          &.nok {
            background-image: $icon-table-nok;
          }
          &.min {
            background-image: $icon-table-min;
          }
        }
        figure {
          margin: 0;
          padding: 0;
        }

        &:not(.titulo):not(.descriptivo):not(.subtitulo) {
          @include flex();
          @include flex-position-child("center", "bottom");
          @include flex-position-vertical("bottom");
          figure {
            width: 30px;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
        &.subtitulo {
          min-height: 30px;
          background: var(--bs--color-grises-gris-10);
          position: static;

          max-width: none;
          padding-top: 0px;
          h3 {
            color: var(--bs--color-principal-azul);
            text-transform: uppercase;
            font-size: 1.3rem;
            line-height: 3rem;
            text-indent: 16px;
          }
        }
        &.descriptivo {
          width: 0px;
          min-width: 0;
          flex: 0 0 0px;
          p {
            position: absolute;
            top: 16px;
            left: 16px;
            white-space: nowrap;
            text-align: left;
            font-size: 1.3rem;
            font-family: $overPass_bold;
            color: var(--bs--color-principal-negro);
          }
          @media #{$no-mobile} {
            border: none;
          }
          @media #{$tablet} {
            @include flex();
            @include flex-position-child("left", "center");
            @include flex-position-vertical("center");
            p {
              position: static;
              font-size: 1.5rem;
              text-indent: 16px;
            }
            flex: 1;
            min-width: 60px;
            max-width: 320px;
          }
        }
        &.titulo {
          background: var(--bs--color-principal-azul);
          min-height: 80px;
          padding-top: 0px;
          figure {
            display: none;
            width: 50px;
            position: absolute;
            left: calc(50% - 25px);
            top: -30px;
            z-index: 10;
            img {
              width: 100%;
              height: auto;
            }
            @media #{$tablet} {
              background: var(--bs--color-principal-blanco);
              display: grid;
              place-items: center;
            }
          }
          @media #{$tablet} {
            background: none;
          }
          h1 {
            color: var(--bs--color-principal-blanco);
            font-size: 1.3rem;
            font-family: $overPass_regular;
            line-height: 1.5;
            margin: 16px auto 8px;
            text-align: center;

            @media #{$tablet} {
              margin: 24px auto 8px;
              color: var(--bs--color-principal-negro);
              font-size: clamp(1.5rem, 1vw + 0.5rem, 1.8rem);
              font-family: $overPass_bold;
            }
          }
        }
      }
    }
  }
}
