// variables globales

:root {
  --bs--font-size-titulo-xl: clamp(1.5rem, 1.7vw + 0.2rem, 2rem);
  --bs--font-size-titulo-l: clamp(1.3rem, 1.7vw + 0.2rem, 1.7rem);
  --bs--font-size-titulo-m: clamp(1.2rem, 1.7vw + 0.2rem, 1.5rem);
  
  --bs--font-size-titulo-label: 1.4rem;
  --bs--font-size-placeholder: 1.4rem;
  //
  --bs--font-size-p: 1.5rem;
  --bs--font-size-caption: 1.3rem;
  --bs--font-size-menu: 1.5rem;
  --bs--font-size-h1: clamp(2.2rem, 3.2vw + 0.2rem, 4rem);
  --bs--font-size-h2: clamp(1.8rem, 2vw + 0.2rem, 2.2rem);
  --bs--font-size-h3: clamp(1.8rem, 2vw + 0.2rem, 2rem);
  --bs--font-size-h4: 1.8rem;
  --bs--font-size-h5: 1.5rem;
  --bs--font-size-button: 1.3rem;

  // colores
  --bs--color-principal-blanco: #{$blanco};
  --bs--color-principal-verde: #{$verde};
  --bs--color-principal-verde-hover: #{$verde-hover};
  --bs--color-principal-azul: #{$azul};
  --bs--color-principal-azul-hover: #{$azul-hover};
  --bs--color-principal-negro: #{$negro};

  --bs--color-secundario-amarillo: #{$amarillo};
  --bs--color-secundario-rojo: #{$rojo};
  --bs--color-secundario-rojo-hover: #{$rojo-hover};
  --bs--color-secundario-azul-40: #{$azul-40};
  --bs--color-secundario-azul-10: #{$azul-10};
  --bs--color-secundario-verde-50: #{$verde-50};

  --bs--color-grises-gris-10: #{$gris-10};
  --bs--color-grises-gris-15: #{$gris-15};
  --bs--color-grises-gris-20: #{$gris-20};
  --bs--color-grises-gris-40: #{$gris-40};
  --bs--color-grises-gris-50: #{$gris-50};
  --bs--color-grises-gris-80: #{$gris-80};

  --bs--color-validacion-verde-10: #{$verde-10};
  --bs--color-validacion-verde-50: #{$verde-50};
  --bs--color-validacion-amarillo-10: #{$amarillo-10};
  --bs--color-validacion-amarillo-50: #{$amarillo-50};
  --bs--color-validacion-rojo-10: #{$rojo-10};
  --bs--color-validacion-rojo-50: #{$rojo-50};
}
