.bs-time-line {
  width: 100%;
  min-height: 40px;
  .time-line-block {
    position: relative;
    display: inline-block;
     width: 100%;
    padding:0;

    &.background {
      background: var(--bs--color-grises-gris-10);
    
    }
    &:first-child {
     .bs-contenedor {
      &::before {
        top: 20px;
        height: calc(100% - 20px);
      }
     }
    }
    &:last-child {
      .bs-contenedor {
        &::before {
          height: 10px !important;
          @media #{$tablet} {
            height: 30px !important;
          }
        }
      }
    }
    .bs-contenedor {
      position: relative;
      padding: 0 0 20px 0;
      width: 100%;
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        width: 2px;
        height: calc(100% + 20px);
        display: block;
        left: 21px;
        background-color: var(--bs--color-principal-azul);
        @media #{$tablet} {
          left: 26px;
        }
      }
    }


    .time-line-spot {
      display: block;
      height: 20px;
      width: 20px;
      border: 2px solid var(--bs--color-principal-azul);
      content: "";
      position: absolute;
      left: 10px;
      top: 10px;
      @include border-radius(50%);
      background: var(--bs--color-principal-blanco) center center no-repeat;

      &.ok {
        background: var(--bs--color-principal-azul) $check_button_white center
          center no-repeat;
      }
      @media #{$tablet} {
        height: 30px;
        width: 30px;
      }
    }
    .time-line-module {
      width: calc(100% - 62px);
      margin-left: 46px;
      padding: 8px 16px 0 0;
      @media #{$tablet} {
        padding: 14px 16px 4px 0;
        width: calc(100% - 72px);
        margin-left: 56px;
      }
    }

  }
}
