/* You can add global styles to this file, and also import other style files */
.btn-right{
    display: flex;
    justify-content: flex-end;
}

.no-valide{
    outline: 1px solid var(--bs--color-grises-gris-80) !important;
    color: var(--bs--color-principal-negro);
}

.message-invalide{
    color: var(--bs--color-secundario-rojo);
}
