.bs-pasos-indice {
  @include flex();
  @include flex-position-child('left','center');
  width: 100%;
  p-progressbar {

    flex:0 0 100%;
  }
  .index  {
    height: 20px;
    width: 20px;
    display: grid; place-items: center;
    @include border-radius(50%);
    color:var(--bs--color-grises-gris-50);
    border:2px solid var(--bs--color-grises-gris-50);

   font-size: 1.3rem;
   font-family: $overPass_bold;
    text-align: center;

    padding:0; margin:0 8px 0 0;

  }
  .pasos {
    color:var(--bs--color-grises-gris-80);
    font-size: 1.3rem;
    text-transform: uppercase;
    font-family: $overPass_regular;
    text-align: right;
  }
  h2.bs-contenido {
    font-family: $overPass_bold;
    flex:1;
    color:var(--bs--color-grises-gris-50)  ;
  }
  &.active {

    .index {
      color:var(--bs--color-principal-azul);
      border:2px solid var(--bs--color-principal-azul);
    }
    h2 {
      color:var(--bs--color-principal-negro);
      span {
        color:var(--bs--color-principal-azul)
      }
    }
  }
  &.completed {
    .index {
      color:rgba(0,0,0,0);
      border:2px solid var(--bs--color-principal-azul);
      background:$check_button_white var(--bs--color-principal-azul) center center no-repeat;
      background-size: auto 12px;
    }
    h2 {
      color:var(--bs--color-principal-azul);
    }
  }

}
