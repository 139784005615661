.bs-tarjeta-detalle {
  width: 100%;
  display: block;
  background: var(--bs--color-principal-blanco);
  border: 2px solid var(--bs--color-grises-gris-20);
  margin-bottom: 20px;
  .top {
    border-bottom: 2px solid var(--bs--color-grises-gris-20);
    @include flex();

    @include flex-position-child("bw", "center");
    @include flex-position-vertical("center");
    padding: 4px 6px;
    .bs-chip {
      margin: 0;
    }
    h1 {
      flex: 1;
      font-size: 1.6rem;
      text-transform: uppercase;
      color: var(--bs--color-principal-negro);
      font-family: $overPass_bold;
      &::before {
        font-family: $overPass_regular;
        font-size: 1rem;
        line-height: 0.9;
        content: attr(data-title);
        display: block;
      }
    }
  }

  .content {
    background: var(--bs--color-grises-gris-10);
    border-bottom: 2px solid var(--bs--color-grises-gris-20);
    .data {
      width: 100%;

      //  @include grid(2, 8px, 8px);
      @include flex();
      @include flex-position-child("bw", "top");
      @include flex-position-vertical("top");
      border-bottom: 1px solid var(--bs--color-grises-gris-40);
      &:last-child {
        border: none;
      }
      .data-module {
        flex: 1;
        padding: 8px;
        display: block;
        & +  .data-module {
          flex: 0 1 40%;

        }

        p {
          line-height: 1.5;
          font-family: $overPass_regular;
          font-size: 1.1rem;
        }
        h5 {
          line-height: 1.2;
          font-family: $overPass_bold;
          font-size: 1.5rem;
        }
      }
    }
  }
  .extra {
    background: var(--bs--color-principal-blanco);
    .expandable-content {
      display: none;
      @media #{$tablet} {
        display: block;
      }
      .data-footer {
        margin: 0 auto;
        padding: 4px 0;
        width: 90%;
        p {
          font-size: 1.2rem;line-height: 1.4;
        }
        margin-bottom: 10px;
      }
      .data-module {
        margin: 0 auto;
        padding: 4px 0;
        width: 90%;
        border-top: 1px solid var(--bs--color-grises-gris-20);
       &:first-of-type {
        border: none;
       }
        a:not(.bs-btn) {
          @include flex();
          @include flex-position-child("bw", "center");
          @include flex-position-vertical("center");
          text-decoration: none;
          &:hover {
                 color: var(--bs--color-grises-gris-80);
                 figure {
                  opacity: 0.6;
                 }
          }
          color: var(--bs--color-principal-negro);
          figure {
            width: 40px;
            img {
              height: 100%;
              width: auto;
            }
          }
          figcaption {
            flex: 1;
            font-family: $overPass_regular;
            span {
              display: block;
              font-size: 1.2rem;
            }
            strong {
              font-family: $overPass_bold;
            }
          }
        }
      }
    }
    input[type="checkbox"] {
      display: none;
      & + label {
        color:var(--bs--color-principal-verde);
        font-family: $overPass_bold;
        line-height: 2.4;
        width: 95%; margin:0 auto;
        text-decoration: underline;
        display: block;
         -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;
         @include animate(width, 0.2s, linear);
        &:hover {
           color:var(--bs--color-principal-verde-hover);
        }
        cursor: pointer;
        &:before {
          content: attr(data-off);
        }
        @media #{$tablet} {
          display: none;
        }
      }
      &:checked {
        & + label {
            width: 90%;
          &:before {
            content: attr(data-on);
          }
        }
        & ~ .expandable-content {
          display: block;
        }
      }
    }
  }
}
