footer {
  &.bs-footer {
    width: 100%;
    margin-top: 40px;
    .simple-content {
      width: 98%;
      margin: 0 auto;
      @include flex();
      @include flex-position-child("center", "center");
      @include flex-position-vertical("center");
      @media #{$tablet} {
        @include flex-position-child("bw", "center");
      }
      > p {
        flex: 0 0 100%;
        text-align: center !important;
      }
      .marca {
        flex: 0 0 100%;
        @include flex();
        @include flex-position-child("center", "center");
        .logo {
          margin: 12px 0;
          @media #{$tablet} {
            margin: 0;
          }
        }
        @media #{$tablet} {
          @include flex-position-child("left", "center");
          flex: 0 0 200px;
        }
      }
      .redes-sociales {
        flex: 0 0 100%;
        margin: 12px 0;
        @include flex();
        @include flex-position-child("center", "center");
        @include flex-position-vertical("center");

        @media #{$tablet} {
          flex: 1;
          margin: 0;
          @include flex-position-child("right", "center");
        }
        p {
          flex: 0 0 100%;
          text-align: center;
          @media #{$tablet} {
            text-align: right;
          }
        }
        a {
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
