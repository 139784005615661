p-accordion {
  .bs-form-accordion {
    .bs-bloque {
      margin:0 2px;width: calc(100% - 4px);
    }
    p-accordiontab {

      .p-accordion-tab {
        border-bottom: 1px solid var(--bs--color-grises-gris-40);
      }

      &:first-child {
        .p-accordion-tab {
          border-top: 1px solid var(--bs--color-grises-gris-40);
        }
      }
    }

    .p-accordion-tab {

      .p-accordion-header {

        .p-accordion-header-link {
          width: 100%;

          height: 44px;
  @media #{$tablet} {
      height: 64px;
  }
          .p-accordion-header-text {
            font-size: var(--bs--font-size-h3) !important;
            font-family: $overPass_bold !important;
            flex: 1;
            order: 1;
          }

          .p-accordion-toggle-icon {
            order: 2;
            background: $accordion-arrow-down center center no-repeat;
            width: 20px;
            height: 20px;

            &::before {
              display: none;
            }


          }
        }
      }

      &.p-accordion-tab-active {
        .p-accordion-header {
          .p-accordion-header-link {
            .p-accordion-toggle-icon {
              background-image: $accordion-arrow-up;
              opacity: 0.7;
            }
          }
        }

      }

    }
  }
}
