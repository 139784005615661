.p-dropdown {
  width: 100%;
  border: none;
  outline: none;
  line-height:  $form-size;
  background: var(--bs--color-principal-blanco);
  @include border-radius(4px);
  font-size: 1.4rem !important;
  text-indent: 15px;
  margin: 0 0 6px 0;
  &.p-dropdown-open{
    @include border-radius(4px 4px 0 0 );
 .p-inputtext:not(.p-dropdown-filter) {

    font-family: $overPass_bold !important;
    color:var(--bs--color-principal-negro) !important;
  }
  }
  .p-dropdown-empty-message {
    font-family: $overPass_bold;
    font-size: 1.4rem;
    color:var(--bs--color-principal-negro) !important;
    line-height:  $form-size;
  }
  .p-inputtext:not(.p-dropdown-filter) {
    outline: none !important;
       color:var(--bs--color-grises-gris-80) !important;
    font-family: $overPass_regular !important;

    .p-inputwrapper-filled & {
      font-family: $overPass_bold !important;
    color:var(--bs--color-principal-negro) !important;
    }
  }

  color: var(--bs--color-principal-negro);
  position: relative;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 1px solid var(--bs--color-grises-gris-80) !important;
  &:not(.p-disabled):hover {
    outline: 1px solid var(--bs--color-principal-negro) !important;
  }
  &:not(.p-disabled).p-focus {
    @include no-shadow();
    border-color: $gris-10 !important;
    outline: 1px solid var(--bs--color-principal-negro) !important;
  }
  .p-dropdown-label {
    font-size: 1.4rem !important;
    margin:0; padding: 0;
    @include no-shadow();
    font-family: $overPass_regular;
  }
  .p-dropdown-open {
    outline: 1px solid var(--bs--color-grises-gris-80) !important;
  }
  .p-dropdown-trigger {
    width: 40px;
    background: $icon_arrow_down_green center center no-repeat;
    background-size: 16px auto;
    span {
      display: none;
    }
    &:hover {
      opacity: 0.5;
    }
  }
  .p-placeholder {
    color: var(--bs--color-grises-gris-80) !important;
    font-family: $overPass_regular !important;

  }
  .p-dropdown-panel {
    background-color: var(--bs--color-principal-blanco);
    @include no-shadow();
    outline: 1px solid var(--bs--color-grises-gris-80) !important;
    @include border-radius( 0 0 4px 4px );
  }
  .p-dropdown-item {
    font-size: 1.6rem;

    line-height: $form-size;
    padding:0 !important;
    margin:0 !important;
    font-family: $overPass_bold !important;
    &:hover {
      background-color: var(--bs--color-grises-gris-10) !important;
    }
    &.p-highlight {
      background-color: var(--bs--color-grises-gris-20) !important;
    }
  }
  .p-dropdown-header {
     padding-left:0 !important;
     padding-right:0 !important;
    //margin:0 !important
    background-color: var(--bs--color-grises-gris-10);
    .p-dropdown-filter-container {
      @include flex();
      @include flex-position-child("bw", "top");
      @include flex-position-vertical("top");

      .p-dropdown-filter-icon {
        height: $form-size;
        width: 30px;
        top:0px;
        margin:8px 0;
        right: 16px;
        background: $icon_search center center no-repeat;
        &::before {
          display: none;
        }
      }
      .p-dropdown-filter {
        flex:1;
        margin: 8px !important;
        width: auto !important;
      }
    }
  }

  /*.ng-invalid &,*/
  .is-invalid & {
    /*outline: 1px solid  var(--bs--color-secundario-rojo) !important;
    color:  var(--bs--color-secundario-rojo);*/
    @include placeholder {
      color:  var(--bs--color-secundario-rojo);
    }
    .p-placeholder {
      color:  var(--bs--color-secundario-rojo) !important;
      font-family: $overPass_bold !important;

    }
    &:hover {
      outline: 1px solid var(--bs--color-secundario-rojo-hover) !important;
    }
    &:focus {
      outline: 1px solid  var(--bs--color-secundario-rojo) !important;
    }
    .p-dropdown-trigger {
      background-image: $icon_arrow_down_red;
    }
  }
  &.p-disabled {
    opacity: 1 !important;
    outline-color: var(--bs--color-grises-gris-40) !important;
    cursor: not-allowed !important;
    .p-dropdown-label {
      cursor:not-allowed !important;
    }
 .p-inputtext:not(.p-dropdown-filter) {
  cursor: not-allowed;
    font-family: $overPass_bold !important;
    color:var(--bs--color-grises-gris-50) !important;

  }


    .p-dropdown-trigger {
      opacity: 0.5 !important;
      cursor: not-allowed !important;
    }
    .p-inputtext {
      cursor: not-allowed !important;
      color: rgba($gris-50, 0.8) !important;

    }
  }
}
p-dropdown {
  &.p-inputwrapper-focus {
    .p-dropdown {
      outline: 2px solid var(--bs--color-principal-negro) !important;
    }
  }
}
