.p-paginator {
  padding: 0;
  margin: 10px;
  .p-paginator-pages {
    margin: 0 10px;
    .p-paginator-element {
      height: 32px;
      background-color: var(--bs--color-principal-blanco) !important;
    }
    .p-paginator-page {
      padding-left: 10px !important;
      padding-right: 10px !important;
      font-family: $overPass_regular;
      color: var(--bs--color-grises-gris-80);
      @include border-radius(4px);
      font-size: 1.5rem;
         @media #{$tablet} {
             padding-left: 15px !important;
      padding-right: 15px !important;
         }
      &:hover {
        font-family: $overPass_bold;
        color: var(--bs--color-principal-verde-hover) !important;
      }
      &.p-highlight {
        background-color: var(--bs--color-principal-verde) !important;
        border-color: var(--bs--color-principal-verde) !important;
        color: var(--bs--color-principal-blanco);
        @include no-shadow();
        &:hover {
          font-family: $overPass_regular;
          color: var(--bs--color-principal-blanco) !important;
        }
      }
    }
  }
  .p-paginator-current {
    margin: 10px;
    flex: 0 0 100%;
    color: var(--bs--color-grises-gris-80);
    font-family: $overPass_bold;
    @media #{$tablet} {
      flex: 1;
    }
  }
  .p-paginator-element {
    height: 32px;
    background: none;
    background-position: center center;

background-repeat: no-repeat;
background-size: 20px auto;
width: 20px;
    &.p-disabled {
      opacity: 0.5;
         filter: grayscale(100%);
              -webkit-filter: grayscale(100%);
    }
    .p-paginator-icon {
      &:before {
      display: none;
      }
    }
     &.p-paginator-next {
      background-image: $icon-paginator-next;
     }
     &.p-paginator-prev {
 background-image: $icon-paginator-prev;
     }
     &.p-paginator-last {
 background-image: $icon-paginator-last;
     }
      &.p-paginator-first {
 background-image: $icon-paginator-first;
     }
    &.p-paginator-prev,
    &.p-paginator-next {
        width: 40px !important;
       @media #{$tablet} {
  width: 80px !important;
       }

    }
  }
}
