.bs-banner-header {
  width: 100%;
  display: block;
  figure {
    position: absolute;
    top: 50px;
    left: 0;
    @media #{$tablet} {
      position: static;
    }
    width: 100%;
    display: block;
    img {
      width: 100%;
      display: block;
      height: 50px;
      object-fit: cover;
      @media #{$tablet} {
        height: 80px;
      }
      @media #{$desktop} {
        height: 100px;
      }
    }
  }
}
