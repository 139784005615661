.bs-aplicacion-movil {
  width: 100%;
  @include flex-inline();
  @include flex-position-child("left", "center");
  @include flex-position-vertical("center");
  @include flex-direction('column',true);

  @media #{$tablet} {
    @include flex-position-child("left", "top");
    @include flex-position-vertical("top");
    @include flex-direction('row',false);
  }
  > figcaption {
    display: block;
    flex:1;
    padding-right: 10px;

    @media #{$tablet} {
      padding-right: 20px;

    }
  }
  > figure {
    flex:1; max-width: 230px;
    margin-right: 20px;
    img {
      margin-top: 30px;
      width: 100%;
      height: auto;
      @media #{$tablet} {
        margin-top: 0px;
      }
      @media #{$desktop} {
        width: auto;
        height: auto;
      }

    }
    @media #{$tablet} {
      flex:1; max-width: 100px;
      margin-right: 20px;
    }
    @media #{$desktop} {
      flex:1; max-width: 160px;
      margin-right: 40px;
    }
  }
}
