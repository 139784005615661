p,
h2,
h2,
h3,
h4,
h5,
label {
  color: var(--bs--color-principal-negro);
  &.bs-uppercase {
    text-transform: uppercase;
  }
  &.bs-center {
    text-align: center !important;
  }
  &.bs-right {
    text-align: right !important;
  }
  &.bs-azul {
     color: var(--bs--color-principal-azul) !important;
  }
}

p {
  font-family: $overPass_regular;
  font-size: var(--bs--font-size-p);
  line-height: 2.4rem;
  color:var(--bs--color-principal-negro);
  strong {
    font-family: $overPass_bold;
    color: var(--bs--color-principal-azul) !important;
    &.bs-negro {
      color: var(--bs--color-principal-negro) !important;
    }
  }
  a {
    font-family: $overPass_bold;
 color: var(--bs--color-principal-verde) !important;
 text-decoration: underline;
    text-underline-offset: 2px;
    &.bs-imagen {
      margin: 0 8px;
      &:hover {
        opacity: 0.5;
      }
    }
    &:hover {
      color: var(--bs--color-principal-verde-hover) !important;
    }
    &.bs-azul {
      color: var(--bs--color-principal-azul) !important;
      &:hover {
        color: var(--bs--color-principal-azul-hover) !important;
      }
    }
  }
  &.bs-caption {
    font-size: var(--bs--font-size-caption);
    color: var(--bs--color-grises-gris-50);
    line-height: 1.1rem;
    &.bs-error {
      color: var(--bs--color-secundario-rojo);
    }
  }
  .color & {
    color: var(--bs--color-principal-blanco);
    &.bs-error {
      color: var(--bs--color-validacion-rojo-10);
    }
  }
}
h1 {
  font-family: $overPass_bold;
  font-size: var(--bs--font-size-h1);
  line-height: normal;
  &.bs-contenido {
    font-size: var(--bs--font-size-titulo-xl);
    text-transform: uppercase;
  }
  strong {
    font-family: $overPass_bold;
  }
}
h2 {
  font-family: $overPass_regular;
  font-size: var(--bs--font-size-h2);
  line-height: 3.4rem;

  &.bs-contenido {
    font-size: var(--bs--font-size-titulo-l);
    text-transform: uppercase;
  }
  strong {
    font-family: $overPass_bold;
  }
}
h3 {
  font-family: $overPass_bold;
  font-size: var(--bs--font-size-h3);
  line-height: 2.4rem;
  &.bs-contenido {
    font-size: var(--bs--font-size-titulo-m);
    text-transform: uppercase;
    color: var(--bs--color-principal-azul);
    &.bs-gris-80 {
      color: var(--bs--color-grises-gris-80);
    }
    &.bs-negro {
      color: var(--bs--color-principal-negro);
    }
  }
}
h4 {
  font-family: $overPass_regular;
  font-size: var(--bs--font-size-h4);
  line-height: 2.4rem;
  strong {
    font-family: $overPass_bold;
  }
}
h5 {
  font-family: $overPass_bold;
  font-size: var(--bs--font-size-h5);
  line-height: 2.4rem;
  label & {
    font-size: var(--bs--font-size-titulo-label);
    line-height: 1;
    margin-bottom: 8px;
    min-height: 1.4rem;
    &[disabled] {
      color: var(--bs--color-grises-gris-50);
    }

    span {
      &.bs-required {
        margin-left: 4px;
        color:  var(--bs--color-secundario-rojo);
        font-size: 1.4rem;
      }
      &.bs-optional {
        margin-left: 4px;
        color: var(--bs--color-grises-gris-50);
        font-size: 1.1rem;
        text-transform: uppercase;
      }
    }
  }

  .bs-color & {
    color: var(--bs--color-principal-blanco);
    &[disabled] {
      color: var(--bs--color-principal-blanco);
      opacity: 0.5;


    }
  }
}
div.bs-lista {
   @include flex();
    @include flex-position-child("left", "top");
    @include flex-position-vertical("top");
  span {
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
    color:var(--bs--color-principal-blanco);
    font-size: 1.3rem;
    margin-right: 12px;
    background-color: var(--bs--color-principal-azul);
    @include border-radius(50%);
    margin-top: 2px;
    &.check  {
      background: $icon-bullet center center no-repeat;
    }
  }
  p {
    flex:1
  }
}
ul.bs-lista,
ol.bs-lista {
  li {
    list-style: none;
    font-size: var(--bs--font-size-p);
    color: var(--bs--color-principal-negro);
    margin-bottom: 12px;
    padding-left: 30px;
  }
}
ul.bs-lista {
  li {
    background: $icon-bullet left 1px no-repeat;
    background-size: auto 20px;

    min-height: 20px;
    line-height: 1.5;
  }
}
ol.bs-lista {
  counter-reset: element-counter;
  li {
    position: relative;
    counter-increment: element-counter;
    &::before {
      content: counter(element-counter);
      height: 20px;
      width: 20px;
      display: grid;
      place-items: center;
      position: absolute;
      @include border-radius(50%);
      left: 0;
      top: 1px;
      color: var(--bs--color-principal-blanco);
      background-color: var(--bs--color-principal-azul);
      font-family: $overPass_bold;
      font-size: 1rem;
    }
  }
}
