.bs-page-loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  background: rgba($blanco, 0.9);
  @include flex();
  @include flex-position-child("center", "center");
  @include flex-position-vertical("center");
  animation-name: fadeIn;
  animation-duration: 0.1s;
  p {
    flex: 0 0 100%;
    text-align: center;
    line-height: 4.8rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.bs-loader-html {
  display: block;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  margin: 0 auto;
  &.inline-block {
    display: inline-block;
  }

  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    @include border-radius(50%);
    border: 3px solid rgba($gris-10, 0.4);
    border-top: 3px solid var(--bs--color-principal-azul);
  }
  @for $i from 0 through 100 {
    $height: 4px + ($i * 4);
    $name: 4 + ($i * 4);

    &.w-#{$name} {
      @include figure-size($height, $height);
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.bs-loader-container {
  width: auto;
  height: auto;
  display: block;
}
