.bs-banner {
  background-size: auto 100%;
  background-position: right center;;
  min-height: 10px;
  @include border-radius(4px);
  @include flex-inline();
  @include flex-position-child('bw','center');
  @include flex-position-vertical( 'center');
  padding:8px;
  width: calc(100% - 16px);
  @media #{$tablet} {
    padding:16px;
    width: calc(100% - 32px);
    background-size: cover;
    background-position: center center;;
  }
  @for $i from 0 through 250 {
    $maxwidth: 5px + ($i * 5);
    $name: 5 + ($i * 5);

    &.m-w-#{$name} {
      max-width: $maxwidth;
    }
  }
  figure {
    flex:0 0 64px;
    img {
      width: 100%; height: auto ;
    }

    @media #{$tablet} {
      flex:0 0 96px;

    }
  }
  figcaption {
    flex:1;
    h1 {
      font-size: clamp(1.5rem,1.7vw + 0.2rem, 2rem);
      color:var(--bs--color-principal-blanco);
      font-family: $overPass_bold;
    }
    h2 {
      font-size: clamp(1.5rem,1.7vw + 0.2rem, 1.8rem);
      color:var(--bs--color-principal-blanco);
      font-family: $overPass_regular;
    }
    h3 {
      font-size: clamp(1.4rem,1.7vw + 0.2rem, 1.7rem);
      color:var(--bs--color-principal-blanco);
      font-family: $overPass_bold;
    }
    h4 {
      font-size: clamp(1.3rem,1.7vw + 0.2rem,1.6rem);
      color:var(--bs--color-principal-blanco);
      font-family: $overPass_regular;
    }
    h5{
      font-size: clamp(1.2rem,1.7vw + 0.2rem, 1.5rem);
      color:var(--bs--color-principal-blanco);
      font-family: $overPass_bold;
    }
    p {
      font-size: clamp(1.2rem,1.7vw + 0.2rem, 1.5rem);
      color:var(--bs--color-principal-blanco);
    }
  }
}
