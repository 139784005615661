.bs-banner-informativo {
  width: 100%;
  display: grid;
  height: auto;
  min-height: 20px;
  place-items: center;
  // puede ser igualmente ajustada en el DIV con la clase aplicada
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  section {
    width: calc(100% - 15px);
    padding: 20px 10px 20px 5px;
    height: auto;
    @media #{$tablet} {
      width: calc(100% - 25px);
      padding: 20px 20px 20px 5px;
    }
    @include flex();
    @include flex-position-child("center", "str");
    @include flex-position-vertical("str");
    filter: brightness(380%);
    -webkit-filter: brightness(380%);
    figure {
      width: 50px;
      display: grid;
      place-items: center;

      img {
        width: 48px;
        @media #{$tablet} {
          width: 64px;
        }
      }

      @media #{$tablet} {
        width: 80px;
      }
    }
    p {
      flex: 1;
      font-size: var(--bs--font-size-p);
      strong {
        display: block;
      }
    }
  }
}
