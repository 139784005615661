.bs-tabla-datos {
  width: 100%;
  overflow: auto hidden;
  @include scrolling(
    2px,
    2px,
    6px,
    var(--bs--color-grises-gris-10),
    var(--bs--color-grises-gris-50)
  );
  p-datatable {
    width: 100%;

  }
}
.bs-tabla-panel {
  width: 100%;
  padding-right: 1rem;
  margin-left: auto;
  span.label  {
    flex:1;
  }
  @include flex-inline();
    @include flex-position-child("right", "center");
  @include flex-position-vertical("center");

}
.p-datatable {
  width: 100%;
  .p-paginator {
    //display: none !important;
  }
  .p-datatable-wrapper {
    margin-bottom: 16px;

    table {
      min-width: 1100px;
      background: var(--bs--color-grises-gris-10);
      @include border-radius(4px);
      thead.p-datatable-thead {
        tr {
          th {

            .bs-btn {

               @include centrar(97%,50%);
               min-width: max-content;
               white-space: nowrap;
               width: auto;
               padding-right: 16px;

            }
            &[colspan="2"],
            &[colspan="3"],
            &[colspan="4"],
            &[colspan="5"] {
              text-align: center;
              border-top: none !important;
              border-bottom: none !important;
            }
            position: relative;
            padding: 1rem;
            min-height: 2.4rem;
            font-size: 1.3rem;
            font-family: $overPass_bold;
            text-transform: uppercase;
            text-align: left;
            color: var(--bs--color-principal-negro);
            vertical-align: middle;
            border: 4px solid var(--bs--color-grises-gris-20);
            border-top: none !important;
            span:not(.arrow) {
              display: inline-block;
              margin-right: auto;
              max-width: calc(100% - 30px);
            }
            .p-sortable-column-icon {
              position: absolute;
              top: calc(50% - 10px);
              right: 10px;
              height: 20px;
              width: 20px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center center;
              background-image: $icon-table-sort-mixto;
              &::before {
                display: none;
              }
              &.pi-sort-alt {
                background-image: $icon-table-sort-mixto;
              }
              &.pi-sort-amount-up-alt {
                background-image: $icon-table-sort-up;
              }
              &.pi-sort-amount-down {
                background-image: $icon-table-sort-down;
              }
            }
          }
        }
      }
      tbody.p-datatable-tbody {
        tr {
          background-color: var(--bs--color-principal-blanco);
          &:nth-child(even) {
            background-color: lighten($gris-10, 5%);
          }
          td {
            min-height: 48px;
            height: auto;
            padding: 1rem;
            vertical-align: middle;
            text-align: left;

            border: 4px solid var(--bs--color-grises-gris-20);
            font-size: 1.5rem;
            color: var(--bs--color-principal-negro);
            font-family: $overPass_regular;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}
