button {
  margin: 0;
  padding: 0;
  border: none; //custom border
  outline: none;
  background: none;
  cursor: pointer;
}
.bs-btn {
  text-decoration: none;
  height: 48px;
  @include flex-inline();
  color: white;
  @include border-radius(24px);
  @include flex-position-child("center", "center");
  @include flex-position-vertical("center");
  font-size: 1.3rem;
  text-align: center;
  padding: 0;
  min-width: 144px;
  font-size: var(--bs--font-size-button);
  font-family: $overPass_bold;
  text-transform: uppercase;
  margin: 6px 0;
  width: 100%;
  @include animate(all, 0.1s, linear);
  position: relative;
  &a {
    cursor: pointer;
  }
  &.small {
    height: 18px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    width: auto !important;
    min-width: 0px;
  }
  &.tabla {
    height: 30px;
    width: auto;
    min-width: 100px;
    margin: 0;
    cursor: pointer;
    & + .bs-btn-primary,
    & + .bs-btn-secondary {
      margin-left: 5px;
    }
    & + .bs-btn-icon-only {
      margin-left: 10px;
    }
    &.bs-btn-icon-only {
      min-width: 0;
    }
    &.bs-btn-link {
      min-width: 0;
      min-height: 0;
      height: auto;
    }
  }

  @media #{$tablet} {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 190px;
  }
  @media #{$desktop} {
    min-width: 220px;
  }
  &.icons {
    @include flex-position-child("bw", "center");
  }
  figure {
    height: 20px;
    width: 20px;
    display: block;
    margin-right: 10px;
    margin-left: 15px;
    @media #{$tablet} {
      margin-left: 0;
    }
    img {
      height: 100%;
      width: auto;
    }
  }
  span {
    &.arrow,
    &.dummy {
      height: 20px;
      width: 20px;
      background-size: 20px 20px;
      display: block;

      &:last-child {
        margin-left: 10px;
        margin-right: 15px;
        @media #{$tablet} {
          margin-right: 0;
        }
      }
      &:first-child {
        margin-left: 15px;
        margin-right: 10px;
        @media #{$tablet} {
          margin-left: 0;
        }
      }
    }
    &.add {
      height: 28px;
      width: 28px;
      background: $icon_add center center no-repeat;
      background-size: 28px 28px;
      display: block;

      &:last-child {
        margin-left: 10px;
        margin-right: 15px;
      }
      &:first-child {
        margin-left: 15px;
        margin-right: 10px;
      }
    }
    &.remove {
      height: 28px;
      width: 28px;
      background: $icon_remove center center no-repeat;
      background-size: 28px 28px;
      display: block;

      &:last-child {
        margin-left: 10px;
        margin-right: 15px;
      }
      &:first-child {
        margin-left: 15px;
        margin-right: 10px;
      }
    }
  }

  &.border {
    background: white;
  }
  &.disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &-secondary-color {
    color: var(--bs--color-principal-blanco);
    border: 2px solid var(--bs--color-principal-blanco);
    &.icons {
      span.arrow {
        background: $icon_arrow center center no-repeat;
      }
    }
    &:hover {
      opacity: 0.5;
    }
  }
  &-form {
    height: $form-size;
    background-color: var(--bs--color-principal-verde);
    outline: var(--bs--color-principal-verde) 1px solid;
    @include border-radius(0 4px 4px 0);
    margin: 0;
    padding: 0 16px;
    min-width: 0px;
    width: auto !important;
    max-width: 120px;
    &.icons {
      cursor: pointer;
      figure {
        margin: 0;
      }
    }
    &.no-bg {
      background: none;
      outline: none;
      &:hover {
        background-color: none !important;
        opacity: 0.5;
      }
    }
    &.float {
      position: absolute;
      top: 0;
      right: 0px;
    }
    &.audio {
      background: $icon_mic_off center center no-repeat;
      &.active {
        background: $icon_mic center center no-repeat;
      }
    }
    &.password {
      background: $icon_watch_off center center no-repeat;
      &.active {
        background: $icon_watch center center no-repeat;
      }
    }
    @include animate(all, 0s, linear);
    &:hover:not(.audio):not(.password):not(.no-bg) {
      background-color: var(--bs--color-principal-verde-hover);
      outline: var(--bs--color-principal-verde-hover) 1px solid;
    }
    &[disabled] {
      &:hover:not(.audio):not(.password):not(.no-bg) {
        background-color: var(--bs--color-principal-verde);
        outline: var(--bs--color-principal-verde) 1px solid;
      }
    }
  }
  &-remove {
    @include border-radius(4px);
    border: 2px dotted var(--bs--color-secundario-rojo);

    text-transform: none;
    height: auto;
    padding: 6px 0;
    min-height: 34px;

    width: 100%;
    @media #{$tablet} {
      width: auto;
      min-width: 190px;
      &.full {
        width: 100%;
      }
    }
    span {
      &.label {
        font-size: 1.5rem;
        flex: 1;
        line-height: normal;
        margin: 0;
        text-align: left;
        color: var(--bs--color-secundario-rojo);
        padding-right: 15px;
      }
    }
    &.icons {
      span.remove {
        margin-left: 0;
        margin-right: 16px;
        margin-left: 15px;
      }
    }
    &:hover {
      border: 2px dotted var(--bs--color-secundario-rojo-hover);
      span {
        &.remove {
          filter: brightness(80%);
          -webkit-filter: brightness(80%);
        }
        &.label {
          color: var(--bs--color-secundario-rojo-hover);
        }
      }
    }
    &[disabled] {
      span {
        &.remove {
          filter: brightness(100%);
          -webkit-filter: brightness(100%);
        }
        &.label {
          color: var(--bs--color-secundario-rojo);
        }
      }
      &:hover {
        border: 2px dotted var(--bs--color-secundario-rojo);
      }
    }
  }

  &-add {
    @include border-radius(4px);
    border: 2px dotted var(--bs--color-principal-verde);

    text-transform: none;
    height: auto;
    padding: 6px 0;
    min-height: 34px;

    width: 100%;
    @media #{$tablet} {
      width: auto;
      min-width: 190px;
       &.full {
        width: 100%;
      }
    }
    span {
      &.label {
        font-size: 1.5rem;
        flex: 1;
        line-height: normal;
        margin: 0;
        text-align: left;
        color: var(--bs--color-principal-verde);
        padding-right: 15px;
      }
    }
    &.icons {
      span.add {
        margin-left: 0;
        margin-right: 16px;
        margin-left: 15px;
      }
    }
    &:hover {
      border: 2px dotted var(--bs--color-principal-verde-hover);
      span {
        &.add {
          filter: brightness(80%);
          -webkit-filter: brightness(80%);
        }
        &.label {
          color: var(--bs--color-principal-verde-hover);
        }
      }
    }
    &[disabled] {
      span {
        &.add {
          filter: brightness(100%);
          -webkit-filter: brightness(100%);
        }
        &.label {
          color: var(--bs--color-principal-verde);
        }
      }
      &:hover {
        border: 2px dotted var(--bs--color-principal-verde);
      }
    }
  }
  &-link {
    color: var(--bs--color-principal-verde);
    text-decoration: underline;
    height: auto;
    text-transform: none;
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
    display: inline;
    font-family: $overPass_bold;
    text-underline-offset: 2px;
    min-width: 0;
    width: auto !important;
    &:hover {
      color: var(--bs--color-principal-verde-hover);
    }
    &.icon {
      padding-left: 20px;
      position: relative;
      img {
        @include centrar(0, 50%);
        height: 16px;
        width: auto;
      }
      &.invert {
        padding-right: 20px;
        padding-left: 0px;
        img {
          @include centrar(100%, 50%);
        }
      }
      &:hover {
        img {
          filter: brightness(80%);
          -webkit-filter: brightness(80%);
        }
      }
    }
    &[disabled] {
      color: var(--bs--color-validacion-verde-50);
      img {
        filter: brightness(100%) grayscale(100%);
        -webkit-filter: brightness(100%) grayscale(100%);
      }
      &:hover {
        color: var(--bs--color-validacion-verde-50);
        img {
          filter: brightness(100%) grayscale(100%);
          -webkit-filter: brightness(100%) grayscale(100%);
        }
      }
    }
  }
  &-primary {
    &.icons {
      span.arrow {
        background: $icon_arrow center center no-repeat;
      }
    }
    background-color: var(--bs--color-principal-verde);
    &:hover {
      background-color: var(--bs--color-principal-verde-hover);
    }
    &[disabled] {
      &:hover {
        background-color: var(--bs--color-principal-verde);
      }
    }
  }
  &-primary-blue {
    background-color: var(--bs--color-principal-azul);
    &.icons {
      span.arrow {
        background: $icon_arrow center center no-repeat;
      }
    }
    &:hover {
      background-color: var(--bs--color-principal-azul-hover);
    }
    &[disabled] {
      &:hover {
        background-color: var(--bs--color-principal-azul);
      }
    }
  }
  &-secondary {
    background-color: var(--bs--color-principal-blanco);
    color: var(--bs--color-principal-verde);
    border: 2px solid var(--bs--color-principal-verde);
    &.icons {
      span.arrow {
        background: $icon_arrow_secondary center center no-repeat;
      }
      &:hover {
        span,
        figure {
          filter: brightness(80%);
          -webkit-filter: brightness(80%);
        }
      }
    }
    &:hover {
      color: var(--bs--color-principal-verde-hover);
      border: 2px solid var(--bs--color-principal-verde-hover);
    }
    &[disabled] {
      &:hover {
        border: 2px solid var(--bs--color-principal-verde);
      }
    }

    &-alert {
      color: var(--bs--color-secundario-rojo);
      border: 2px solid var(--bs--color-secundario-rojo);
      &:hover {
        color: var(--bs--color-secundario-rojo-hover);
        border: 2px solid var(--bs--color-secundario-rojo-hover);
      }
      &[disabled] {
        &:hover {
          border: 2px solid var(--bs--color-secundario-rojo);
        }
      }
    }
  }
  &-back {
    color: var(--bs--color-principal-negro);
    @include flex-position-child("left", "center");
    min-width: 50px;
    padding-left: 0px;
    text-transform: none;
    font-family: $overPass_regular;
    font-size: 1.5rem;
    span.arrow {
      margin-left: 0;
      margin-right: 10px;
      background: $icon_arrow_back center center no-repeat;
    }
    &:hover {
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
    }
  }

  &-back-color {
    color: var(--bs--color-principal-blanco);
    @include flex-position-child("left", "center");
    min-width: 50px;
    padding-left: 0px;
    span.arrow {
      margin-left: 0;
      margin-right: 10px;
      background: $icon_arrow_back-white center center no-repeat;
    }
    &:hover {
      opacity: 0.5;
    }
  }
  &-terciary-color {
    color: var(--bs--color-principal-blanco);
    width: auto;
    min-width: 100px;
    &.icons {
      span.arrow {
        background: $icon_arrow center center no-repeat;
      }
    }
    &:hover {
      opacity: 0.5;
    }
  }
  &-icon-only {
    padding-right: 10px;
    padding-left: 10px;
    min-width: 0px;
    figure {
      margin: 0;
    }
  }
  &-terciary {
    width: auto;
    color: var(--bs--color-principal-verde);

    min-width: 100px;
    &.icons {
      span.arrow {
        background: $icon_arrow_secondary center center no-repeat;
      }
      &:hover {
        span,
        figure {
          filter: brightness(80%);
          -webkit-filter: brightness(80%);
        }
      }
    }
    &:hover {
      color: var(--bs--color-principal-verde-hover);
    }
    &[disabled] {
      &:hover {
        color: var(--bs--color-principal-verde);
      }
    }
  }
}
