// imagen se debe adaptar a su padre
img {
  $sizes: 4, 8, 12, 20;
  @each $size in $sizes {
    &.border-#{$size} {
      @include border-radius(#{$size}px);
    }
  }
  &.bs-fotografia {
    height: auto;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    filter: saturate(65%);
    -webkit-filter: saturate(65%);
  }
}
