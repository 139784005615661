header {
  &.bs-header {
    background-color: var(--bs--color-principal-blanco);
    z-index: 990;
    height: 60px;
    width: 100%;
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    border-bottom: 1px solid var(--bs--color-grises-gris-20);
    @include flex();
    @include flex-position-child("center", "center");
    @include flex-position-vertical("center");
    a.logo {
      height: 32px;
      display: inline-block;
      margin-left: 8px;
      @media #{$mobile-360} {
        margin: 0 16px;
      }
      @media #{$tablet} {
        height: 32px;
      }

      img {
        height: 24px;
        margin-top: 4px;
        width: auto;
        @media #{$mobile-360} {
          margin-top: 0;
          height: 32px;
        }
      }
    }
    .actions {
      @include flex();
      @include flex-position-child("right", "center");
      @include flex-position-vertical("center");
      flex: 1;
      .action {
        position: relative;
        @include flex();
        @include flex-position-child("bw", "center");
        @include flex-position-vertical("center");
        span.bubble {
          width: 16px;
          height: 16px;
          display: grid;
          place-items: center;
          font-size: 1.1rem;
          font-family: $overPass_bold;
          color: var(--bs--color-principal-blanco);
          @include border-radius(50%);
          position: absolute;
          top: 2px;
          right: 20px;
          &.azul {
            background: var(--bs--color-principal-azul);
          }
          &.verde {
            background: var(--bs--color-principal-verde);
          }
        }
      }
    }
    button.menu {
      width: 30px;
      height: 30px;
      margin: 0 8px;
      display: inline-block;
      background: $icon-menu center center no-repeat;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      background-size: 30px auto;
      &.active {
        background-image: $icon_delete;
        background-size: 24px auto !important;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    &.corporativo {
      border-bottom: none;
      height: auto;
      @include box-shadow(
        0px,
        0px,
        20px,
        0px,
        rgba(100, 100, 100, 0.09),
        false
      );
      .top,
      .bottom {
        width: 100%;
      }
      .top {
        border-bottom: 1px solid var(--bs--color-grises-gris-20); 
        display: block;
        .bs-contenedor {
          position: relative;
          width: 100%;
          height: 60px;
          @include flex();
          @include flex-position-child("left", "center");
          @include flex-position-vertical("center");

          .actions {
            height: 40px;
            .action {
              &.menu {
                position: static;
                @media #{$tablet} {
                  position: relative;
                }
                .bs-menu-desplegable {
                  display: none;
                }
                &:hover {
                  .bs-menu-desplegable {
                    display: block;
                  }
                }
                padding: 0 2px;
                @media #{$mobile-360} {
                  padding: 0 3px;
                }
                @media #{$mobile-430} {
                  padding: 0 10px;
                }
                @media #{$tablet} {
                  padding: 0 20px;
                  border-right: 1px solid var(--bs--color-grises-gris-40);
                  &:last-child {
                    border-right: none;
                  }
                }
                > a {
                  position: relative;
                  @include flex();
                  @include flex-position-child("bw", "center");
                  @include flex-position-vertical("center");
                  cursor: pointer;

                  > figcaption {
                    display: none;
                    @media #{$tablet} {
                      display: inline-block;
                    }
                    margin-right: 20px;
                    p,
                    h4 {
                      font-size: 1.35rem;
                      line-height: 1.3;
                      text-align: right;
                    }

                    h4 {
                      font-family: $overPass_bold;
                    }
                  }
                  > figure {
                    height: 40px;
                    display: grid;
                    place-items: center;
                    img {
                      height: 30px;
                      width: auto;
                    }
                  }
                  &:hover {
                    > figcaption {
                      opacity: 0.5;
                    }
                    > figure {
                      opacity: 0.5;
                    }
                  }
                }
              }
            }
            .action:not(.menu) {
              &.buscar {
                label {
                  margin: 0;
                  .btn {
                    margin: 0;
                    height: 30px !important;
                    @media #{$desktop} {
                      height: 40px !important ;
                    }
                    figure {
                      height: 30px !important;
                      img {
                        height: 24px;
                      }
                      @media #{$desktop} {
                        height: 40px !important ;
                        display: grid;
                        place-items: center;
                        img {
                          height: 34px;
                        }
                      }
                    }
                  }
                }
                input {
                  background: none !important;
                  width: 100%;
                  margin: 0;
                  line-height: 30px !important;
                  text-align-last: right;
                  padding-right: 8px;
                }
                position: absolute;
                top: 60px;
                right: 0;
                @media #{$desktop} {
                  input {
                    width: 200px;
                    line-height: 40px !important;
                  }
                }
                @media #{$desktop} {
                  position: static;
                }
              }
              cursor: pointer;
              padding: 0 2px;
              @media #{$mobile-360} {
                padding: 0 3px;
              }
              @media #{$mobile-430} {
                padding: 0 10px;
              }
              @media #{$desktop} {
                padding: 0 20px;
                border-right: 1px solid var(--bs--color-grises-gris-40);
                &:last-child {
                  border-right: none;
                }
              }

              > figcaption {
                display: none;
                @media #{$tablet} {
                  display: inline-block;
                }
                margin-right: 20px;
                p,
                h4 {
                  font-size: 1.35rem;
                  line-height: 1.3;
                  text-align: right;
                }

                h4 {
                  font-family: $overPass_bold;
                }
              }
              > figure {
                height: 40px;
                display: grid;
                place-items: center;
                img {
                  height: 30px;
                  width: auto;
                }
              }
              &:hover {
                > figcaption {
                  opacity: 0.5;
                }
                > figure {
                  opacity: 0.5;
                }
              }
            }
          }
          button.menu {
            display: inline-block;
            @media #{$mobile-360} {
              margin: 0 16px;
              background-size: 40px auto;
              & + a.logo {
                margin-left: 0px;
              }
            }

            @media #{$desktop} {
              display: none;

              & + a.logo {
                margin-left: 16px;
              }
            }

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
      .bottom {
        height: 30px;
        width: 100%;
      >.bs-contenedor {
          position: relative;
          > ul {
            height: 30px;
            width: 100%;

            display: none;
            @media #{$desktop} {
              @include flex-inline();
            }
            >li {
              list-style: none;
              position: relative;

              >a {
                cursor: pointer;
                padding: 0 10px;
                font-size: var(--bs--font-size-menu);
                color: var(--bs--color-principal-negro);
                text-decoration: none;
                font-family: $overPass_bold;
                line-height: 3rem;

              }
              .bs-menu-desplegable {
                display: none;
                z-index: 990;
              }
              &:hover {
                > a {
                  color:var(--bs--color-principal-verde);
                }
                .bs-menu-desplegable {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    &.privado {
      .bs-contenedor {
        position: relative;
        width: 100%;
        height: 50px;
        @media #{$tablet} {
          height: 60px;
        }
        @include flex();
        @include flex-position-child("bw", "center");
        @include flex-position-vertical("center");
      }
      height: 50px;
      @media #{$tablet} {
        height: 60px;
      }

      .actions {
        height: 40px;
        .action {
          &.menu {
            position: static;
            @media #{$tablet} {
              position: relative;
            }
            .bs-menu-desplegable {
              display: none;
            }
            &:hover {
              .bs-menu-desplegable {
                display: block;
              }
            }
            padding: 0 10px;
            @media #{$tablet} {
              padding: 0 20px;
            }
            border-right: 1px solid var(--bs--color-grises-gris-40);
            &:last-child {
              border-right: none;
            }
            > a {
              position: relative;
              @include flex();
              @include flex-position-child("bw", "center");
              @include flex-position-vertical("center");
              width: 100%;
              cursor: pointer;

              > figcaption {
                display: none;
                @media #{$tablet} {
                  display: inline-block;
                }
                margin-right: 20px;
                p,
                h4 {
                  font-size: 1.35rem;
                  line-height: 1.3;
                  text-align: right;
                }

                h4 {
                  font-family: $overPass_bold;
                }
              }
              > figure {
                height: 40px;
                display: grid;
                place-items: center;
                img {
                  height: 30px;
                  width: auto;
                }
              }
              &:hover {
                > figcaption {
                  opacity: 0.5;
                }
                > figure {
                  opacity: 0.5;
                }
              }
            }
          }
        }
        .action:not(.menu) {
          cursor: pointer;
          padding: 0 10px;
          @media #{$tablet} {
            padding: 0 20px;
          }
          border-right: 1px solid var(--bs--color-grises-gris-40);
          &:last-child {
            border-right: none;
          }
          > figcaption {
            display: none;
            @media #{$tablet} {
              display: inline-block;
            }
            margin-right: 20px;
            p,
            h4 {
              font-size: 1.35rem;
              line-height: 1.3;
              text-align: right;
            }

            h4 {
              font-family: $overPass_bold;
            }
          }
          > figure {
            height: 40px;
            display: grid;
            place-items: center;
            img {
              height: 30px;
              width: auto;
            }
          }
          &:hover {
            > figcaption {
              opacity: 0.5;
            }
            > figure {
              opacity: 0.5;
            }
          }
        }
      }
      button.menu {
        margin: 0 16px;
        background-size: 40px auto;

        & + a.logo {
          margin-left: 0px;
        }
      }
    }
    &.simple {
      .bs-contenedor {
        height: 60px;
        width: 100%;

        @include flex();
        @include flex-position-child("bw", "center");
        @include flex-position-vertical("center");
        h1 {
          flex: 1;
          text-align: center;
          display: none;
          @media #{$tablet} {
            display: inline-block;
          }
        }
        a.logo {
          max-width: 150px;
          height: 32px;

          margin-left: 16px;
          @media #{$tablet} {
            height: 40px;
          }
          @media #{$desktop_t} {
            margin-left: 0px;
          }
          img {
            height: 32px;

            @media #{$tablet} {
              height: 40px;
            }
          }
        }
        .floating-img {
          margin-left: auto;
          width: 64px;
          height: auto;
          margin-right: 16px;
          @media #{$tablet} {
            width: auto;
          }
          @media #{$desktop_t} {
            margin-right: 0px;
          }
        }
        a.resumen {
          text-decoration: none;
          height: 60px;
          width: clamp(70px, 10vw, 88px);
          background-color: var(--bs--color-principal-verde);
          @include flex();
          @include flex-position-child("center", "center");
          @include flex-position-vertical("center");
          &:hover,
          &:active {
            background-color: var(--bs--color-principal-verde-hover);
          }
          figure {
            width: 100%;
            display: inline-block;
            text-align: center;
            margin: 0;
            padding: 0;
            img {
              margin: 2px auto;
            }
          }
          span {
            color: var(--bs--color-principal-blanco);
            font-family: $overPass_regular;
            font-size: 1.2rem;
            width: 100%;
            display: block;
            text-align: center;
            line-height: 1;
          }
          @media #{$tablet} {
            display: none;
          }
        }
      }
    }
    &.movil {
      position: static;
      & + .separador {
        display: none;
      }
    }
  }
}


 