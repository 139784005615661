.bs-titular-seccional {
  width: 100%;
  min-height: 40px;
  display: block;
  @include flex();
  @include flex-position-child("left", "center");
  @include flex-position-vertical("center");

  border-bottom: 1px solid var(--bs--color-grises-gris-80);
  figcaption {
    flex: 1;
    h3 {
      font-size: 1.4rem;
      line-height: 1;

      span {
        font-family: $overPass_regular;
        color:var(--bs--color-grises-gris-80);
        display: block;
        @media #{$tablet} {
          display: inline;
        }
      }
    }
  }
  figure {
    height: 40px;
    display: inline-flex;
    margin: 4px 10px 4px 0;
    padding: 0;
    flex: 0 0 40px;
    img {
      width: 100%;
      height: auto;
    }
  }
}
