p-radiobutton {
  margin-bottom: 16px;
  align-items: flex-start !important;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
  margin: 0 10px 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
  &.p-radiobutton-disabled {
    & + .p-radiobutton-label {
      color: var(--bs--color-grises-gris-80);
    }
  }
  .p-radiobutton-box {
    border: 1px solid var(--bs--color-grises-gris-80);
    background: var(--bs--color-principal-blanco);
         -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;
    width: 20px;
    height: 20px;
    @include border-radius(50%);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    &:not(.p-disabled) {
      &.p-focus {
        outline: 0 none;
        outline-offset: 0;
        border-color: var(--bs--color-principal-azul);
      }
    }
    .p-radiobutton-icon {
      width: 11px;
      height: 11px;
      transition-duration: 0.2s;
      background-color: var(--bs--color-principal-azul);
    }
    &.p-disabled {
      border: 1px solid var(--bs--color-grises-gris-50);
      .p-radiobutton-icon {
        opacity: 0.5;
        background-color: var(--bs--color-principal-azul);
      }
    }
    &.p-highlight {
      border-color: var(--bs--color-grises-gris-80);
      background: var(--bs--color-principal-blanco);
      &:not(.p-disabled) {
        &:hover {
          border-color: var(--bs--color-grises-gris-80);
          background: var(--bs--color-principal-blanco);
          color: var(--bs--color-principal-blanco);
        }
      }
    }
  }
}
p-radiobutton {
  &.ng-dirty {
    &.is-invalid,
    &.ng-invalid {
      > .p-radiobutton {
        > .p-radiobutton-box {
          border-color: var(--bs--color-validacion-rojo-50);
          .p-radiobutton-icon {
            background-color: var(--bs--color-secundario-rojo);
          }
          &.p-highlight {
            border-color: var(--bs--color-validacion-rojo-50);

            &:not(.p-disabled) {
              &:hover {
                border-color: var(--bs--color-validacion-rojo-50) !important;
              }
            }
          }
        }
      }
    }
  }
}

.p-input-filled {
  .p-radiobutton {
    .p-radiobutton-box {
      background-color: var(--bs--color-principal-azul);
    }
  }
}
.p-input-filled {
  .p-radiobutton {
    .p-radiobutton-box {
      &.p-highlight {
        background: var(--bs--color-principal-azul);
      }
      &:not(.p-disabled) {
        &:hover {
          background-color: var(--bs--color-principal-blanco);
        }
      }
    }
  }
}

.p-input-filled
  .p-radiobutton
  .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--bs--color-principal-azul);
}

.p-radiobutton-label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin-right: 16px;
  line-height: 2rem;
  font-family: $overPass_regular;
  color: var(--bs--color-principal-negro);
}
