// para marcar zona
.border-rojo {
  outline: 1px solid var(--bs--color-secundario-rojo);
  &-dotted {
    outline: 1px dotted var(--bs--color-secundario-rojo);
    &.gris {
      outline-color: var(--bs--color-grises-gris-40);
    }
  }

  &.gris {
    outline-color: var(--bs--color-grises-gris-20);
  }
}
.bs-border-all {
  border: 2px solid var(--bs--color-grises-gris-10);
  &.gris-10 {
    border-color: var(--bs--color-grises-gris-10);
  }
  &.gris-15 {
    border-color: var(--bs--color-grises-gris-15);
  }
  &.gris-20 {
    border-color: var(--bs--color-grises-gris-20);
  }
  &.gris-40 {
    border-color: var(--bs--color-grises-gris-40);
  }
  &.gris-50 {
    border-color: var(--bs--color-grises-gris-50);
  }
  &.gris-80 {
    border-color: var(--bs--color-grises-gris-80);
  }
  &.no-border-tablet {
    @media #{$no-tablet} {
      border: none;
    }
  }
  &.no-border-mobile {
    @media #{$no-mobile} {
      border: none;
    }
  }
}
.bs-border {
  &-left {
    border-left: 2px solid var(--bs--color-grises-gris-20);
  }
  &-right {
    border-right: 2px solid var(--bs--color-grises-gris-20);
  }
  &-top {
    border-top: 2px solid var(--bs--color-grises-gris-20);
  }
  &-bottom {
    border-bottom: 2px solid var(--bs--color-grises-gris-20);
  }
  // color
  &-left,
  &-right,
  &-top,
  &-bottom {
    &.no-border-tablet {
      @media #{$no-tablet} {
        border: none;
      }
    }
    &.no-border-mobile {
      @media #{$no-mobile} {
        border: none;
      }
    }
    &.gris-10 {
      border-color: var(--bs--color-grises-gris-10);
    }
    &.gris-15 {
      border-color: var(--bs--color-grises-gris-15);
    }
    &.gris-20 {
      border-color: var(--bs--color-grises-gris-20);
    }
    &.gris-40 {
      border-color: var(--bs--color-grises-gris-40);
    }
    &.gris-50 {
      border-color: var(--bs--color-grises-gris-50);
    }
    &.gris-80 {
      border-color: var(--bs--color-grises-gris-80);
    }
  }
  &.no-border-tablet {
    @media #{$no-tablet} {
      border: none;
    }
  }
  &.no-border-mobile {
    @media #{$no-mobile} {
      border: none;
    }
  }
}
// para mostrar
.bs-block-color {
  @include flex();
  @include flex-position-child("center", "center");
  @include flex-position-vertical("center");
  @include border-radius(4px);
  margin: 6px 0;
  width: calc(100% - 12px);
  min-height: 50px;
  padding: 16px 6px;
  background: var(--bs--color-principal-verde);
}
.bs-bloque {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 50px;
  display: block;
  > article {
    width: calc(100% - 16px);
    padding: 16px 8px;
    margin:auto;
    &.padding-h-4 {
padding: 4px 8px;
    }
      &.padding-h-8 {
      padding: 8px 8px;
    }
      &.padding-h-12 {
      padding: 12px 8px;
    }
  }
  &.color {
    &.verde {
      background-color: var(--bs--color-principal-verde);
    }
    &.azul {
      background-color: var(--bs--color-principal-azul);
    }
  }
  @for $i from 0 through 200 {
    $height: 0px + ($i * 5);
    $name: 0 + ($i * 5);

    &.h-#{$name} {
      height: $height;
    }
  }
  @for $i from 0 through 200 {
    $height: 0px + ($i * 5);
    $name: 0 + ($i * 5);

    &.min-h-#{$name} {
      min-height: $height;
    }
    &.m-h-#{$name} {
      max-height: $height;
    }
  }
  &.botonera {
    @include flex();
    @include flex-position-child("bw", "center");
    @include flex-position-vertical("center");
    &:not(.extremos) {
      @include flex-direction("column", true);
      @media #{$tablet} {
        @include flex-position-child("bw", "center");
        @include flex-direction("row", false);
      }
      &.right {
        @media #{$tablet} {
          @include flex-position-child("right", "center");
          @include flex-direction("row", false);
        }
      }
      &.center {
        @media #{$tablet} {
          @include flex-position-child("center", "center");
          @include flex-direction("row", false);
        }
      }
    }

    &.extremos {
      .bs-btn-primary,
      .bs-btn-secondary,
      .bs-btn-back,
      .bs-btn-terciary {
        width: auto;
      }
    }
  }
  &.important {
    section {
      @include flex();
      @include flex-position-child("bw", "top");
      @include flex-position-vertical("top");
      figure {
        width: 16px;
        margin-right: 16px;
        margin-top: 5px;
        img {
          width: 16px;
          height: auto;
        }
      }
      p {
        flex: 1;
      }
    }
  }
  > img {
    width: 100%;
    height: auto;
  }
  &.background {
    @include border-radius(4px);
    padding: 16px;
    width: calc(100% - 32px);
    &.gris {
      background-color: var(--bs--color-grises-gris-10);
    }
    &.verde {
      background-color: var(--bs--color-principal-verde);
    }
    &.azul {
      background-color: var(--bs--color-principal-azul);
    }
  }
}
.bs-chip {
  margin: 0 8px 8px 0;
  background-color: var(--bs--color-principal-negro);
  color: var(--bs--color-principal-blanco);
  display: inline-block;
  padding: 4px 8px 3px;
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
@include border-radius(4px);
  box-sizing: border-box;
 font-family: $overPass_regular;
  &.mini {
    font-size: 1.2rem;
    margin: 4px 0;
    padding: 2px 8px;
    font-family: $overPass_regular;
  }
  &.gris-40 {
    background-color: var(--bs--color-grises-gris-40);
  }
  &.gris-50 {
    background-color: var(--bs--color-grises-gris-50);
  }
  &.gris-80 {
    background-color: var(--bs--color-grises-gris-80);
  }
  &.rojo {
    background-color: var(--bs--color-secundario-rojo);
  }
  &.verde {
    background-color: var(--bs--color-principal-verde);
  }
  &.azul {
    background-color: var(--bs--color-principal-azul);
  }
  &.amarillo {
    background-color: var(--bs--color-secundario-amarillo);
  }
}
.no-mobile {
  @media #{$no-mobile} {
    display: none;
  }
}
.no-tablet {
  @media #{$no-tablet} {
    display: none;
  }
}
